import { Header, Text, TextSpan1, TextSpan2 } from './Styles';
import { EmailPassForm } from '../../components/EmailPassForm';
import { useIamViewModel } from '../../view-models/IamViewModel';

export default function LoginPage() {
  const iamViewModel = useIamViewModel();

  const visitSignUpPage = () => {
    window.location.replace('https://reconcilio.com/#contact-us');
  };

  const onSubmit = (email: string, password: string) => {
    iamViewModel.loginWithEmailPassword(email, password)
  }

  return (
    <>
      <Header>Login</Header>
      <EmailPassForm
        message={iamViewModel.authMessage}
        requireTerms
        view="Login"
        onSubmit={onSubmit}
      />
      <Text>
        <TextSpan1>Don’t have an account?</TextSpan1>
        <TextSpan2> </TextSpan2>
        <TextSpan2 onClick={visitSignUpPage}>SIGN UP</TextSpan2>
      </Text>
    </>
  );
}