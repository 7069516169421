import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { UnreconciledTransactionsBlock } from '../../../models/ReconciliationReport';

export default function UnreconciledTransactionsSummary({ unreconciledTransactionsBlocks }: {
    unreconciledTransactionsBlocks: UnreconciledTransactionsBlock[]
}) {
    return (
        <div className='rounded overflow-hidden shadow-lg p-5' >
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell align="right">Adjusted Balance</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {unreconciledTransactionsBlocks.map((row: any, idx: number) => (
                        <TableRow
                            key={row.idx}
                        >
                            <TableCell scope="row">
                                {row.title}
                            </TableCell>
                            <TableCell scope="row" align="right">
                                {row.total}
                            </TableCell>
                            <TableCell scope="row" align="right">
                                {row.adjustedBalance}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}
