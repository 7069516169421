import Tooltip from '@mui/material/Tooltip';
import { TransactionProps } from '../../../types';
import { LedgerTransaction, LedgerDebit, LedgerCredit } from './Styles';
import { Date, Reference } from '../Styles';
import Description from '../DescriptionColumn';

function LedgerTransactionBlock(props: TransactionProps): JSX.Element {
  const {
    date,
    description,
    reference,
    debit,
    credit,
    financialTransactionId
  } = props;

  const tooltipTitle = `TransactionID: ${financialTransactionId}`;

  return (
    <Tooltip title={tooltipTitle} placement='top'>
      <LedgerTransaction>
        <Date>{date}</Date>
        <Description content={description} />
        <Reference>{reference}</Reference>
        <LedgerDebit>{debit !== undefined && debit !== '' && debit !== '0.00' && debit}</LedgerDebit>
        <LedgerCredit>
          {credit !== undefined && credit !== '' && credit !== '0.00' && credit}
        </LedgerCredit>
      </LedgerTransaction>
    </Tooltip>
  );
}

export default LedgerTransactionBlock;
