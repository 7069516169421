import { ButtonsLayout, Layout, SuggestedMatches } from './Styles';
import { SMLayoutProps } from '../../../types';
import Headers from '../../Headers/SuggestedMatchesHeaders';
import AcceptAllButton from './AcceptAllBlock/AcceptAllButton';
import RejectAllButton from './RejectAllBlock/RejectAllButton';

function SMLayout(props: SMLayoutProps): JSX.Element {
  const {
    children,
    externalDocumentType,
    ledgerDocumentType,
    canAcceptBulkSuggestions,
    acceptSuggestions,
    rejectSuggestions,
    from,
    to,
  } = props;

  const handleAcceptSuggestions = () => {
    acceptSuggestions(from, to);
  };

  const handleRejectSuggestions = () => {
    rejectSuggestions(from, to);
  };
  return (
    <Layout>
      <ButtonsLayout>
        <AcceptAllButton onClick={handleAcceptSuggestions} disabled={!canAcceptBulkSuggestions}/>
        <RejectAllButton onClick={handleRejectSuggestions} disabled={!canAcceptBulkSuggestions}/>
      </ButtonsLayout>
      <Headers
        externalDocumentType={externalDocumentType}
        ledgerDocumentType={ledgerDocumentType}
      />
      <SuggestedMatches>{children}</SuggestedMatches>
    </Layout>
  );
}

export default SMLayout;
