import { Fragment } from 'react';
import { CategoryItem } from '../../../../../../models/Resources';
import { Item } from '../../Styles';

interface ChunkedItemProps {
  items: CategoryItem[];
  showEditCategoryItemDialog: (itemId: string) => void;
}
const ChunkedItem = (props: ChunkedItemProps) => {
  const { items, showEditCategoryItemDialog } = props;

  return (
    <>
      {items.map((item) => (
        <Fragment key={item.id}>
          <Item
            key={item.id}
            sx={{
              width: {
                md: 100,
                lg: 200,
              },
            }}
            onClick={() => showEditCategoryItemDialog(item.id)}
          >
            {item.value}
          </Item>
        </Fragment>
      ))}
    </>
  );
};

export default ChunkedItem;
