import Layout from './Layout';
import ReconciledTransaction from './ReconciledTransaction/ReconciledTransaction';
import { ReconciledTransactionBlocksProps } from '../../../types';
import { Empty } from './Styles';
import PaginationComponent from '../../Pagination';
import { ReconciliationReportViewModel, useReconciliationReportViewModel } from '../../../view-models/ReconciliationReportViewModel';
import Search from '../../Search';

export default function ReconciledTransactions(props: ReconciledTransactionBlocksProps): JSX.Element {
  const {
    reconciledTransactionsPagination,
    externalDocumentType,
    ledgerDocumentType,
    unreconcileMatch,
    bankStatementTransactionsPercentage,
  } = props;

  const recinciliationReportViewModel = useReconciliationReportViewModel();
  const {
    reconciledTransactionsQuickFilter,
    reconciledTransactionCounts,
    reconciledTransactionsPaginated
  } = recinciliationReportViewModel as ReconciliationReportViewModel;

  const { page } = reconciledTransactionsPagination;

  const onPageChange = (value: number) => {
    recinciliationReportViewModel?.setReconciledTransactionsPage(value);
  }

  const onItemsPerPageChange = (value: number) => {
    recinciliationReportViewModel?.setReconciledTransactionsItemsPage(value);
    window.scrollTo({ top: 0 });
  }

  const changeQuickFilter = (value: string) => {
    recinciliationReportViewModel?.changeReconciledTransactionsQuickFilter(value)
  }

 console.log(page)
  return (
    <>
      {/* <Subheader title="Reconciled Transactions" /> */}
      {reconciledTransactionsPaginated === null && (
        <Empty>Reconciled transactions will be loaded soon.</Empty>
      )}
      {reconciledTransactionsPaginated && reconciledTransactionsPaginated.length === 0 && (
        <Empty>No reconciled transactions found.</Empty>
      )}

      <div className="my-4">
        <Search
          value={reconciledTransactionsQuickFilter}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            changeQuickFilter(e.target.value);
          }}
        />
      </div>

      {reconciledTransactionsPaginated && reconciledTransactionsPaginated.length > 0 && (
        <>

          <Layout
            externalDocumentType={externalDocumentType}
            externalStatementTransactionsPercentage={bankStatementTransactionsPercentage}
            ledgerDocumentType={ledgerDocumentType}
          >
            {reconciledTransactionsPaginated.map((reconciledTransaction, index) => (
              <ReconciledTransaction
                id={reconciledTransaction.id}
                unreconcileMatch={unreconcileMatch}
                bankStatementTransactions={reconciledTransaction.bankStatementTransactions}
                ledgerTransactions={reconciledTransaction.ledgerTransactions}
                type={reconciledTransaction.type}
                key={index}
              />
            ))}
          </Layout>
          {reconciledTransactionsPaginated.length > 0 && (
            <div className='flex justify-end mt-5'>
              <PaginationComponent
                activePage={page}
                totalItems={reconciledTransactionCounts}
                onItemsPerPageChange={onItemsPerPageChange}
                onPageChange={onPageChange}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}