import { styled } from '@mui/material/styles';

export const DialogContentContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px'
});

export const LoaderContainer = styled('div')({
    margin: '1em 0em',
    display: 'flex',
    justifyContent: 'center',
});
