import Pagination from '@mui/material/Pagination';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';

export default function PaginationComponent({
  totalItems,
  activePage,
  onPageChange,
  onItemsPerPageChange
}: {
  activePage: number;
  totalItems: number
  onPageChange?: (v: number) => void;
  onItemsPerPageChange?: (v: number) => void;
}) {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const handleChange = (event: SelectChangeEvent) => {
    onItemsPerPageChange?.(Number(event.target.value));
    setItemsPerPage(Number(event.target.value))
  };

  const handlePageChange = (_: any, page: number) => {
    onPageChange?.(page);
  };

  const pages = [10, 25, 50, 100, 150, 200, 250, 500];
  const totalPages = Number.isInteger(totalItems / itemsPerPage) ? totalItems / itemsPerPage : Math.trunc(totalItems / itemsPerPage) + 1;
  const pagesCount = totalPages ?? 1;
console.log(totalItems);
  return (
    <div className="flex items-center gap-5">
      <Select
        value={itemsPerPage.toString()}
        onChange={handleChange}
        label="Items"

      >
        {pages.map((v) => <MenuItem value={v} key={v}>{v}</MenuItem>)}
      </Select>

      <Pagination
        count={pagesCount}
        page={activePage}
        onChange={handlePageChange}
      />
    </div>
  )
}
