import { useEffect, useState } from 'react';
import SuggestedMatch from '../SuggestedMatch/SuggestedMatch';
import Layout from './Layout';
import { VisibleSuggestedMatchesProps } from '../../../types';

function VisibleSuggestedMatches(props: VisibleSuggestedMatchesProps): JSX.Element {
  const {
    reconciliationReportId,
    rowsPerPage,
    page,
    suggestedMatches,
    timeout,
    externalDocumentType,
    ledgerDocumentType,
    canAcceptBulkSuggestions,
    acceptSuggestedMatch,
    rejectSuggestedMatch,
    acceptSuggestions,
    rejectSuggestions,
  } = props;
  const [min, setMin] = useState(rowsPerPage * page);
  const [max, setMax] = useState(rowsPerPage * (page + 1) - 1);

  useEffect(() => {
    setMin(rowsPerPage * page);
    setMax(rowsPerPage * (page + 1) - 1);
  }, [rowsPerPage, page]);

  return (
    <Layout
      acceptSuggestions={acceptSuggestions}
      rejectSuggestions={rejectSuggestions}
      canAcceptBulkSuggestions={canAcceptBulkSuggestions}
      from={min}
      to={max}
      externalDocumentType={externalDocumentType}
      ledgerDocumentType={ledgerDocumentType}
    >
      {suggestedMatches
        .filter((match, index) => index >= min && index <= max)
        .map((match, index) => (
          <SuggestedMatch
            id={match.id}
            reconciliationReportId={reconciliationReportId}
            bankTransactions={match.bankStatementTransactions}
            ledgerTransactions={match.ledgerTransactions}
            timeout={timeout}
            key={index}
            acceptSuggestedMatch={acceptSuggestedMatch}
            rejectSuggestedMatch={rejectSuggestedMatch}
          />
        ))}
    </Layout>
  );
}

export default VisibleSuggestedMatches;
