import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FilledButton from '../../Buttons/FilledButton';
import CreateDialog from '../CreateDialog';
import InputBlock from '../Input';
import { EntityParams } from '../../../models/Resources';

interface NewEntityDialogProps {
  open: boolean;
  countries: string[];
  languages: string[];
  closeDialog: () => void;
  onSubmit: (props: EntityParams) => void;
}

export default function NewEntityDialog(props: NewEntityDialogProps) {
  const {
    open,
    countries,
    languages,
    onSubmit,
    closeDialog,
  } = props;

  const [entityName, setEntityName] = useState('');
  const [uniqueIdentifier, setUniqueIdentifier] = useState('');
  const [country, setCountry] = useState('');
  const [language, setLanguage] = useState('');

  const areRequiredFieldsSet = entityName.length > 0;

  const submit = () => {
    onSubmit({
      name: entityName,
      country,
      language,
      uniqueIdentifier
    });
  }

  return (
    <CreateDialog
      title="Create Entity"
      open={open}
      closeDialog={closeDialog}
      content={
        <>
          <InputBlock
            name="Entity Name"
            input={
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={entityName}
                onChange={(event) => setEntityName(event.target.value)}
              />
            }
            required
          />
          <InputBlock
            name="Unique Identifier"
            input={
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={uniqueIdentifier}
                onChange={(event) => setUniqueIdentifier(event.target.value)}
              />
            }
          />
          <InputBlock
            name="Country"
            input={
              <Autocomplete
                options={countries}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
                value={country}
                onChange={(e, value) => setCountry(value!)}
              />
            }
          />
          <InputBlock
            name="Language"
            input={
              <Autocomplete
                options={languages}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
                value={language}
                onChange={(e, value) => setLanguage(value!)}
              />
            }
          />
        </>
      }
      action={<FilledButton disabled={!areRequiredFieldsSet} content="Create" onClick={submit} />}
    />
  );
}