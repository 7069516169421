/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
import { WorkBook, WorkSheet, utils } from 'xlsx';
import {
  AdjustmentsReport,
  ReconciledTransaction,
  ReconciledTransactionsBlock,
  ReconciliationReport,
  ReconciliationTransactionStatuses,
  Transaction,
  UnreconciledTransactionsBlock,
} from '../models/ReconciliationReport';
import {
  CREDITS_NOT_PRESENT,
  DEBITS_NOT_PRESENT,
  EXTERNAL_CREDITS_HEADER,
  EXTERNAL_DEBITS_HEADER,
  LEDGER_CREDITS_HEADER,
  LEDGER_DEBITS_HEADER,
  NOT_APPLICABLE,
} from '../view-models/ReconciliationReportViewModel';
import { RECONCILIATION_ACCOUNT_TYPES, ReconciliationAccount } from '../models/Resources';
import DateUtils from './dates';
import { FileData } from '../models/UploadFileData';

const EMPTY_COLUMNS_NUM = 6;
const EMPTY_COLUMNS_WITH_DEBUG_NUM = EMPTY_COLUMNS_NUM + 1;

export default class ReconciliationReportExcelFile {
  public static reportToExcelData(createReportData: {
    reconciliationData: ReconciliationReport;
    automaticReconciliationPercentage: number;
    reconciliationAccountData: ReconciliationAccount | null;
    adjustmentsData: (string | null)[][] | null;
  }): WorkBook {
    const {
      reconciliationData,
      automaticReconciliationPercentage,
      reconciliationAccountData,
      adjustmentsData,
    } = createReportData;

    const workbook = utils.book_new();

    this.createReconciliationReportsTab(workbook, reconciliationData, reconciliationAccountData);

    this.createReconciledTransactionsTab(
      workbook,
      reconciliationData,
      automaticReconciliationPercentage,
      reconciliationAccountData
    );

    if (adjustmentsData) {
      this.createAdjustmentsTab(workbook, adjustmentsData);
    }

    return workbook;
  }

  static createAdjustmentsTab(workbook: WorkBook, data: any) {
    const ws = utils.json_to_sheet<AdjustmentsReport>([]);
    utils.book_append_sheet(workbook, ws, 'Adjustments Report');

    // Add the data to the workbook
    utils.sheet_add_aoa(ws, data, {
      origin: 'A1',
    });
  }

  public static pdfToExcelData(fileData: FileData[]): WorkBook {
    const workbook = utils.book_new();

    this.createFileDataTab(workbook, fileData);

    return workbook;
  }

  private static createFileDataTab(workbook: WorkBook, fileData: FileData[]) {
    let fileDataWs = utils.json_to_sheet<FileData[]>([]);

    const data = ReconciliationReportExcelFile.pdfToFileExcelData(fileData);

    // Add the data to the workbook
    utils.sheet_add_aoa(fileDataWs, data, {
      origin: 'A1',
    }); // start from first cell
    utils.book_append_sheet(workbook, fileDataWs, 'Pdf to Excel');
    fileDataWs = ReconciliationReportExcelFile.applyNumericFormatToColumn(fileDataWs, [
      'E',
      'F',
      'G',
    ]);
  }

  private static pdfToFileExcelData(fileData: FileData[]) {
    const data = [
      ['Date', 'Description', 'Reference', 'TransactionID', 'Debit', 'Credit', 'Balance'],
      ...fileData.flatMap(ReconciliationReportExcelFile.getFileDataRows),
      [],
    ];
    return data;
  }

  private static getFileDataRows(fileData: FileData): (string | number)[][] {
    const rows = [];
    const row = [];
    row.push(
      fileData.date,
      fileData.description,
      fileData.reference,
      fileData.financialTransactionId,
      ReconciliationReportExcelFile.isDebit(fileData.amount.type) ? fileData.amount.amount : '',
      ReconciliationReportExcelFile.isCredit(fileData.amount.type) ? fileData.amount.amount : '',
      fileData.balance
    );
    rows.push(row);
    return rows;
  }

  private static isDebit(type: string): boolean {
    return type === 'debit';
  }

  private static isCredit(type: string): boolean {
    return type === 'credit';
  }

  private static createReconciliationReportsTab(
    workbook: WorkBook,
    report: ReconciliationReport,
    reconciliationAccount: ReconciliationAccount | null
  ) {
    let reportWs = utils.json_to_sheet<ReconciliationReport>([]);

    let data;
    if (
      reconciliationAccount?.reconciliationType ===
      RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING
    ) {
      data =
        ReconciliationReportExcelFile.reportToUnreconciledTransactionsExcelDataTRansactionMatching(
          report,
          reconciliationAccount
        );
    } else {
      data = ReconciliationReportExcelFile.reportToUnreconciledTransactionsExcelData(
        report,
        reconciliationAccount
      );
    }

    // Add the data to the workbook
    utils.sheet_add_aoa(reportWs, data, {
      origin: 'A1',
    }); // start from first cell
    utils.book_append_sheet(workbook, reportWs, 'Reconciliation Report');
    reportWs = ReconciliationReportExcelFile.applyNumericFormatToColumn(reportWs, ['E', 'F']);
  }

  private static createReconciledTransactionsTab(
    workbook: WorkBook,
    report: ReconciliationReport,
    automaticReconciliationPercentage: number,
    reconciliationAccount: ReconciliationAccount | null
  ) {
    let transactionsWs = utils.json_to_sheet<ReconciliationReport>([]);

    let reconciliedTransactionsData;
    if (
      reconciliationAccount?.reconciliationType ===
      RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING
    ) {
      reconciliedTransactionsData =
        ReconciliationReportExcelFile.reportToReconciliedTransactionsExcelDataTransactionMatching(
          report,
          automaticReconciliationPercentage,
          reconciliationAccount.name
        );
    } else {
      reconciliedTransactionsData =
        ReconciliationReportExcelFile.reportToReconciliedTransactionsExcelData(
          report,
          automaticReconciliationPercentage
        );
    }

    // Add the data to the workbook
    utils.sheet_add_aoa(transactionsWs, reconciliedTransactionsData, {
      origin: 'A1',
    }); // start from first cell
    utils.book_append_sheet(workbook, transactionsWs, 'Reconciled Transactions');
    transactionsWs = ReconciliationReportExcelFile.applyNumericFormatToColumn(transactionsWs, [
      'E',
      'F',
      'L',
      'M',
    ]);
  }

  private static reportToUnreconciledTransactionsExcelData(
    report: ReconciliationReport,
    reconciliationAccount: ReconciliationAccount | null
  ) {
    const ledgerEndBalance = this.getLedgerEndBalance(report);
    const { unreconciledTransactions: unreconciledTransactionsBlocks, reconciliationDifference } =
      this.getUnreconciledTransactions(report, reconciliationAccount);
    const bankClosingBalance = this.getBankClosingBalance(report);

    const accountName = reconciliationAccount ? reconciliationAccount.name : '';
    const data = [
      [`Reconciliation Report for ${accountName}`],
      [
        `For period: ${report.reconciliationPeriod.startingDate} - ${report.reconciliationPeriod.endingDate}`,
      ],
      ['Ledger Closing Balance', '', '', '', '', ledgerEndBalance],
      ...unreconciledTransactionsBlocks.flatMap(
        ReconciliationReportExcelFile.getUnreconciledTransactionsBlockRows
      ),
      [],
      [`${accountName} closing balance`, '', '', '', '', bankClosingBalance],
      ['Difference', '', '', '', '', reconciliationDifference],
      [],
    ];
    return data;
  }

  private static reportToUnreconciledTransactionsExcelDataTRansactionMatching(
    report: ReconciliationReport,
    reconciliationAccount: ReconciliationAccount | null
  ) {
    const {
      unreconciledTransactions: unreconciledTransactionsBlocks,
      reconciliationDifference,
      ledgerSumDifference,
      bankSumDifference,
    } = this.getUnreconciledTransactionsTransactionMatching(report, reconciliationAccount);

    const accountName = reconciliationAccount ? reconciliationAccount.name : '';

    const data = [
      [`Reconciliation Report for ${accountName}`],
      [
        `For period: ${report.reconciliationPeriod.startingDate} - ${report.reconciliationPeriod.endingDate}`,
      ],
      ['Net Movement Internal Data / Dataset B', '', '', '', '', ledgerSumDifference],
      ...unreconciledTransactionsBlocks.flatMap(
        ReconciliationReportExcelFile.getUnreconciledTransactionsBlockRows
      ),
      [],
      [`${accountName} net movement`, '', '', '', '', bankSumDifference],
      ['Difference', '', '', '', '', reconciliationDifference],
      [],
    ];
    return data;
  }

  private static getLedgerBalanceHeader(
    reconciliationAccount: ReconciliationAccount | null
  ): string {
    if (
      reconciliationAccount?.reconciliationType ===
      RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING
    ) {
      return 'Net Movement Internal Data / Dataset B';
    }
    return 'Closing Ledger Balance';
  }

  private static getBankClosingBalanceLabelDescription(
    reconciliationAccount: ReconciliationAccount | null
  ): { label: string; description: string } {
    if (
      reconciliationAccount?.reconciliationType ===
      RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING
    ) {
      return {
        label: 'net movement',
        description: 'Dataset A net sum (Sum of Credits - sum of Debits)',
      };
    }
    return { label: 'closing balance', description: '' };
  }

  private static getBankClosingBalanceOrDifference(
    reconciliationAccount: ReconciliationAccount | null,
    bankSumDifference: number,
    bankClosingBalance: string
  ): string {
    if (
      reconciliationAccount?.reconciliationType ===
      RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING
    ) {
      return bankSumDifference.toFixed(2);
    }
    return bankClosingBalance;
  }

  private static getNetSumLedgerDescription(reconciliationAccount: ReconciliationAccount | null): {
    description: string;
    shouldDisplay: boolean;
  } {
    if (
      reconciliationAccount?.reconciliationType ===
      RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING
    ) {
      return {
        description: 'Dataset B net sum (Sum of Debits - sum of Credits)',
        shouldDisplay: true,
      };
    }
    return {
      description: '',
      shouldDisplay: false,
    };
  }

  private static reportToReconciliedTransactionsExcelData(
    report: ReconciliationReport,
    automaticReconciliationPercentage: number
  ) {
    const { debug } = report;

    const reconciledTransactionsBlocks: ReconciledTransactionsBlock[] =
      this.getReconciledTransactions(report);

    const headerRow = [
      'Date',
      'Description',
      'Reference',
      'TransactionID',
      'Debit',
      'Credit',
      'Match Method',
      'Date',
      'Description',
      'Reference',
      'TransactionID',
      'Debit',
      'Credit',
    ];

    if (debug) {
      headerRow.push('Created By');
    }

    const data = [
      ['Reconciled Transactions Analysis'],
      [`Automatic Reconciliation Rate ${automaticReconciliationPercentage.toFixed(2)}%`],
      [],
      ['Reconciled Bank Transactions', '', '', '', '', '', '', 'Reconciled Ledger Transactions'],
      headerRow,
      ...reconciledTransactionsBlocks.flatMap((block) =>
        ReconciliationReportExcelFile.getReconciledTransactionsBlockRows(block, debug)
      ),
      [],
    ];
    return data;
  }

  private static reportToReconciliedTransactionsExcelDataTransactionMatching(
    report: ReconciliationReport,
    automaticReconciliationPercentage: number,
    accountName: string
  ) {
    const { debug } = report;

    const reconciledTransactionsBlocks: ReconciledTransactionsBlock[] =
      this.getReconciledTransactions(report);

    const headerRow = [
      'Date',
      'Description',
      'Reference',
      'TransactionID',
      'Debit',
      'Credit',
      'Match Method',
      'Date',
      'Description',
      'Reference',
      'TransactionID',
      'Debit',
      'Credit',
    ];

    if (debug) {
      headerRow.push('Created By');
    }

    const data = [
      [],
      ['Automatic Reconciliation Rate', `${automaticReconciliationPercentage.toFixed(2)}%`],
      [],
      [
        `Reconciled ${accountName} / Dataset A Transactions`,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'Reconciled Internal Data / Dataset B Transactions',
      ],
      headerRow,
      ...reconciledTransactionsBlocks.flatMap((block) =>
        ReconciliationReportExcelFile.getReconciledTransactionsBlockRows(block, debug)
      ),
      [],
    ];
    return data;
  }

  private static getUnreconciledTransactionsBlockRows(
    block: UnreconciledTransactionsBlock,
    index: number
  ): string[][] {
    const {
      title,
      total,
      adjustedBalance,
      blocks,
      dateHeader,
      descriptionHeader,
      referenceHeader,
      amountHeader,
    } = block;

    const rows: string[][] = [
      [],
      [`${index + 1}. ${title}`],
      [],
      [
        dateHeader || 'Date',
        descriptionHeader || 'Description',
        referenceHeader || 'Reference',
        'TransactionID',
        amountHeader,
      ],
    ];
    blocks?.forEach((tx) => {
      rows.push([tx.date, tx.description, tx.reference, tx.financialTransactionId, tx.amount]);
    });
    rows.push(
      [],
      ['Total', '', '', '', `${total}`],
      ['Adjusted Balance', '', '', '', '', `${adjustedBalance}`]
    );
    return rows;
  }

  private static getReconciledTransactionsBlockRows(
    block: ReconciledTransactionsBlock,
    debug = false
  ): string[][] {
    const { bankStatementTransactions, ledgerTransactions } = block;
    const maxLength = Math.max(bankStatementTransactions.length, ledgerTransactions.length);
    const rows = [];
    for (let i = 0; i < maxLength; i += 1) {
      const row = [];
      if (i < bankStatementTransactions.length) {
        const bankStatementTransaction = bankStatementTransactions[i];

        row.push(
          bankStatementTransaction.date,
          bankStatementTransaction.description,
          bankStatementTransaction.reference,
          bankStatementTransaction.financialTransactionId,
          bankStatementTransaction.debit === '0.00' ? '' : bankStatementTransaction.debit,
          bankStatementTransaction.credit === '0.00' ? '' : bankStatementTransaction.credit,
          block.type
        );
      } else {
        row.push('', '', '', '', '', '', '');
      }
      // row.push(''); // empty cell
      if (i < ledgerTransactions.length) {
        const ledgerTransaction = ledgerTransactions[i];
        row.push(
          ledgerTransaction.date,
          ledgerTransaction.description,
          ledgerTransaction.reference,
          ledgerTransaction.financialTransactionId,
          ledgerTransaction.debit === '0.00' ? '' : ledgerTransaction.debit,
          ledgerTransaction.credit === '0.00' ? '' : ledgerTransaction.credit
          // block.type
        );

        if (debug) {
          row.push(block.createdBy || '');
        }
      } else {
        const emptyColumns = debug ? EMPTY_COLUMNS_WITH_DEBUG_NUM : EMPTY_COLUMNS_NUM;
        row.push(...Array(emptyColumns).fill(''));
      }
      rows.push(row);
    }
    return rows;
  }

  private static getLedgerEndBalance(report: ReconciliationReport): string {
    return report.ledgerEndBalance.toFixed(2);
  }

  private static getBankClosingBalance(report: ReconciliationReport): string {
    return report.bankClosingBalance.toFixed(2);
  }

  private static getLedgerCreditsHeader(
    reconciliationAccount: ReconciliationAccount | null
  ): string {
    if (reconciliationAccount) {
      const reconciliationAccountType = reconciliationAccount.reconciliationType;
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.BANK) {
        return `${LEDGER_CREDITS_HEADER} ${reconciliationAccount.name} Statement`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SUPPLIER) {
        return `${reconciliationAccount.name} ${EXTERNAL_CREDITS_HEADER} Statement`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.CUSTOMER) {
        return `${LEDGER_CREDITS_HEADER} ${reconciliationAccount.name} Statement`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SINGLE_ACCOUNT) {
        return `${CREDITS_NOT_PRESENT} ${reconciliationAccount.name} Statement`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING) {
        return 'Dataset B Credits not present in Dataset A';
      }
      throw new Error('Unknown reconciliation account type');
    }
    return `${LEDGER_CREDITS_HEADER} Statement`;
  }

  private static getLedgerDebitsHeader(
    reconciliationAccount: ReconciliationAccount | null
  ): string {
    if (reconciliationAccount) {
      const reconciliationAccountType = reconciliationAccount.reconciliationType;
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.BANK) {
        return `${LEDGER_DEBITS_HEADER} ${reconciliationAccount.name} Statement`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SUPPLIER) {
        return `${reconciliationAccount.name} ${EXTERNAL_DEBITS_HEADER} Statement`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.CUSTOMER) {
        return `${LEDGER_DEBITS_HEADER} ${reconciliationAccount.name} Statement`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SINGLE_ACCOUNT) {
        return NOT_APPLICABLE;
      }

      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING) {
        return 'Dataset B Debits not present in Dataset A';
      }
      throw new Error('Unknown reconciliation account type');
    }
    return `${LEDGER_DEBITS_HEADER} Statement`;
  }

  private static getExternalCreditsHeader(
    reconciliationAccount: ReconciliationAccount | null
  ): string {
    if (reconciliationAccount) {
      const reconciliationAccountType = reconciliationAccount.reconciliationType;
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.BANK) {
        return `${reconciliationAccount.name} ${EXTERNAL_CREDITS_HEADER}`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SUPPLIER) {
        return `${LEDGER_CREDITS_HEADER} ${reconciliationAccount.name}`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.CUSTOMER) {
        return `${reconciliationAccount.name} ${EXTERNAL_CREDITS_HEADER}`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SINGLE_ACCOUNT) {
        return NOT_APPLICABLE;
      }

      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING) {
        return 'Dataset A Credits not present in Dataset B';
        // return `${reconciliationAccount.name} ${EXTERNAL_CREDITS_HEADER}`;
      }

      throw new Error('Unknown reconciliation account type');
    }
    return ` ${EXTERNAL_CREDITS_HEADER}`;
  }

  private static getExternalDebitsHeader(
    reconciliationAccount: ReconciliationAccount | null
  ): string {
    if (reconciliationAccount) {
      const reconciliationAccountType = reconciliationAccount.reconciliationType;
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.BANK) {
        return `${reconciliationAccount.name} ${EXTERNAL_DEBITS_HEADER}`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SUPPLIER) {
        return `${LEDGER_DEBITS_HEADER} ${reconciliationAccount.name}`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.CUSTOMER) {
        return `${reconciliationAccount.name} ${EXTERNAL_DEBITS_HEADER}`;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SINGLE_ACCOUNT) {
        return `${DEBITS_NOT_PRESENT} ${reconciliationAccount.name} Statement`;
      }

      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING) {
        return 'Dataset A Debits not present in Dataset B';
      }

      throw new Error('Unknown reconciliation account type');
    }
    return ` ${EXTERNAL_DEBITS_HEADER}`;
  }

  private static getUnreconciledTransactions(
    report: ReconciliationReport,
    reconciliationAccount: ReconciliationAccount | null
  ): {
    unreconciledTransactions: UnreconciledTransactionsBlock[];
    reconciliationDifference: string;
  } {
    const unreconciledTransactionsBlocks: UnreconciledTransactionsBlock[] = [];

    // 1. Process ledger credit transactions
    const ledgerUnreconciledTransactions = report.ledgerTransactions.filter(
      (transaction) => transaction.status === ReconciliationTransactionStatuses.Unreconciled
    );
    // check if the transaction is a credit transaction by checking
    // that it has a value other than 0,null or undefined
    const ledgerCreditTransactions = ledgerUnreconciledTransactions.filter(
      (transaction) => transaction.credit > 0 || transaction.credit < 0
    );
    const ledgerCreditTotal = ledgerCreditTransactions.reduce(
      (total, transaction) => total + transaction.credit,
      0
    );
    const ledgerCreditAdjustedBalance = report.ledgerEndBalance + ledgerCreditTotal;
    const ledgerCreditBlock: UnreconciledTransactionsBlock = {
      title: this.getLedgerCreditsHeader(reconciliationAccount),
      blocks: ledgerCreditTransactions.map((transaction) => ({
        date: transaction.date,
        description: transaction.description,
        reference: transaction.reference,
        amount: transaction.credit.toFixed(2),
        financialTransactionId: transaction.financialTransactionId,
      })),
      type: 'ledger',
      total: ledgerCreditTotal.toFixed(2),
      adjustedBalance: ledgerCreditAdjustedBalance.toFixed(2),
      dateHeader: undefined,
      descriptionHeader: undefined,
      referenceHeader: undefined,
      amountHeader: 'Credit',
    };
    unreconciledTransactionsBlocks.push(ledgerCreditBlock);

    // 2. Process ledger debit transactions
    const ledgerDebitTransactions = ledgerUnreconciledTransactions.filter(
      (transaction) => transaction.debit > 0 || transaction.debit < 0
    );
    const ledgerDebitTotal = ledgerDebitTransactions.reduce(
      (total, transaction) => total + transaction.debit,
      0
    );
    const ledgerDebitAdjustedBalance = ledgerCreditAdjustedBalance - ledgerDebitTotal;
    const ledgerDebitBlock: UnreconciledTransactionsBlock = {
      title: this.getLedgerDebitsHeader(reconciliationAccount),
      blocks: ledgerDebitTransactions.map((transaction) => ({
        date: transaction.date,
        description: transaction.description,
        reference: transaction.reference,
        amount: transaction.debit.toFixed(2),
        financialTransactionId: transaction.financialTransactionId,
      })),
      type: 'ledger',
      total: ledgerDebitTotal.toFixed(2),
      adjustedBalance: ledgerDebitAdjustedBalance.toFixed(2),
      dateHeader: undefined,
      descriptionHeader: undefined,
      referenceHeader: undefined,
      amountHeader: 'Debit',
    };
    unreconciledTransactionsBlocks.push(ledgerDebitBlock);

    // 3. Process bank credit transactions
    const bankUnreconciledTransactions = report.externalTransactions.filter(
      (transaction) => transaction.status === ReconciliationTransactionStatuses.Unreconciled
    );
    const bankCreditTransactions = bankUnreconciledTransactions.filter(
      (transaction) => transaction.credit > 0 || transaction.credit < 0
    );

    const bankCreditTotal = bankCreditTransactions.reduce(
      (total, transaction) => total + transaction.credit,
      0
    );
    const bankCreditAdjustedBalance = ledgerDebitAdjustedBalance + bankCreditTotal;
    const bankCreditBlock: UnreconciledTransactionsBlock = {
      title: this.getExternalCreditsHeader(reconciliationAccount),
      blocks: bankCreditTransactions.map((transaction) => ({
        date: transaction.date,
        description: transaction.description,
        reference: transaction.reference,
        amount: transaction.credit.toFixed(2),
        financialTransactionId: transaction.financialTransactionId,
      })),
      type: 'bank',
      total: bankCreditTotal.toFixed(2),
      adjustedBalance: bankCreditAdjustedBalance.toFixed(2),
      dateHeader: undefined,
      descriptionHeader: undefined,
      referenceHeader: undefined,
      amountHeader: 'Credit',
    };
    unreconciledTransactionsBlocks.push(bankCreditBlock);

    // 4. Process bank debit transactions
    const bankDebitTransactions = bankUnreconciledTransactions.filter(
      (transaction) => transaction.debit > 0 || transaction.debit < 0
    );
    const bankDebitTotal = bankDebitTransactions.reduce(
      (total, transaction) => total + transaction.debit,
      0
    );
    const bankDebitAdjustedBalance = bankCreditAdjustedBalance - bankDebitTotal;
    const bankDebitBlock: UnreconciledTransactionsBlock = {
      title: this.getExternalDebitsHeader(reconciliationAccount),
      blocks: bankDebitTransactions.map((transaction) => ({
        date: transaction.date,
        description: transaction.description,
        reference: transaction.reference,
        amount: transaction.debit.toFixed(2),
        financialTransactionId: transaction.financialTransactionId,
      })),
      type: 'bank',
      total: bankDebitTotal.toFixed(2),
      adjustedBalance: bankDebitAdjustedBalance.toFixed(2),
      dateHeader: undefined,
      descriptionHeader: undefined,
      referenceHeader: undefined,
      amountHeader: 'Debit',
    };
    unreconciledTransactionsBlocks.push(bankDebitBlock);
    const reconciliationDifference = (bankDebitAdjustedBalance - report.bankClosingBalance).toFixed(
      2
    );

    return {
      unreconciledTransactions: unreconciledTransactionsBlocks,
      reconciliationDifference,
    };
  }

  private static getUnreconciledTransactionsTransactionMatching(
    report: ReconciliationReport,
    reconciliationAccount: ReconciliationAccount | null
  ): {
    unreconciledTransactions: UnreconciledTransactionsBlock[];
    reconciliationDifference: string;
    ledgerSumDifference: number;
    bankSumDifference: number;
  } {
    const unreconciledTransactionsBlocks: UnreconciledTransactionsBlock[] = [];

    const sumOfLedgerCredits = report.ledgerTransactions.reduce(
      (sum, transaction) => sum + transaction.credit,
      0
    );

    const sumOfledgerDebits = report.ledgerTransactions.reduce(
      (sum, transaction) => sum + transaction.debit,
      0
    );

    const sumOfCreditsBank = report.externalTransactions.reduce(
      (sum, transaction) => sum + transaction.credit,
      0
    );

    const sumOfDebitsBank = report.externalTransactions.reduce(
      (sum, transaction) => sum + transaction.debit,
      0
    );

    const datasetBNetSum = sumOfledgerDebits - sumOfLedgerCredits;
    const datasetANetSum = sumOfCreditsBank - sumOfDebitsBank;

    // 1. Process ledger credit transactions
    const ledgerUnreconciledTransactions = report.ledgerTransactions.filter(
      (transaction) => transaction.status === ReconciliationTransactionStatuses.Unreconciled
    );
    // check if the transaction is a credit transaction by checking
    // that it has a value other than 0,null or undefined
    const ledgerCreditTransactions = ledgerUnreconciledTransactions.filter(
      (transaction) => transaction.credit > 0 || transaction.credit < 0
    );
    const ledgerCreditTotal = ledgerCreditTransactions.reduce(
      (total, transaction) => total + transaction.credit,
      0
    );
    const ledgerCreditAdjustedBalance = datasetBNetSum + ledgerCreditTotal;
    const ledgerCreditBlock: UnreconciledTransactionsBlock = {
      title: this.getLedgerCreditsHeader(reconciliationAccount),
      blocks: ledgerCreditTransactions.map((transaction) => ({
        date: transaction.date,
        description: transaction.description,
        reference: transaction.reference,
        amount: transaction.credit.toFixed(2),
        financialTransactionId: transaction.financialTransactionId,
      })),
      type: 'ledger',
      total: ledgerCreditTotal.toFixed(2),
      adjustedBalance: ledgerCreditAdjustedBalance.toFixed(2),
      dateHeader: undefined,
      descriptionHeader: undefined,
      referenceHeader: undefined,
      amountHeader: 'Credit',
    };
    unreconciledTransactionsBlocks.push(ledgerCreditBlock);

    // 2. Process ledger debit transactions
    const ledgerDebitTransactions = ledgerUnreconciledTransactions.filter(
      (transaction) => transaction.debit > 0 || transaction.debit < 0
    );
    const ledgerDebitTotal = ledgerDebitTransactions.reduce(
      (total, transaction) => total + transaction.debit,
      0
    );
    const ledgerDebitAdjustedBalance = ledgerCreditAdjustedBalance - ledgerDebitTotal;
    const ledgerDebitBlock: UnreconciledTransactionsBlock = {
      title: this.getLedgerDebitsHeader(reconciliationAccount),
      blocks: ledgerDebitTransactions.map((transaction) => ({
        date: transaction.date,
        description: transaction.description,
        reference: transaction.reference,
        amount: transaction.debit.toFixed(2),
        financialTransactionId: transaction.financialTransactionId,
      })),
      type: 'ledger',
      total: ledgerDebitTotal.toFixed(2),
      adjustedBalance: ledgerDebitAdjustedBalance.toFixed(2),
      dateHeader: undefined,
      descriptionHeader: undefined,
      referenceHeader: undefined,
      amountHeader: 'Debit',
    };
    unreconciledTransactionsBlocks.push(ledgerDebitBlock);

    // 3. Process bank credit transactions
    const bankUnreconciledTransactions = report.externalTransactions.filter(
      (transaction) => transaction.status === ReconciliationTransactionStatuses.Unreconciled
    );
    const bankCreditTransactions = bankUnreconciledTransactions.filter(
      (transaction) => transaction.credit > 0 || transaction.credit < 0
    );

    const bankCreditTotal = bankCreditTransactions.reduce(
      (total, transaction) => total + transaction.credit,
      0
    );
    const bankCreditAdjustedBalance = ledgerDebitAdjustedBalance + bankCreditTotal;
    const bankCreditBlock: UnreconciledTransactionsBlock = {
      title: this.getExternalCreditsHeader(reconciliationAccount),
      blocks: bankCreditTransactions.map((transaction) => ({
        date: transaction.date,
        description: transaction.description,
        reference: transaction.reference,
        amount: transaction.credit.toFixed(2),
        financialTransactionId: transaction.financialTransactionId,
      })),
      type: 'bank',
      total: bankCreditTotal.toFixed(2),
      adjustedBalance: bankCreditAdjustedBalance.toFixed(2),
      dateHeader: undefined,
      descriptionHeader: undefined,
      referenceHeader: undefined,
      amountHeader: 'Credit',
    };
    unreconciledTransactionsBlocks.push(bankCreditBlock);

    // 4. Process bank debit transactions
    const bankDebitTransactions = bankUnreconciledTransactions.filter(
      (transaction) => transaction.debit > 0 || transaction.debit < 0
    );
    const bankDebitTotal = bankDebitTransactions.reduce(
      (total, transaction) => total + transaction.debit,
      0
    );
    const bankDebitAdjustedBalance = bankCreditAdjustedBalance - bankDebitTotal;
    const bankDebitBlock: UnreconciledTransactionsBlock = {
      title: this.getExternalDebitsHeader(reconciliationAccount),
      blocks: bankDebitTransactions.map((transaction) => ({
        date: transaction.date,
        description: transaction.description,
        reference: transaction.reference,
        amount: transaction.debit.toFixed(2),
        financialTransactionId: transaction.financialTransactionId,
      })),
      type: 'bank',
      total: bankDebitTotal.toFixed(2),
      adjustedBalance: bankDebitAdjustedBalance.toFixed(2),
      dateHeader: undefined,
      descriptionHeader: undefined,
      referenceHeader: undefined,
      amountHeader: 'Debit',
    };
    unreconciledTransactionsBlocks.push(bankDebitBlock);
    const reconciliationDifference = (bankDebitAdjustedBalance - datasetANetSum).toFixed(2);

    return {
      unreconciledTransactions: unreconciledTransactionsBlocks,
      reconciliationDifference,
      ledgerSumDifference: datasetBNetSum,
      bankSumDifference: datasetANetSum,
    };
  }

  private static fixTransactionAmounts = (transactions: Transaction[]): ReconciledTransaction[] => {
    const fixedTransactions: ReconciledTransaction[] = [];
    transactions.forEach((transaction) => {
      fixedTransactions.push({
        date: transaction.date,
        description: transaction.description,
        reference: transaction.reference,
        debit: transaction.debit.toFixed(2),
        credit: transaction.credit.toFixed(2),
        financialTransactionId: transaction.financialTransactionId,
      });
    });
    return fixedTransactions;
  };

  private static getReconciledTransactions(
    report: ReconciliationReport
  ): ReconciledTransactionsBlock[] {
    const sortedMatches = report.matches.slice().sort((a, b) => {
      const bankTransactionA = a.bankStatementTransactions[0];
      const bankTransactionB = b.bankStatementTransactions[0];

      // Ensure both transactions exist and have a date
      if (bankTransactionA && bankTransactionA.date && bankTransactionB && bankTransactionB.date) {
        const bankTransactionADate = DateUtils.ParseCustomDate(bankTransactionA.date).getTime();
        const bankTransactionBDate = DateUtils.ParseCustomDate(bankTransactionB.date).getTime();
        return bankTransactionADate - bankTransactionBDate;
      }

      // If transaction A is invalid, place it after B
      if (!bankTransactionA || !bankTransactionA.date) return 1;

      // If transaction B is invalid, place it after A
      if (!bankTransactionB || !bankTransactionB.date) return -1;

      return 0; // If both are invalid, consider them equal
    });

    const reconciledTransactionsBlocks: ReconciledTransactionsBlock[] = sortedMatches.map(
      (match) => {
        const reconciledBlock: ReconciledTransactionsBlock = {
          id: match.id,
          bankStatementTransactions: ReconciliationReportExcelFile.fixTransactionAmounts(
            match.bankStatementTransactions
          ),
          ledgerTransactions: ReconciliationReportExcelFile.fixTransactionAmounts(
            match.ledgerTransactions
          ),
          type: match.type,
          createdBy: match.createdBy,
        };
        return reconciledBlock;
      }
    );
    return reconciledTransactionsBlocks;
  }

  private static applyNumericFormatToColumn = (ws: WorkSheet, columns: string[]): WorkSheet => {
    const fmt = '0.00';
    const range = 20000;
    const regExp = /[a-zA-Z]/g;
    for (const column of columns) {
      const C = utils.decode_col(column); // 1
      for (let i = 1; i <= range; i += 1) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        const ref = utils.encode_cell({ r: i, c: C });
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if (!ws[ref] || !ws[ref].v || regExp.test(ws[ref].v)) continue;
        // console.log('ws[ref]', ws[ref]);
        /* `.t == "n"` for number cells */
        // if (ws[ref].t !== 'n') continue;
        ws[ref].t = 'n';
        /* assign the `.z` number format */
        ws[ref].z = fmt;
      }
    }
    return ws;
  };
}
