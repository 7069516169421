import FilledButton from '../../../Buttons/FilledButton';
import { RejectAllBlock } from './Styles';

interface RejectAllButtonProps {
  onClick: () => void;
  disabled: boolean;
}

function RejectAllButton(props: RejectAllButtonProps): JSX.Element {
  const { onClick, disabled } = props;
  return (
    <RejectAllBlock>
      <FilledButton content="Reject all" onClick={onClick} disabled={disabled} />
    </RejectAllBlock>
  );
}
export default RejectAllButton;
