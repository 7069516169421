import { observer } from 'mobx-react-lite';
import * as XLSX from 'xlsx';
import { CUSTOM_OPTIONS, useConvertersAndTemplatesViewModel } from '../../view-models/ConvertersAndTemplatesViewModel';

import { ConvertersAndTemplatesPage } from './page';
import { useReconciliationAccountViewModel } from '../../view-models/ReconciliationAccountViewModel';

// eslint-disable-next-line import/prefer-default-export
export const ConvertersAndTemplatesController: React.FC = observer(() => {
  const convertersAndTemplatesViewModel = useConvertersAndTemplatesViewModel();
  const reconciliationAccountViewModel = useReconciliationAccountViewModel();

  const handleBankConvert = (sheet: XLSX.WorkSheet, fileName: string, bankType: string) => {
    convertersAndTemplatesViewModel.convertBankStatement(sheet, fileName, bankType);
  };

  const handleCustomConvert = (sheet: XLSX.WorkSheet, fileName: string, bankType: CUSTOM_OPTIONS) => {
    convertersAndTemplatesViewModel.convertCustomDocument(sheet, fileName, bankType);
  };

  return (
    <ConvertersAndTemplatesPage
      pdfUpladFileStatus={convertersAndTemplatesViewModel.pdfUploadFileStatus}
      customUploadFileStatus={convertersAndTemplatesViewModel.customUploadFileStatus}
      bankUploadFileStatus={convertersAndTemplatesViewModel.bankUploadFileStatus}
      ledgerUploadFileStatus={convertersAndTemplatesViewModel.ledgerUploadFileStatus}
      selectedCustom={convertersAndTemplatesViewModel.selectedCustom}
      customOptions={convertersAndTemplatesViewModel.customOptions}
      bankTransactionTypes={convertersAndTemplatesViewModel.bankTransactionTypes}
      erpOptions={convertersAndTemplatesViewModel.ledgerERPOptions}
      handleBankConvert={handleBankConvert}
      status="success"
      selectedERP={convertersAndTemplatesViewModel.selectedERP}
      selectedBank={convertersAndTemplatesViewModel.selectedBank}
      setSelectedBank={convertersAndTemplatesViewModel.handleBankTypeChange}
      uploadedBankFile={convertersAndTemplatesViewModel.uploadedBankFile}
      uploadedCustomFile={convertersAndTemplatesViewModel.uploadedCustomFile}
      handleBankFileUpload={convertersAndTemplatesViewModel.handleBankFileUpload}
      downloadLedgerTemplate={
        convertersAndTemplatesViewModel.handleDownloadLedgerTemplateButtonClick
      }
      downloadBankTemplate={convertersAndTemplatesViewModel.handleDownloadBankTemplateButtonClick}
      externalDocumentType={reconciliationAccountViewModel.externalDocumentType}
      ledgerDocumentType={reconciliationAccountViewModel.ledgerDocumentType}
      showCreateReconciliationAccountDialog={
        reconciliationAccountViewModel.showCreateReconciliationAccountDialog
      }
      handlePdfFileUpload={convertersAndTemplatesViewModel.handlePdfFileUpload}
      convertPdfDocument={convertersAndTemplatesViewModel.convertPdfDocument}
      convertLedgerDocument={convertersAndTemplatesViewModel.convertLedgerStatement}
      handleLedgerFileUpload={convertersAndTemplatesViewModel.handleLedgerFileUpload}
      setSelectedERP={convertersAndTemplatesViewModel.handleERPOptionChange}
      handleCustomFileUpload={convertersAndTemplatesViewModel.handleCustomFileUpload}
      handleCustomConvert={handleCustomConvert}
      setSelectedCustom={convertersAndTemplatesViewModel.handleCustomOptionChange}
      setLedgerUploadFileStatus={convertersAndTemplatesViewModel.setLedgerUploadFileStatus}
      setBankUploadFileStatus={convertersAndTemplatesViewModel.setBankUploadFileStatus}
      setCustomUploadFileStatus={convertersAndTemplatesViewModel.setCustomUploadFileStatus}
      setPdfUploadFileStatus={convertersAndTemplatesViewModel.setPdfUploadFileStatus}
    />
  );
});
