import { Fragment } from 'react';
import { Button, Divider } from '@mui/material';
import TextField from '@mui/material/TextField';
import { CategoriesTable, TableHeader, TableBody, Category as CategoryDiv, Item } from './Styles';
import CategoryBlock from './CategoryBlock';
import { Category } from '../../../../models/Resources';
import { NewWorkspaceCategory } from '../../../CreateDialog/NewCategoryDialog';
import { NewWorkspaceCategoryItem } from '../../../CreateDialog/NewCategoryItemDialog';
import { EditWorkspaceCategoryItem } from '../../../CreateDialog/CategoryItemControlsDialog';

interface CategoriesProps {
  // content: string;
  categories: Category[];
  updateCategoryName: (categoryId: string) => Promise<void>;
  deleteCategory: (categoryId: string) => Promise<void>;
  showAddCategoryDialog: () => void;
  setCategoryName: (value: string) => void;
  showAddCategoryItemDialog: (categoryId: string) => void;
  showEditCategoryItemDialog: (categoryId: string, itemId: string) => void;
}

function CategoriesView(props: CategoriesProps): JSX.Element {
  const {
    categories,
    updateCategoryName,
    deleteCategory,
    showAddCategoryDialog,
    showAddCategoryItemDialog,
    setCategoryName,
    showEditCategoryItemDialog,
  } = props;

  return (
    <CategoriesTable
      sx={{
        width: {
          xs: 'var(--tab-content-min-width)',
          lg: 'var(--tab-content-max-width)',
        },
      }}
    >
      <TableHeader>
        <CategoryDiv>Category</CategoryDiv>
        <Item>Items</Item>
      </TableHeader>
      <Divider />
      <TableBody>
        {categories.map((item) => (
          <Fragment key={item.id}>
            <CategoryBlock
              key={item.id}
              name={item.name}
              nameInput={
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  defaultValue={item.name}
                  onChange={(event) => setCategoryName(event.target.value)}
                />
              }
              updateField={() => updateCategoryName(item.id)}
              deleteField={() => deleteCategory(item.id)}
              items={item.items}
              showAddCategoryItemDialog={() => showAddCategoryItemDialog(item.id)}
              showEditCategoryItemDialog={(itemId: string) =>
                showEditCategoryItemDialog(item.id, itemId)
              }
            />
            <NewWorkspaceCategoryItem />
          </Fragment>
        ))}
        <EditWorkspaceCategoryItem />
        <Button variant="text" onClick={showAddCategoryDialog}>
          Add Category
        </Button>
        <NewWorkspaceCategory />
      </TableBody>
    </CategoriesTable>
  );
}
export default CategoriesView;
