import { ReconciledTransaction } from '../models/ReconciliationReport';

export default class ReconciledTransactionHelper {
    static isCredit(transaction: ReconciledTransaction): boolean {
        return transaction.credit !== '';
    }

    static isDebit(transaction: ReconciledTransaction): boolean {
        return transaction.debit !== '';
    }

    static getCreditNumber(transaction: ReconciledTransaction): number {
        if (!this.isCredit(transaction)) {
            return 0;
        }
        return this.parseNumber(transaction.credit);
    }

    static getDebitNumber(transaction: ReconciledTransaction): number {
        if (!this.isDebit(transaction)) {
            return 0;
        }
        return this.parseNumber(transaction.debit);
    }

    private static parseNumber(value: string): number {
        const cleanedValue = value.replace(/[^0-9.-]+/g, '');
        return parseFloat(cleanedValue) || 0;
    }
}