import { jwtDecode } from 'jwt-decode';
import {
  CreateEntityError,
  CreateReconciliationAccountError,
  CreateWorkspaceError,
  DeleteEntityError,
  DeleteReconciliationAccountError,
  DeleteWorkspaceError,
  EntityFetchError,
  EntitySettingsFetchError,
  IResourcesRepository,
  ReconciliationAccountFetchError,
  UpdateEntityError,
  UpdateReconciliationAccountError,
  UpdateWorkspaceError,
  WorkspaceFetchError,
  WorkspaceSettingsFetchError,
} from '.';
import {
  SELECTED_ACCOUNT_LOCAL_STORAGE_KEY,
  SELECTED_ENTITY_LOCAL_STORAGE_KEY,
  SELECTED_WORKSPACE_LOCAL_STORAGE_KEY,
  WORKSPACES_LOCAL_STORAGE_KEY,
} from '../../../constants';
import {
  Category,
  CreateEntityParams,
  CreateReconciliationAccountParams,
  CreateWorkspaceParams,
  EditReconciliationAccountParams,
  Entity,
  EntityParams,
  EntitySettings,
  EntitySlim,
  InheritedCategory,
  InheritedStopListItem,
  ReconciliationAccount,
  ReconciliationAccountSlim,
  StopListItem,
  UpdateEntityParams,
  UpdateWorkspaceParams,
  WorkspaceSettings,
} from '../../../models/Resources';
import { AsyncStatus, DecodedToken, State, fetchedState } from '../../../types';
import {
  Entity as ServiceEntity,
  IResourcesService,
  Workspace,
} from '../../interfaces/IResourcesService';
import { LocalStorageRepository } from '../LocalStorage';
import {
  CategoryId,
  CategoryItemDeletedPayload,
  CategoryItemPayload,
  EventBus,
  Events,
  StopListItemId,
} from '../../../Events';

class ResourcesRepository implements IResourcesRepository {
  constructor(private resourcesService: IResourcesService) {
    EventBus.subscribe(Events.WORKSPACE_SELECT, this.selectWorkspaceHandler);
    EventBus.subscribe(Events.ENTITY_SELECT, this.selectEntityHandler);
    EventBus.subscribe(
      Events.RECONCILIATION_ACCOUNT_SELECT,
      this.selectReconciliationAccountHandler
    );
  }

  getWorkspaces(): State<Workspace[], WorkspaceFetchError> {
    this.fetchWorkspaces();
    const localWorkspaces = LocalStorageRepository.getLocalStorageStateObject<Workspace[]>(
      WORKSPACES_LOCAL_STORAGE_KEY
    );
    return {
      status: AsyncStatus.CACHED,
      fetchedAt: localWorkspaces.fetchedAt,
      data: localWorkspaces.data,
      error: null,
    };
  }

  fetchWorkspaces(): void {
    new Promise<State<Workspace[], WorkspaceFetchError>>(async (resolve, reject) => {
      try {
        const workspaces: Workspace[] = await this.resourcesService.getWorkspaces({
          idToken: LocalStorageRepository.getAccessToken(),
        });
        const workspacesState: State<Workspace[], null> = fetchedState(workspaces);
        if (workspacesState.data !== null)
          LocalStorageRepository.setLocalStorageStateObject<Workspace[]>(
            WORKSPACES_LOCAL_STORAGE_KEY,
            workspacesState.data
          );
        resolve(workspacesState);
      } catch (error) {
        console.log('error', error);
        reject(error);
      }
    })
      .then((workspacesResponse: State<Workspace[], WorkspaceFetchError>) => {
        EventBus.emit(Events.WORKSPACES_FETCH, workspacesResponse);
        console.log('set workspaces', workspacesResponse);
      })
      .catch((error) => {
        const errorResponse: State<null, WorkspaceFetchError> = {
          status: AsyncStatus.ERROR,
          fetchedAt: Date.now(),
          data: null,
          error,
        };
        // const errorResponse = errorState<WorkspaceFetchError>(error); // TODO replace error response with this
        EventBus.emit(Events.WORKSPACES_FETCH, errorResponse);
      });
  }

  async updateReconciliationAccount(
    params: Partial<EditReconciliationAccountParams>
  ): Promise<void> {
    try {
      const workspaceId = LocalStorageRepository.getWorkspaceId();
      if (!workspaceId) throw new Error('No workspace found');
      const entityId = LocalStorageRepository.getEntityId();
      if (!entityId) throw new Error('No entity found');
      const reconciliationAccountId = LocalStorageRepository.getAccountId();
      if (!reconciliationAccountId) throw new Error('No reconciliation account found');
      const token = LocalStorageRepository.getAccessToken();
      if (!token) throw new Error('No token found');
      const updateParams = {
        ...params,
        workspaceId,
        entityId,
        reconciliationAccountId,
      };
      await this.resourcesService.updateReconciliationAccount(updateParams, { idToken: token });

      const entityState = LocalStorageRepository.getLocalStorageStateObject<Entity>(
        SELECTED_ENTITY_LOCAL_STORAGE_KEY
      );
      const reconciliationAccountState =
        LocalStorageRepository.getLocalStorageStateObject<ReconciliationAccount>(
          SELECTED_ACCOUNT_LOCAL_STORAGE_KEY
        );
      if (entityState.data && reconciliationAccountState.data) {
        const entity = entityState.data;
        const reconciliationAccount = reconciliationAccountState.data;
        const updatedAccount: ReconciliationAccount = {
          ...reconciliationAccount,
          updatedAt: Date.now(),
        };
        if (params.name) {
          updatedAccount.name = params.name;
        }
        if (params.counterpartyContactEmail) {
          updatedAccount.counterpartyContactEmail = params.counterpartyContactEmail;
        }
        if (params.counterpartyContactName) {
          updatedAccount.counterpartyContactName = params.counterpartyContactName;
        }
        const accountsNew = entity.reconciliationAccountsList.map(
          (r: ReconciliationAccountSlim) => {
            if (r.id !== reconciliationAccountId) return r;
            return {
              id: updatedAccount.id,
              name: updatedAccount.name,
              reconciliationType: updatedAccount.reconciliationType,
            };
          }
        );
        const updatedEntity: Entity = {
          ...entity,
          reconciliationAccountsList: accountsNew,
        };
        LocalStorageRepository.setLocalStorageStateObject<ReconciliationAccount>(
          SELECTED_ACCOUNT_LOCAL_STORAGE_KEY,
          updatedAccount
        );
        LocalStorageRepository.setLocalStorageStateObject<Entity>(
          SELECTED_ENTITY_LOCAL_STORAGE_KEY,
          updatedEntity
        );
        EventBus.emit(
          Events.RECONCILIATION_ACCOUNT_UPDATE,
          fetchedState<ReconciliationAccount>(updatedAccount)
        );
      }
    } catch (error: any) {
      const errorResponse: State<null, UpdateReconciliationAccountError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.RECONCILIATION_ACCOUNT_UPDATE, errorResponse);
    }
  }

  async updateEntity(params: UpdateEntityParams): Promise<void> {
    try {
      const workspaceId = LocalStorageRepository.getWorkspaceId();
      if (!workspaceId) throw new Error('No workspace found');
      const entityId = LocalStorageRepository.getEntityId();
      if (!entityId) throw new Error('No entity found');
      const token = LocalStorageRepository.getAccessToken();
      if (!token) throw new Error('No token found');
      const updateParams = {
        ...params,
        workspaceId,
        entityId,
      };
      await this.resourcesService.updateEntity(updateParams, { idToken: token });

      const entityState = LocalStorageRepository.getLocalStorageStateObject<Entity>(
        SELECTED_ENTITY_LOCAL_STORAGE_KEY
      );
      const workspaceState = LocalStorageRepository.getLocalStorageStateObject<Workspace>(
        SELECTED_WORKSPACE_LOCAL_STORAGE_KEY
      );
      const workspacesState = LocalStorageRepository.getLocalStorageStateObject<Workspace[]>(
        WORKSPACES_LOCAL_STORAGE_KEY
      );
      if (workspaceState.data && entityState.data && workspacesState.data) {
        const entity = entityState.data;
        const workspace = workspaceState.data;
        const workspaces = workspacesState.data;
        const updatedEntity: Entity = {
          ...entity,
          updatedAt: Date.now(),
        };
        if (params.name) {
          updatedEntity.name = params.name;
        }
        if (params.country) {
          updatedEntity.country = params.country;
        }
        if (params.language) {
          updatedEntity.language = params.language;
        }
        const entitiesNew = workspace.entities.map((e: EntitySlim) => {
          if (e.id !== entityId) return e;
          return {
            id: updatedEntity.id,
            name: updatedEntity.name,
          };
        });
        const updatedWorkspace: Workspace = {
          ...workspace,
          entities: entitiesNew,
        };
        const workspacesNew = workspaces.map((w) => (w.id !== workspace.id ? w : updatedWorkspace));
        LocalStorageRepository.setLocalStorageStateObject<Entity>(
          SELECTED_ENTITY_LOCAL_STORAGE_KEY,
          updatedEntity
        );
        LocalStorageRepository.setLocalStorageStateObject<Workspace>(
          SELECTED_WORKSPACE_LOCAL_STORAGE_KEY,
          updatedWorkspace
        );
        LocalStorageRepository.setLocalStorageStateObject<Workspace[]>(
          WORKSPACES_LOCAL_STORAGE_KEY,
          workspacesNew
        );
        EventBus.emit(Events.ENTITY_UPDATE, fetchedState<Entity>(updatedEntity));
      }
    } catch (error: any) {
      const errorResponse: State<null, UpdateEntityError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_UPDATE, errorResponse);
    }
  }

  async updateWorkspace(updatedParams: UpdateWorkspaceParams): Promise<void> {
    const params = { ...updatedParams };
    try {
      const workspaceId = LocalStorageRepository.getWorkspaceId();
      if (!workspaceId) throw new Error('No workspace found');
      const token = LocalStorageRepository.getAccessToken();
      if (!token) throw new Error('No token found');
      params.workspaceId = workspaceId;
      await this.resourcesService.updateWorkspace(params, { idToken: token });

      const workspaceState = LocalStorageRepository.getLocalStorageStateObject<Workspace>(
        SELECTED_WORKSPACE_LOCAL_STORAGE_KEY
      );
      const workspacesState = LocalStorageRepository.getLocalStorageStateObject<Workspace[]>(
        WORKSPACES_LOCAL_STORAGE_KEY
      );
      if (workspaceState.data && workspacesState.data) {
        const workspace = workspaceState.data;
        const workspaces = workspacesState.data;
        const updatedWorkspace: Workspace = {
          ...workspace,
          updatedAt: Date.now(),
        };
        if (params.name) {
          updatedWorkspace.name = params.name;
        }
        if (params.country) {
          updatedWorkspace.country = params.country;
        }
        if (params.language) {
          updatedWorkspace.language = params.language;
        }
        const workspacesNew = workspaces.map((w) => (w.id !== workspace.id ? w : updatedWorkspace));
        LocalStorageRepository.setLocalStorageStateObject<Workspace>(
          SELECTED_WORKSPACE_LOCAL_STORAGE_KEY,
          updatedWorkspace
        );
        LocalStorageRepository.setLocalStorageStateObject<Workspace[]>(
          WORKSPACES_LOCAL_STORAGE_KEY,
          workspacesNew
        );
        EventBus.emit(Events.WORKSPACE_UPDATE, fetchedState<Workspace>(updatedWorkspace));
      }
    } catch (error: any) {
      const errorResponse: State<null, UpdateWorkspaceError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.WORKSPACE_UPDATE, errorResponse);
    }
  }

  async deleteWorkspace(): Promise<void> {
    try {
      const workspaceId = LocalStorageRepository.getWorkspaceId();
      if (!workspaceId) throw new Error('No workspace found');
      const token = LocalStorageRepository.getAccessToken();
      if (!token) throw new Error('No token found');
      await this.resourcesService.deleteWorkspace(workspaceId, { idToken: token });

      const workspacesState = LocalStorageRepository.getLocalStorageStateObject<Workspace[]>(
        WORKSPACES_LOCAL_STORAGE_KEY
      );

      if (workspacesState.data) {
        const workspaces = workspacesState.data;
        const workspacesNew = workspaces.filter((ws) => ws.id !== workspaceId);

        LocalStorageRepository.setLocalStorageStateObject<Workspace | null>(
          SELECTED_WORKSPACE_LOCAL_STORAGE_KEY,
          null
        );

        LocalStorageRepository.setLocalStorageStateObject<Entity | null>(
          SELECTED_ENTITY_LOCAL_STORAGE_KEY,
          null
        );

        LocalStorageRepository.setLocalStorageStateObject<Entity | null>(
          SELECTED_ACCOUNT_LOCAL_STORAGE_KEY,
          null
        );

        LocalStorageRepository.setLocalStorageStateObject<Workspace[]>(
          WORKSPACES_LOCAL_STORAGE_KEY,
          workspacesNew
        );
        EventBus.emit(Events.WORKSPACE_DELETE, fetchedState<string>(workspaceId));
      }
    } catch (error: any) {
      const errorResponse: State<null, DeleteWorkspaceError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.WORKSPACE_UPDATE, errorResponse);
    }
  }

  async deleteEntity(): Promise<void> {
    try {
      const workspaceId: string | null = LocalStorageRepository.getWorkspaceId();
      if (!workspaceId) throw new Error('No workspace found');
      const entityId = LocalStorageRepository.getEntityId();
      if (!entityId) throw new Error('No entity was found');
      const token = LocalStorageRepository.getAccessToken();
      if (!token) throw new Error('No token found');

      await this.resourcesService.deleteEntity({ entityId, workspaceId }, { idToken: token });

      const workspacesState = LocalStorageRepository.getLocalStorageStateObject<Workspace[]>(
        WORKSPACES_LOCAL_STORAGE_KEY
      );
      const selectedWorkspaceState = LocalStorageRepository.getLocalStorageStateObject<Workspace>(
        SELECTED_WORKSPACE_LOCAL_STORAGE_KEY
      );
      if (workspacesState.data && selectedWorkspaceState.data) {
        const workspaces = workspacesState.data;
        const workspaceFound = workspaces.find((ws) => ws.id === workspaceId);
        const selectedWorkspace = selectedWorkspaceState.data;
        const entities = workspaceFound?.entities || [];
        const entitiesNew = entities.filter((e) => e.id !== entityId);
        workspaceFound!.entities = entitiesNew;
        const workspacesNew = workspaces.map((ws) =>
          ws.id !== workspaceId ? ws : workspaceFound!
        );
        const selectedWorkspaceEntities = selectedWorkspace.entities || [];
        const selectedWorkspaceEntitiesNew = selectedWorkspaceEntities.filter(
          (e) => e.id !== entityId
        );
        selectedWorkspace.entities = selectedWorkspaceEntitiesNew;
        console.log('selectedWorkspace new deleted entity', selectedWorkspace);

        LocalStorageRepository.setLocalStorageStateObject<Entity | null>(
          SELECTED_ENTITY_LOCAL_STORAGE_KEY,
          null
        );

        LocalStorageRepository.setLocalStorageStateObject<Workspace[]>(
          WORKSPACES_LOCAL_STORAGE_KEY,
          workspacesNew
        );
        LocalStorageRepository.setLocalStorageStateObject<Entity | null>(
          SELECTED_ACCOUNT_LOCAL_STORAGE_KEY,
          null
        );
        LocalStorageRepository.setLocalStorageStateObject<Workspace>(
          SELECTED_WORKSPACE_LOCAL_STORAGE_KEY,
          selectedWorkspace
        );
        EventBus.emit(Events.ENTITY_DELETE, fetchedState<string>(entityId));
      }
    } catch (error: any) {
      const errorResponse: State<null, DeleteEntityError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_DELETE, errorResponse);
    }
  }

  async deleteReconciliationAccount(): Promise<void> {
    try {
      const workspaceId: string | null = LocalStorageRepository.getWorkspaceId();
      if (!workspaceId) throw new Error('No workspace found');
      const entityId = LocalStorageRepository.getEntityId();
      if (!entityId) throw new Error('No entity was found');
      const reconciliationAccountId = LocalStorageRepository.getAccountId();
      if (!reconciliationAccountId) throw new Error('No reconciliation was found');
      const token = LocalStorageRepository.getAccessToken();
      if (!token) throw new Error('No token found');

      await this.resourcesService.deleteReconciliationAccount(
        { entityId, workspaceId, reconciliationAccountId },
        { idToken: token }
      );

      const entityState = LocalStorageRepository.getLocalStorageStateObject<Entity>(
        SELECTED_ENTITY_LOCAL_STORAGE_KEY
      );

      if (entityState.data) {
        const entity = entityState.data;
        const reconciliationAccounts = entity.reconciliationAccountsList || [];
        const reconciliationAccountsNew = reconciliationAccounts.filter(
          (a) => a.id !== reconciliationAccountId
        );
        entity.reconciliationAccountsList = reconciliationAccountsNew;

        LocalStorageRepository.setLocalStorageStateObject<ReconciliationAccount | null>(
          SELECTED_ACCOUNT_LOCAL_STORAGE_KEY,
          null
        );

        LocalStorageRepository.setLocalStorageStateObject<Entity>(
          SELECTED_ENTITY_LOCAL_STORAGE_KEY,
          entity
        );

        EventBus.emit(
          Events.RECONCILIATION_ACCOUNT_DELETE,
          fetchedState<string>(reconciliationAccountId)
        );
      }
    } catch (error: any) {
      const errorResponse: State<null, DeleteReconciliationAccountError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.RECONCILIATION_ACCOUNT_DELETE, errorResponse);
    }
  }

  async createWorkspace(params: CreateWorkspaceParams): Promise<void> {
    try {
      const id = await this.resourcesService.createWorkspace(params, {
        idToken: LocalStorageRepository.getAccessToken(),
      });
      const workspacesState = LocalStorageRepository.getLocalStorageStateObject<Workspace[]>(
        WORKSPACES_LOCAL_STORAGE_KEY
      );
      if (workspacesState.data == null) {
        workspacesState.data = [];
        workspacesState.fetchedAt = Date.now();
      }
      const workspaces = workspacesState.data;
      const newWorkspace: Workspace = {
        id,
        name: params.name,
        country: params.country,
        language: params.language,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        entities: [],
        // entityViewModel: new EntityViewModel(id, this),
      };
      workspaces.push(newWorkspace);
      workspacesState.data = workspaces;
      LocalStorageRepository.setLocalStorageStateObject<Workspace[]>(
        WORKSPACES_LOCAL_STORAGE_KEY,
        workspaces
      );
      LocalStorageRepository.setLocalStorageStateObject<Workspace>(
        SELECTED_WORKSPACE_LOCAL_STORAGE_KEY,
        newWorkspace
      );
      EventBus.emit(Events.WORKSPACE_CREATE, fetchedState<Workspace>(newWorkspace));
    } catch (error: any) {
      const errorResponse: State<null, CreateWorkspaceError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.WORKSPACE_CREATE, errorResponse);
    }
  }

  fetchSelectedWorkspace(): void {
    const workspace = LocalStorageRepository.getLocalStorageStateObject<Workspace>(
      SELECTED_WORKSPACE_LOCAL_STORAGE_KEY
    );
    console.log('[ResourcesRepository] [Workspace] fetchSelectedWorkspace', workspace);
    if (workspace) {
      EventBus.emit(Events.WORKSPACES_FETCH_SELECTED, workspace);
    }
  }

  getEntityById(entityId: string): State<Entity, EntityFetchError> {
    console.log('[ResourcesRepository] [Entity] getEntityById', entityId);
    this.fetchEntity(entityId);
    return {
      status: AsyncStatus.PENDING,
      fetchedAt: 0,
      data: null,
      error: null,
    };
  }

  private fetchEntity(entityId: string): void {
    console.log('[ResourcesRepository] [Entity] fetchEntity', entityId);
    const workspaceId = LocalStorageRepository.getWorkspaceId();
    if (!workspaceId) throw new Error('No workspace selected. Cannot fetch entity.');
    new Promise<State<Entity, WorkspaceFetchError>>(async (resolve, reject) => {
      try {
        const entity: ServiceEntity = await this.resourcesService.getEntity(workspaceId, entityId, {
          idToken: LocalStorageRepository.getAccessToken(),
        });
        const fullEntity: Entity = { ...entity };
        const entityState: State<Entity, null> = {
          status: AsyncStatus.SUCCESS,
          fetchedAt: Date.now(),
          data: fullEntity,
          error: null,
        };
        resolve(entityState);
      } catch (error: any) {
        reject(error);
      }
    })
      .then((entityResponse: State<Entity, EntityFetchError>) => {
        console.log('entityResponse haha', entityResponse);
        EventBus.emit(Events.ENTITY_FETCH, entityResponse);
      })
      .catch((error) => {
        const errorResponse: State<null, EntityFetchError> = {
          status: AsyncStatus.ERROR,
          error,
          fetchedAt: Date.now(),
          data: null,
        };
        EventBus.emit(Events.ENTITY_FETCH, errorResponse);
      });
  }

  getReconciliationAccountById(
    reconciliationAccountId: string
  ): State<ReconciliationAccount, ReconciliationAccountFetchError> {
    console.log('** getReconciliationAccountById', reconciliationAccountId);
    this.fetchReconciliationAccount(reconciliationAccountId);
    return {
      status: AsyncStatus.PENDING,
      fetchedAt: 0,
      data: null,
      error: null,
    };
  }

  private fetchReconciliationAccount(reconciliationAccountId: string): void {
    const workspaceId = LocalStorageRepository.getWorkspaceId();
    const entityId = LocalStorageRepository.getEntityId();
    if (!workspaceId) throw new Error('No workspace found');
    if (!entityId) throw new Error('No entity found');
    console.log('fetching reconciliation account', reconciliationAccountId);
    new Promise<State<ReconciliationAccount, ReconciliationAccountFetchError>>(
      async (resolve, reject) => {
        try {
          const reconciliationAccount: ReconciliationAccount =
            await this.resourcesService.getReconciliationAccount(
              workspaceId,
              entityId,
              reconciliationAccountId,
              { idToken: LocalStorageRepository.getAccessToken() }
            );
          const reconciliationAccountState: State<ReconciliationAccount, null> = {
            status: AsyncStatus.SUCCESS,
            fetchedAt: Date.now(),
            data: reconciliationAccount,
            error: null,
          };
          resolve(reconciliationAccountState);
        } catch (error) {
          console.log('error', error);
          reject(error);
        }
      }
    )
      .then(
        (
          reconciliationAccountResponse: State<
            ReconciliationAccount,
            ReconciliationAccountFetchError
          >
        ) => {
          console.log('emitting reconciliation account fetch');
          EventBus.emit(Events.RECONCILIATION_ACCOUNT_FETCH, reconciliationAccountResponse);
        }
      )
      .catch((error) => {
        const errorResponse: State<null, ReconciliationAccountFetchError> = {
          status: AsyncStatus.ERROR,
          error,
          fetchedAt: Date.now(),
          data: null,
        };
        EventBus.emit(Events.RECONCILIATION_ACCOUNT_FETCH, errorResponse);
      });
  }

  async createEntity(newParams: EntityParams): Promise<void> {
    const params: CreateEntityParams = {
      owner: '',
      workspaceId: '',
      ...newParams
    };
    console.log('[ResourcesRepository] [Entity] createEntity', params);
    try {
      console.log('creating entity', params);
      const token = LocalStorageRepository.getAccessToken();
      if (!token) throw new Error('No token found');
      const decoded = jwtDecode(token) as DecodedToken;
      const ownerId = decoded.sub;
      params.owner = ownerId;
      const workspaceId = LocalStorageRepository.getWorkspaceId();
      if (!workspaceId) throw new Error('No workspace found');
      const enrichedParams = {
        ...params,
        workspaceId,
      };
      const id = await this.resourcesService.createEntity(enrichedParams, {
        idToken: token,
      });
      const newEntity: Entity = {
        id,
        name: params.name,
        country: params.country,
        language: params.language,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        owner: ownerId,
        reconciliationAccountsList: [],
        uniqueIdentifier: params.uniqueIdentifier,
        usersList: [],
      };
      LocalStorageRepository.setLocalStorageStateObject<Entity>(
        SELECTED_ENTITY_LOCAL_STORAGE_KEY,
        newEntity
      );
      const workspaceState = LocalStorageRepository.getLocalStorageStateObject<Workspace>(
        SELECTED_WORKSPACE_LOCAL_STORAGE_KEY
      );
      if (workspaceState.data) {
        const slimEntities = workspaceState.data.entities;
        slimEntities.push(newEntity);
        workspaceState.data.entities = slimEntities;
        LocalStorageRepository.setLocalStorageStateObject<Workspace>(
          SELECTED_WORKSPACE_LOCAL_STORAGE_KEY,
          workspaceState.data
        );
      }
      EventBus.emit(Events.ENTITY_CREATE, fetchedState<Entity>(newEntity));
    } catch (error: any) {
      const errorResponse: State<null, CreateEntityError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_CREATE, errorResponse);
    }
  }

  async createReconciliationAccount(newParams: CreateReconciliationAccountParams): Promise<void> {
    const params = { ...newParams };
    const token = LocalStorageRepository.getAccessToken();
    if (!token) throw new Error('No token found');
    const decoded = jwtDecode(token) as DecodedToken;
    const ownerId = decoded.sub;
    params.owner = ownerId;
    const workspaceId = LocalStorageRepository.getWorkspaceId();
    if (!workspaceId) throw new Error('No workspace found');
    const entityId = LocalStorageRepository.getEntityId();
    if (!entityId) throw new Error('No entity found');
    const enrichedParams = {
      ...params,
      entityId,
      workspaceId,
    };
    try {
      const id = await this.resourcesService.createReconciliationAccount(enrichedParams, {
        idToken: token,
      });
      console.log('created reconciliation account', id);
      // const entities = getLocalStorageStateObject<Entity[]>(SELECTED_ENTITY_LOCAL_STORAGE_KEY) || [];
      const newReconciliationAccount: ReconciliationAccount = {
        id,
        name: params.name,
        reconciliationType: params.reconciliationType,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        owner: ownerId,
        uniqueIdentifier: params.uniqueIdentifier,
        counterpartyContactEmail: params.counterpartyContactEmail,
        counterpartyContactName: params.counterpartyContactName,
        usersList: [],
      };
      EventBus.emit(
        Events.RECONCILIATION_ACCOUNT_CREATE,
        fetchedState<ReconciliationAccount>(newReconciliationAccount)
      );
    } catch (error: any) {
      const errorResponse: State<null, CreateReconciliationAccountError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.RECONCILIATION_ACCOUNT_CREATE, errorResponse);
    }
  }

  private selectWorkspaceHandler(workspace: State<Workspace, WorkspaceFetchError>): void {
    console.log('[ResourcesRepository] selectWorkspaceHandler', workspace);
    if (workspace.data)
      LocalStorageRepository.setLocalStorageStateObject<Workspace>(
        SELECTED_WORKSPACE_LOCAL_STORAGE_KEY,
        workspace.data
      );
  }

  private selectEntityHandler(entity: State<Entity | null, WorkspaceFetchError>): void {
    console.log('[ResourcesRepository] selectEntityHandler', entity);
    if (entity.data)
      LocalStorageRepository.setLocalStorageStateObject<Entity>(
        SELECTED_ENTITY_LOCAL_STORAGE_KEY,
        entity.data
      );
  }

  private selectReconciliationAccountHandler(
    reconciliationAccount: State<ReconciliationAccount | null, WorkspaceFetchError>
  ): void {
    console.log('[ResourcesRepository] selectReconciliationAccountHandler', reconciliationAccount);
    if (reconciliationAccount.data)
      LocalStorageRepository.setLocalStorageStateObject<ReconciliationAccount>(
        SELECTED_ACCOUNT_LOCAL_STORAGE_KEY,
        reconciliationAccount.data
      );
  }

  // getWorkspaceById(workspaceId: string): State<Workspace, WorkspaceFetchError> {
  //   const localSelectedWorkspaceState = LocalStorageRepository.getLocalStorageStateObject<Workspace>(SELECTED_WORKSPACE_LOCAL_STORAGE_KEY) || null;
  //   new Promise<State<Workspace, WorkspaceFetchError>>(async (resolve) => {
  //     try {
  //       const workspace: Workspace = await this.resourcesService.getWorkspace(workspaceId, { idToken: LocalStorageRepository.getAccessToken() });
  //       const workspaceState: State<Workspace, null> = {
  //         status: AsyncStatus.SUCCESS,
  //         fetchedAt: Date.now(),
  //         data: workspace,
  //         error: null,
  //       };
  //       LocalStorageRepository.setLocalStorageStateObject<State<Workspace, null>>(SELECTED_WORKSPACE_LOCAL_STORAGE_KEY, workspaceState);
  //       resolve(workspaceState);
  //     } catch (error: any) {
  //       resolve({
  //         status: AsyncStatus.ERROR,
  //         fetchedAt: Date.now(),
  //         data: null,
  //         error: error,
  //       });
  //     }
  //   }).then((state: State<Workspace, WorkspaceFetchError>) => {
  //     EventBus.emit(Events.WORKSPACE_FETCH, state);
  //     return;
  //   }).catch((error: any) => {
  //     const errorResponse = {
  //       status: AsyncStatus.ERROR,
  //       fetchedAt: Date.now(),
  //       data: null,
  //       error,
  //     };
  //     EventBus.emit(Events.WORKSPACE_FETCH, errorResponse);
  //   });
  //   return {
  //     status: AsyncStatus.CACHED,
  //     fetchedAt: localSelectedWorkspaceState?.fetchedAt || 0,
  //     data: localSelectedWorkspaceState?.data || null,
  //     error: null,
  //   };
  // }

  getWorkspaceSettings(): State<WorkspaceSettings, WorkspaceSettingsFetchError> {
    const [token, workspaceId] = this.fetchTokenAndWorkspaceId();

    this.resourcesService
      .getWorkspaceSettings(workspaceId, token)
      .then((settings) => {
        const settingsState: State<WorkspaceSettings, null> = {
          status: AsyncStatus.SUCCESS,
          fetchedAt: Date.now(),
          data: settings,
          error: null,
        };
        EventBus.emit(Events.WORKSPACE_SETTINGS_FETCH, settingsState);
      })
      .catch((error) => {
        const errorResponse: State<null, WorkspaceSettingsFetchError> = {
          status: AsyncStatus.ERROR,
          fetchedAt: Date.now(),
          data: null,
          error,
        };
        EventBus.emit(Events.WORKSPACE_SETTINGS_FETCH, errorResponse);
      });

    return {
      status: AsyncStatus.CACHED,
      fetchedAt: 0,
      data: null,
      error: null,
    };
  }

  getEntitySettings(): State<EntitySettings, EntitySettingsFetchError> {
    const [token, workspaceId] = this.fetchTokenAndWorkspaceId();
    const entityId = LocalStorageRepository.getEntityId();
    if (!entityId) throw new Error('No entity found');

    this.resourcesService
      .getEntitySettings(workspaceId, entityId, token)
      .then((settings) => {
        const workspaceStopListHashmap = settings.entitySettings.workspaceStopList.reduce(
          (acc, stopListItem) => {
            acc[stopListItem.id] = stopListItem.isEnabled;
            return acc;
          },
          {} as Record<string, boolean>
        );

        const workspaceCategoriesHashmap = settings.entitySettings.workspaceCategories.reduce(
          (acc, category) => {
            acc[category.id] = category.isEnabled;
            return acc;
          },
          {} as Record<string, boolean>
        );

        const workspaceStopList: InheritedStopListItem[] = settings.workspaceStopList.map(
          (stopListItem) => ({
            ...stopListItem,
            isEnabled: workspaceStopListHashmap[stopListItem.id],
          })
        );
        const workspaceCategories: InheritedCategory[] = settings.workspaceCategories.map(
          (category) => ({
            ...category,
            isEnabled: workspaceCategoriesHashmap[category.id],
          })
        );

        const settingsState: State<EntitySettings, null> = {
          status: AsyncStatus.SUCCESS,
          fetchedAt: Date.now(),
          data: {
            ...settings.entitySettings,
            stopList: settings.entitySettings.stopList,
            categories: settings.entitySettings.categories,
            workspaceCategories,
            workspaceStopList,
          },
          error: null,
        };
        EventBus.emit(Events.ENTITY_SETTINGS_FETCH, settingsState);
      })
      .catch((error) => {
        const errorResponse: State<null, EntitySettingsFetchError> = {
          status: AsyncStatus.ERROR,
          fetchedAt: Date.now(),
          data: null,
          error,
        };
        EventBus.emit(Events.ENTITY_SETTINGS_FETCH, errorResponse);
      });

    return {
      status: AsyncStatus.CACHED,
      fetchedAt: 0,
      data: null,
      error: null,
    };
  }

  async addWorkspaceStopListItem(value: string): Promise<void> {
    const [token, workspaceId] = this.fetchTokenAndWorkspaceId();
    try {
      const id = await this.resourcesService.addWorkspaceStopListItem(workspaceId, value, token);
      // const entities = getLocalStorageStateObject<Entity[]>(SELECTED_ENTITY_LOCAL_STORAGE_KEY) || [];
      const newStopListItem: StopListItem = {
        id,
        value,
      };
      EventBus.emit(
        Events.WORKSPACE_STOP_LIST_ITEM_CREATE,
        fetchedState<StopListItem>(newStopListItem)
      );
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.WORKSPACE_STOP_LIST_ITEM_CREATE, errorResponse);
    }
  }

  async deleteWorkspaceStopListItem(stopListItemId: string): Promise<void> {
    const [token, workspaceId] = this.fetchTokenAndWorkspaceId();
    try {
      await this.resourcesService.deleteWorkspaceStopListItem(workspaceId, stopListItemId, token);

      EventBus.emit(Events.WORKSPACE_STOP_LIST_ITEM_DELETE, fetchedState<string>(stopListItemId));
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.WORKSPACE_STOP_LIST_ITEM_DELETE, errorResponse);
    }
  }

  async editWorkspaceStopListItem(stopListItemId: string, newValue: string): Promise<void> {
    const [token, workspaceId] = this.fetchTokenAndWorkspaceId();
    try {
      await this.resourcesService.editWorkspaceStopListItem(
        workspaceId,
        stopListItemId,
        newValue,
        token
      );
      const newStopListItem: StopListItem = {
        id: stopListItemId,
        value: newValue,
      };

      EventBus.emit(
        Events.WORKSPACE_STOP_LIST_ITEM_UPDATE,
        fetchedState<StopListItem>(newStopListItem)
      );
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.WORKSPACE_STOP_LIST_ITEM_UPDATE, errorResponse);
    }
  }

  private fetchTokenAndWorkspaceId(): [string, string] {
    const token = LocalStorageRepository.getAccessToken();
    if (!token) throw new Error('No token found');
    const workspaceId = LocalStorageRepository.getWorkspaceId();
    if (!workspaceId) throw new Error('No workspace found');
    return [token, workspaceId];
  }

  private fetchTokenWorkspaceIdAndEntityId(): [string, string, string] {
    const entityId = LocalStorageRepository.getEntityId();
    if (!entityId) throw new Error('No entity found');
    const [token, workspaceId] = this.fetchTokenAndWorkspaceId();
    return [token, workspaceId, entityId];
  }

  async addWorkspaceCategory(name: string): Promise<void> {
    const [token, workspaceId] = this.fetchTokenAndWorkspaceId();
    try {
      const id = await this.resourcesService.addWorkspaceCategory(workspaceId, name, token);
      const newCategory: Category = {
        id,
        name,
        items: [],
      };
      EventBus.emit(Events.WORKSPACE_CATEGORY_CREATE, fetchedState<Category>(newCategory));
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.WORKSPACE_CATEGORY_CREATE, errorResponse);
    }
  }

  async deleteWorkspaceCategory(categoryId: string): Promise<void> {
    const [token, workspaceId] = this.fetchTokenAndWorkspaceId();
    try {
      await this.resourcesService.deleteWorkspaceCategory(workspaceId, categoryId, token);
      EventBus.emit(Events.WORKSPACE_CATEGORY_DELETE, fetchedState<string>(categoryId));
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.WORKSPACE_CATEGORY_DELETE, errorResponse);
    }
  }

  async editWorkspaceCategoryName(categoryId: string, newName: string): Promise<void> {
    try {
      const [token, workspaceId] = this.fetchTokenAndWorkspaceId();
      await this.resourcesService.editWorkspaceCategoryName(
        workspaceId,
        categoryId,
        newName,
        token
      );
      const newCategory: Category = {
        id: categoryId,
        name: newName,
        items: [],
      };
      EventBus.emit(Events.WORKSPACE_CATEGORY_UPDATE, fetchedState<Category>(newCategory));
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.WORKSPACE_CATEGORY_UPDATE, errorResponse);
    }
  }

  async addWorkspaceCategoryItem(categoryId: string, value: string): Promise<void> {
    const [token, workspaceId] = this.fetchTokenAndWorkspaceId();
    try {
      const id = await this.resourcesService.addWorkspaceCategoryItem(
        workspaceId,
        categoryId,
        value,
        token
      );
      const newCategoryItem: CategoryItemPayload = {
        id,
        value,
        categoryId,
      };
      EventBus.emit(
        Events.WORKSPACE_CATEGORY_ITEM_CREATE,
        fetchedState<CategoryItemPayload>(newCategoryItem)
      );
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.WORKSPACE_CATEGORY_ITEM_CREATE, errorResponse);
    }
  }

  async deleteWorkspaceCategoryItem(categoryId: string, itemId: string): Promise<void> {
    const [token, workspaceId] = this.fetchTokenAndWorkspaceId();
    try {
      await this.resourcesService.deleteWorkspaceCategoryItem(
        workspaceId,
        categoryId,
        itemId,
        token
      );
      const payload: CategoryItemDeletedPayload = {
        itemId,
        categoryId,
      };
      EventBus.emit(
        Events.WORKSPACE_CATEGORY_ITEM_DELETE,
        fetchedState<CategoryItemDeletedPayload>(payload)
      );
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.WORKSPACE_CATEGORY_ITEM_DELETE, errorResponse);
    }
  }

  async editWorkspaceCategoryItem(
    categoryId: string,
    itemId: string,
    newValue: string
  ): Promise<void> {
    const [token, workspaceId] = this.fetchTokenAndWorkspaceId();
    try {
      await this.resourcesService.editWorkspaceCategoryItem(
        workspaceId,
        categoryId,
        itemId,
        newValue,
        token
      );
      const newCategoryItem: CategoryItemPayload = {
        id: itemId,
        value: newValue,
        categoryId,
      };
      EventBus.emit(
        Events.WORKSPACE_CATEGORY_ITEM_UPDATE,
        fetchedState<CategoryItemPayload>(newCategoryItem)
      );
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.WORKSPACE_CATEGORY_ITEM_UPDATE, errorResponse);
    }
  }

  async enableWorkspaceStopListItemOfEntity(stopListItemId: string): Promise<void> {
    const [token, workspaceId, entityId] = this.fetchTokenWorkspaceIdAndEntityId();
    try {
      await this.resourcesService.enableWorkspaceStopListItemOfEntity(
        workspaceId,
        entityId,
        stopListItemId,
        token
      );
      EventBus.emit(
        Events.ENTITY_WORKSPACE_STOP_LIST_ITEM_ENABLE,
        fetchedState<StopListItemId>(stopListItemId)
      );
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_WORKSPACE_STOP_LIST_ITEM_ENABLE, errorResponse);
    }
  }

  async disableWorkspaceStopListItemOfEntity(stopListItemId: string): Promise<void> {
    const [token, workspaceId, entityId] = this.fetchTokenWorkspaceIdAndEntityId();
    try {
      await this.resourcesService.disableWorkspaceStopListItemOfEntity(
        workspaceId,
        entityId,
        stopListItemId,
        token
      );
      EventBus.emit(
        Events.ENTITY_WORKSPACE_STOP_LIST_ITEM_DISABLE,
        fetchedState<StopListItemId>(stopListItemId)
      );
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_WORKSPACE_STOP_LIST_ITEM_DISABLE, errorResponse);
    }
  }

  async enableWorkspaceCategoryOfEntity(categoryId: string): Promise<void> {
    const [token, workspaceId, entityId] = this.fetchTokenWorkspaceIdAndEntityId();
    try {
      await this.resourcesService.enableWorkspaceCategoryOfEntity(
        workspaceId,
        entityId,
        categoryId,
        token
      );
      EventBus.emit(Events.ENTITY_WORKSPACE_CATEGORY_ENABLE, fetchedState<CategoryId>(categoryId));
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_WORKSPACE_CATEGORY_ENABLE, errorResponse);
    }
  }

  async disableWorkspaceCategoryOfEntity(categoryId: string): Promise<void> {
    const [token, workspaceId, entityId] = this.fetchTokenWorkspaceIdAndEntityId();
    try {
      await this.resourcesService.disableWorkspaceCategoryOfEntity(
        workspaceId,
        entityId,
        categoryId,
        token
      );
      EventBus.emit(Events.ENTITY_WORKSPACE_CATEGORY_DISABLE, fetchedState<CategoryId>(categoryId));
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_WORKSPACE_CATEGORY_DISABLE, errorResponse);
    }
  }

  async addEntityStopListItem(value: string): Promise<void> {
    const [token, workspaceId, entityId] = this.fetchTokenWorkspaceIdAndEntityId();
    try {
      const id = await this.resourcesService.addEntityStopListItem(
        workspaceId,
        entityId,
        value,
        token
      );
      const data: StopListItem = {
        id,
        value,
      };
      EventBus.emit(Events.ENTITY_STOP_LIST_ITEM_CREATE, fetchedState<StopListItem>(data));
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_STOP_LIST_ITEM_CREATE, errorResponse);
    }
  }

  async deleteEntityStopListItem(stopListItemId: string): Promise<void> {
    const [token, workspaceId, entityId] = this.fetchTokenWorkspaceIdAndEntityId();
    try {
      await this.resourcesService.deleteEntityStopListItem(
        workspaceId,
        entityId,
        stopListItemId,
        token
      );
      EventBus.emit(
        Events.ENTITY_STOP_LIST_ITEM_DELETE,
        fetchedState<StopListItemId>(stopListItemId)
      );
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_STOP_LIST_ITEM_DELETE, errorResponse);
    }
  }

  async editEntityStopListItem(stopListItemId: string, newValue: string): Promise<void> {
    const [token, workspaceId, entityId] = this.fetchTokenWorkspaceIdAndEntityId();
    try {
      await this.resourcesService.editEntityStopListItem(
        workspaceId,
        entityId,
        stopListItemId,
        newValue,
        token
      );
      const data: StopListItem = {
        id: stopListItemId,
        value: newValue,
      };
      EventBus.emit(Events.ENTITY_STOP_LIST_ITEM_UPDATE, fetchedState<StopListItem>(data));
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_STOP_LIST_ITEM_UPDATE, errorResponse);
    }
  }

  async addEntityCategory(name: string): Promise<void> {
    const [token, workspaceId, entityId] = this.fetchTokenWorkspaceIdAndEntityId();
    try {
      const id = await this.resourcesService.addEntityCategory(workspaceId, entityId, name, token);
      const data: Category = {
        id,
        name,
        items: [],
      };
      EventBus.emit(Events.ENTITY_CATEGORY_CREATE, fetchedState<Category>(data));
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_CATEGORY_CREATE, errorResponse);
    }
  }

  async deleteEntityCategory(categoryId: string): Promise<void> {
    const [token, workspaceId, entityId] = this.fetchTokenWorkspaceIdAndEntityId();
    try {
      await this.resourcesService.deleteEntityCategory(workspaceId, entityId, categoryId, token);

      EventBus.emit(Events.ENTITY_CATEGORY_DELETE, fetchedState<CategoryId>(categoryId));
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_CATEGORY_DELETE, errorResponse);
    }
  }

  async editEntityCategoryName(categoryId: string, newName: string): Promise<void> {
    const [token, workspaceId, entityId] = this.fetchTokenWorkspaceIdAndEntityId();
    try {
      await this.resourcesService.editEntityCategoryName(
        workspaceId,
        entityId,
        categoryId,
        newName,
        token
      );
      type CategoryUpdatePayload = Omit<Category, 'items'>;
      const data: CategoryUpdatePayload = {
        id: categoryId,
        name: newName,
      };
      EventBus.emit(Events.ENTITY_CATEGORY_UPDATE, fetchedState<CategoryUpdatePayload>(data));
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_CATEGORY_UPDATE, errorResponse);
    }
  }

  async addEntityCategoryItem(categoryId: string, value: string): Promise<void> {
    const [token, workspaceId, entityId] = this.fetchTokenWorkspaceIdAndEntityId();
    try {
      const id = await this.resourcesService.addEntityCategoryItem(
        workspaceId,
        entityId,
        categoryId,
        value,
        token
      );
      const data: CategoryItemPayload = {
        id,
        categoryId,
        value,
      };
      EventBus.emit(Events.ENTITY_CATEGORY_ITEM_CREATE, fetchedState<CategoryItemPayload>(data));
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_CATEGORY_ITEM_CREATE, errorResponse);
    }
  }

  async deleteEntityCategoryItem(categoryId: string, itemId: string): Promise<void> {
    const [token, workspaceId, entityId] = this.fetchTokenWorkspaceIdAndEntityId();
    try {
      await this.resourcesService.deleteEntityCategoryItem(
        workspaceId,
        entityId,
        categoryId,
        itemId,
        token
      );
      const data: CategoryItemDeletedPayload = {
        categoryId,
        itemId,
      };
      EventBus.emit(
        Events.ENTITY_CATEGORY_ITEM_DELETE,
        fetchedState<CategoryItemDeletedPayload>(data)
      );
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_CATEGORY_ITEM_DELETE, errorResponse);
    }
  }

  async editEntityCategoryItem(
    categoryId: string,
    itemId: string,
    newValue: string
  ): Promise<void> {
    const [token, workspaceId, entityId] = this.fetchTokenWorkspaceIdAndEntityId();
    try {
      await this.resourcesService.editEntityCategoryItem(
        workspaceId,
        entityId,
        categoryId,
        itemId,
        newValue,
        token
      );
      const data: CategoryItemPayload = {
        id: itemId,
        categoryId,
        value: newValue,
      };
      EventBus.emit(Events.ENTITY_CATEGORY_ITEM_UPDATE, fetchedState<CategoryItemPayload>(data));
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceSettingsFetchError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_CATEGORY_ITEM_UPDATE, errorResponse);
    }
  }
}

export { ResourcesRepository };
