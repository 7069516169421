import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import ManualReconciliationPage from './page';
import { useEntityViewModel } from '../../view-models/EntityViewModel';
import { useReconciliationReportViewModel } from '../../view-models/ReconciliationReportViewModel';
import { useReconciliationAccountViewModel } from '../../view-models/ReconciliationAccountViewModel';

const ManualReconciliationController: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const reconciliationReportId = params.id!;
  const entityViewModel = useEntityViewModel();
  const reconciliationReportViewModel = useReconciliationReportViewModel()!;
  const reconciliationAccountViewModel = useReconciliationAccountViewModel();

  useEffect(() => {
    reconciliationReportViewModel.setReconciliationReportId(reconciliationReportId);
    reconciliationReportViewModel.clearAllFilters();
  }, []);

  const navigateToViewlReconciliationReport = () => {
    navigate(`/reconciliation-report/${reconciliationReportId}`);
  };

  return (
    <ManualReconciliationPage
      unreconciledBankTransactionsPagination={reconciliationReportViewModel.unreconciledBankTransactionsPagination}
      unreconciledLedgerTransactionsPagination={reconciliationReportViewModel.unreconciledLedgerTransactionsPagination}
      bankUnreconciledTransactionCounts={reconciliationReportViewModel.bankUnreconciledTransactionCounts}
      ledgerUnreconciledTransactionCounts={reconciliationReportViewModel.ledgerUnreconciledTransactionCounts}
      currentEntity={entityViewModel.entity.data}
      currentReconciliationAccount={reconciliationAccountViewModel.reconciliationAccount.data}
      currentReconciliationReportName={reconciliationReportViewModel.reconciliationReportName}
      bankUnreconciledTransactions={
        reconciliationReportViewModel.unreconciledUnselectedBankTransactionsFiltered
      }
      ledgerUnreconciledTransactions={
        reconciliationReportViewModel.unreconciledUnselectedLedgerTransactionsFiltered
      }
      selectedTransactions={reconciliationReportViewModel.selectedTransactions}
      matchTransactionsStatus={reconciliationReportViewModel.manualReconcilableStatus}
      amountDifference={reconciliationReportViewModel.manualReconciliationDifference}
      amountDifferenceLabel={reconciliationReportViewModel.manualReconciliationDifferenceLabel}
      reconcile={reconciliationReportViewModel.manuallyReconcile}
      status="success"
      selectOrUnselectBankTransaction={
        reconciliationReportViewModel.selectOrUnselectBankTransaction
      }
      selectOrUnselectLedgerTransaction={
        reconciliationReportViewModel.selectOrUnselectLedgerTransaction
      }
      navigateToViewlReconciliationReport={navigateToViewlReconciliationReport}
      cancelManualReconciliation={reconciliationReportViewModel.unselectSelectedTransactions}
      showNewLedgerTransactionDialog={reconciliationReportViewModel.showNewLedgerTransactionDialog}
      showEditLedgerTransactionDialog={
        reconciliationReportViewModel.showEditLedgerTransactionDialog
      }
      toggleBankDateDropdown={reconciliationReportViewModel.toggleBankDateDropdown}
      isBankDateAscending={reconciliationReportViewModel.isBankDateAscending}
      toggleBankCreditDropdown={reconciliationReportViewModel.toggleBankCreditDropdown}
      isBankCreditAscending={reconciliationReportViewModel.isBankCreditAscending}
      toggleBankDebitDropdown={reconciliationReportViewModel.toggleBankDebitDropdown}
      isBankDebitAscending={reconciliationReportViewModel.isBankDebitAscending}
      toggleLedgerDateDropdown={reconciliationReportViewModel.toggleLedgerDateDropdown}
      isLedgerDateAscending={reconciliationReportViewModel.isLedgerDateAscending}
      toggleLedgerCreditDropdown={reconciliationReportViewModel.toggleLedgerCreditDropdown}
      isLedgerCreditAscending={reconciliationReportViewModel.isLedgerCreditAscending}
      toggleLedgerDebitDropdown={reconciliationReportViewModel.toggleLedgerDebitDropdown}
      isLedgerDebitAscending={reconciliationReportViewModel.isLedgerDebitAscending}
      externalDocumentType={reconciliationAccountViewModel.externalDocumentType}
      ledgerDocumentType={reconciliationAccountViewModel.ledgerDocumentType}
      generateCorrespondingTransactions={
        reconciliationReportViewModel.generateCorrespondingTransactions
      }
      changeBankQuickFilter={reconciliationReportViewModel.changeBankQuickFilter}
      changeLedgerQuickFilter={reconciliationReportViewModel.changeLedgerQuickFilter}
      showBankFilterPopUpDialog={reconciliationReportViewModel.showBankFilterPopUpDialog}
      showLedgerFilterPopUpDialog={reconciliationReportViewModel.showLedgerFilterPopUpDialog}
      bankQuickFilter={reconciliationReportViewModel.bankQuickFilter}
      ledgerQuickFilter={reconciliationReportViewModel.ledgerQuickFilter}
      selectFilteredBankUnreconciledManualTransactions={
        reconciliationReportViewModel.selectFilteredBankUnreconciledManualTransactions
      }
      selectFilteredLedgerUnreconciledManualTransactions={
        reconciliationReportViewModel.selectFilteredLedgerUnreconciledManualTransactions
      }
      removeSelectedBankUnreconciledManualTransactions={
        reconciliationReportViewModel.removeSelectedBankUnreconciledManualTransactions
      }
      removeSelectedLedgerUnreconciledManualTransactions={
        reconciliationReportViewModel.removeSelectedLedgerUnreconciledManualTransactions
      }
    />
  );
};

const MRC = observer(ManualReconciliationController);

export { MRC as ManualReconciliationControllerPage };
