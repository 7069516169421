import CircularProgress from '@mui/material/CircularProgress';
import {
    Dialog,
    DialogTitle,
    DialogContent,
} from '@mui/material';
import { LoaderContainer, DialogContentContainer } from './Styles';

interface FileProgressDialogProps {
    open: boolean;
}
function FileProgressDialog(props: FileProgressDialogProps) {
    const { open } = props;

    return (
        <Dialog
            open={open}
        >
            <DialogTitle>
                Hang tight!
            </DialogTitle>

            <DialogContent>

                <DialogContentContainer>
                    <p>We’re uploading and prepping your file for reconciliation.</p>
                    <LoaderContainer>
                        <CircularProgress size={35} />
                    </LoaderContainer>
                </DialogContentContainer>
            </DialogContent>
        </Dialog>
    );
}

export default FileProgressDialog;
