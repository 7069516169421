import FilledButton from '../../../Buttons/FilledButton';
import { AcceptAllBlock } from './Styles';

interface AcceptAllButtonProps {
  onClick: () => void;
  disabled: boolean;
}

function AcceptAllButton(props: AcceptAllButtonProps): JSX.Element {
  const { onClick, disabled } = props;
  return (
    <AcceptAllBlock>
      <FilledButton content="Accept all" onClick={onClick} disabled={disabled} />
    </AcceptAllBlock>
  );
}
export default AcceptAllButton;
