export interface SlimObject {
  id: string;
  name: string;
}

// export interface WorkspaceSlim extends SlimObject {};
export interface EntitySlim extends SlimObject { }
export interface ReconciliationAccountSlim extends SlimObject {
  reconciliationType: string;
}

export type UsersList = {
  userId: string;
  roleName?: string;
  roleId: string;
};

export type Workspace = {
  id: string;
  name: string;
  country: string;
  language: string;
  createdAt: number;
  updatedAt: number;
  entities: EntitySlim[];
  usersList?: UsersList[];
};

export type CreateWorkspaceParams = {
  name: string;
  country: string;
  language: string;
};

export type UpdateWorkspaceParams = {
  workspaceId: string;
  name?: string;
  country?: string;
  language?: string;
};

export type UpdateEntityParams = {
  name?: string;
  country?: string;
  language?: string;
};

export type CurrentEntityStatus = 'idle' | 'pending' | 'success' | 'cached' | 'error';

export type Entity = {
  id: string;
  name: string;
  country: string;
  language: string;
  uniqueIdentifier: string;
  owner: string;
  usersList: UsersList[];
  createdAt: number;
  updatedAt: number;
  reconciliationAccountsList: ReconciliationAccountSlim[];
};

export interface EntityParams {
  name: string;
  country: string;
  language: string;
  uniqueIdentifier: string;
}

export interface CreateEntityParams extends EntityParams {
  owner: string;
  workspaceId: string;
};

export type ReconciliationAccount = {
  id: string;
  name: string;
  reconciliationType: string;
  uniqueIdentifier: string;
  counterpartyContactName?: string;
  counterpartyContactEmail?: string;
  owner: string;
  usersList: UsersList[];
  createdAt: number;
  updatedAt: number;
};

export type CreateReconciliationAccountParams = {
  name: string;
  reconciliationType: string;
  uniqueIdentifier: string;
  counterpartyContactName?: string;
  counterpartyContactEmail?: string;
  owner: string;
  entityId: string;
  workspaceId: string;
};

export type ReconciliationReport = {
  id: string;
  name: string;
  description: string;
};

export type EditWorkspaceParams = {
  name: string;
  country: string;
  language: string;
};

export enum RECONCILIATION_ACCOUNT_TYPES {
  BANK = 'BANK',
  SUPPLIER = 'SUPPLIER',
  CUSTOMER = 'CUSTOMER',
  SINGLE_ACCOUNT = 'SINGLE_ACCOUNT',
  TRANSACTION_MATCHING = 'TRANSACTION_MATCHING',
}
export type EditEntityParams = {
  name: string;
  country: string;
  language: string;
};

export type EditReconciliationAccountParams = {
  name: string;
  counterpartyContactName: string;
  counterpartyContactEmail: string;
};

export type StopListItem = {
  id: string;
  value: string;
};

export type CategoryItem = {
  id: string;
  value: string;
};

export type Category = {
  id: string;
  name: string;
  items: CategoryItem[];
};

export type InheritedStopListItem = {
  id: Readonly<string>;
  value: Readonly<string>;
  isEnabled: boolean;
};

export type InheritedCategory = {
  id: Readonly<string>;
  isEnabled: boolean;
  name: Readonly<string>;
  items: readonly CategoryItem[];
};

export type EntitySettings = {
  stopList: StopListItem[];
  categories: Category[];
  workspaceStopList: InheritedStopListItem[];
  workspaceCategories: InheritedCategory[];
};

export type WorkspaceSettings = {
  stopList: StopListItem[];
  categories: Category[];
};

