import { useState } from 'react';
import AcceptButton from '../../Buttons/AcceptButton';
import RejectButton from '../../Buttons/RejectButton';
import { SuggestedMatch, Buttons } from './Styles';
import Match from '../../Match/Match';
import { SuggestedMatchProps } from '../../../types';

function SuggestedMatchBlock(props: SuggestedMatchProps): JSX.Element {
  const {
    id,
    reconciliationReportId,
    bankTransactions,
    ledgerTransactions,
    timeout,
    acceptSuggestedMatch,
    rejectSuggestedMatch,
  } = props;

  const [deletedActionId, setDeletedActionId] = useState('');

  const handleClick = (matchId: string) => {
    setDeletedActionId(matchId);

    setTimeout(() => {
      setDeletedActionId('');
    }, 2000);
  };

  const handleAcceptMatch = (reportId: string, matchId: string) => {
    handleClick(matchId);
    acceptSuggestedMatch(reportId, matchId);
  }
  
  const rejectAcceptedMatch = (reportId: string, matchId: string) => {
    handleClick(matchId);
    rejectSuggestedMatch(reportId, matchId);
  }
  return (
    <SuggestedMatch
    // sx={{
    //   width: {
    //     xs: 'auto',
    //     lg: 'var(--page-max-width)',
    //   },
    // }}
    >
      <Match bankTransactions={bankTransactions} ledgerTransactions={ledgerTransactions} />
      <Buttons>
        <AcceptButton
          disabled={deletedActionId === id}
          onClick={handleAcceptMatch}
          suggestedMatchId={id}
          reconciliationReportId={reconciliationReportId}
        />
        <RejectButton
          disabled={deletedActionId === id}
          onClick={rejectAcceptedMatch}
          suggestedMatchId={id}
          reconciliationReportId={reconciliationReportId}
        />
      </Buttons>
    </SuggestedMatch>
  );
}

export default SuggestedMatchBlock;
