import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';
import 'react-datepicker/dist/react-datepicker.css';
import { ConvertersAndTemplatesPageProps } from '../../types';
import ConvertersAndTemplates from '../../components/ConvertersAndTemplates';
import PageDetails from '../../components/PageDetails/Details';
import SimpleButton from '../../components/Buttons/SimpleButton';

// eslint-disable-next-line import/prefer-default-export
export function ConvertersAndTemplatesPage(props: ConvertersAndTemplatesPageProps): JSX.Element {
  const {
    pdfUpladFileStatus,
    customUploadFileStatus,
    bankUploadFileStatus,
    ledgerUploadFileStatus,
    customOptions,
    selectedCustom,
    erpOptions,
    bankTransactionTypes,
    handleBankConvert,
    errorMessage,
    status,
    selectedBank,
    selectedERP,
    setSelectedBank,
    uploadedBankFile,
    uploadedCustomFile,
    externalDocumentType,
    ledgerDocumentType,
    handlePdfFileUpload,
    handleBankFileUpload,
    downloadBankTemplate,
    downloadLedgerTemplate,
    showCreateReconciliationAccountDialog,
    convertPdfDocument,
    convertLedgerDocument,
    handleLedgerFileUpload,
    setSelectedERP,
    handleCustomFileUpload,
    handleCustomConvert,
    setSelectedCustom,
    setLedgerUploadFileStatus,
    setBankUploadFileStatus,
    setCustomUploadFileStatus,
    setPdfUploadFileStatus,
  } = props;

  return (
    <>
      <PageDetails
        header="Converters & Templates"
        action={
          status === 'success' ? (
            <SimpleButton
              content="Create Reconciliation Account"
              onClick={showCreateReconciliationAccountDialog}
            />
          ) : undefined
        }
      />
      {status === 'loading' && <Loader />}
      {status === 'error' &&
        (errorMessage ? (
          <Error message={errorMessage} />
        ) : (
          <Error message="Something went wrong." />
        ))}
      <ConvertersAndTemplates
        pdfUpladFileStatus={pdfUpladFileStatus}
        bankUploadFileStatus={bankUploadFileStatus}
        customUploadFileStatus={customUploadFileStatus}
        ledgerUploadFileStatus={ledgerUploadFileStatus}
        selectedCustom={selectedCustom}
        customOptions={customOptions}
        bankTransactionTypes={bankTransactionTypes}
        convertBankDocument={handleBankConvert}
        selectedBank={selectedBank}
        selectedERP={selectedERP}
        erpOptions={erpOptions}
        setSelectedBank={setSelectedBank}
        uploadedBankFile={uploadedBankFile}
        uploadedCustomFile={uploadedCustomFile}
        externalDocumentType={externalDocumentType}
        ledgerDocumentType={ledgerDocumentType}
        handleBankFileUpload={handleBankFileUpload}
        handlePdfFileUpload={handlePdfFileUpload}
        downloadBankTemplate={downloadBankTemplate}
        downloadLedgerTemplate={downloadLedgerTemplate}
        convertPdfDocument={convertPdfDocument}
        convertLedgerDocument={convertLedgerDocument}
        handleLedgerFileUpload={handleLedgerFileUpload}
        setSelectedERP={setSelectedERP}
        handleCustomFileUpload={handleCustomFileUpload}
        convertCustomDocument={handleCustomConvert}
        setSelectedCustom={setSelectedCustom}
        setLedgerUploadFileStatus={setLedgerUploadFileStatus}
        setCustomUploadFileStatus={setCustomUploadFileStatus}
        setBankUploadFileStatus={setBankUploadFileStatus}
        setPdfUploadFileStatus={setPdfUploadFileStatus}
      />
    </>
  );
}
