import { getAbsoluteDifference, isPositiveOrZero, valueIncreases } from './number';
import { ReconciliationAccount } from '../models/Resources';
import {
  AdjustmentsReport,
  ReconciliationAccountReport,
  TransactionType,
} from '../models/ReconciliationReport';

export type FlattenTransaction = {
  amount: number;
  amountType: string;
  balance: number;
  date: string;
  description: string;
  id: string;
  rowId: string;
  status: string;
  financialTransactionId: string;
  reference: string;
};

export default class ReconciliationReportDataHandler {
  private static getCreatedTransactionAmount = (
    transaction: FlattenTransaction
  ): { debit: string | null; credit: string | null } => {
    const { amount } = transaction;
    return {
      debit: this.isDebitFlattenTransaction(transaction) ? this.fixAmount(amount) : null,
      credit: this.isCreditFlattenTransaction(transaction) ? this.fixAmount(amount) : null,
    };
  };

  private static getReconciliationAccountReportFromAdjustmentsReport = (
    report: AdjustmentsReport,
    reconciliationAccountReportData: ReconciliationAccountReport[]
  ): ReconciliationAccountReport | null => {
    if (!reconciliationAccountReportData) return null;
    for (let r = 0; r < reconciliationAccountReportData.length; r += 1) {
      const accountReport = reconciliationAccountReportData[r];
      if (accountReport.id === report.reconciliationReportId) {
        return accountReport;
      }
    }
    return null;
  };

  private static flattenTransaction(transaction: TransactionType): FlattenTransaction {
    const { amount } = transaction;
    return {
      ...transaction,
      amount: amount.amount,
      amountType: amount.type,
    };
  }

  static createAdjustmentsReportData = (
    report: AdjustmentsReport,
    reconciliationAccount: ReconciliationAccount | null,
    accountReport: ReconciliationAccountReport
  ) => {
    const { createdTransactions, deletedTransactions, updatedTransactions } = report;

    const COUNTER_ACCOUNT_NAME = 'COUNTER ACCOUNT';
    const createdTransactionsData = createdTransactions.map((t) => this.flattenTransaction(t));
    const deletedTransactionsData = deletedTransactions.map((t) => this.flattenTransaction(t));

    const updatedTransactionsData = updatedTransactions.map((txs) => ({
      original: this.flattenTransaction(txs.originalTransaction),
      updated: this.flattenTransaction(txs.updatedTransaction),
    }));
    const accountName = reconciliationAccount ? reconciliationAccount.name : '';
    const accountCode = reconciliationAccount ? reconciliationAccount.uniqueIdentifier : '';
    const data = [
      [`Reconciliation Report for ${accountName}`],
      [
        `For period: ${accountReport.reconciliationPeriod.startingDate} - ${accountReport.reconciliationPeriod.endingDate}`,
      ],
      ['Manual Adjustments & Journal Entries'],
      [],
      ['Date', 'Description', 'Reference', 'Transaction ID', 'Account Code', 'Debit', 'Credit'],
      ...createdTransactionsData.flatMap((tx) => [
        [
          tx.date,
          tx.description,
          tx.reference,
          tx.financialTransactionId,
          accountCode,
          this.getCreatedTransactionAmount(tx).debit,
          this.getCreatedTransactionAmount(tx).credit,
        ],
        [
          tx.date,
          tx.description,
          tx.reference,
          tx.financialTransactionId,
          COUNTER_ACCOUNT_NAME,
          this.getCreatedTransactionAmount(tx).credit,
          this.getCreatedTransactionAmount(tx).debit,
        ],
        [],
      ]),
      ...updatedTransactionsData.flatMap((tx) => [
        [
          tx.updated.date,
          this.getEditedDescriptionName(tx.updated.description),
          tx.updated.reference,
          tx.updated.financialTransactionId,
          accountCode,
          this.getEditedTransactionAmount(tx.original, tx.updated).debit,
          this.getEditedTransactionAmount(tx.original, tx.updated).credit,
        ],
        [
          tx.updated.date,
          this.getEditedDescriptionName(tx.updated.description),
          tx.updated.reference,
          tx.updated.financialTransactionId,
          COUNTER_ACCOUNT_NAME,
          this.getEditedTransactionAmount(tx.original, tx.updated).credit,
          this.getEditedTransactionAmount(tx.original, tx.updated).debit,
        ],
        [],
      ]),
      ...deletedTransactionsData.flatMap((tx) => [
        [
          tx.date,
          this.getEditedDescriptionName(tx.description),
          tx.reference,
          tx.financialTransactionId,
          accountCode,
          this.getDeletedTransactionAmount(tx).debit,
          this.getDeletedTransactionAmount(tx).credit,
        ],
        [
          tx.date,
          this.getEditedDescriptionName(tx.description),
          tx.reference,
          tx.financialTransactionId,
          COUNTER_ACCOUNT_NAME,
          this.getDeletedTransactionAmount(tx).credit,
          this.getDeletedTransactionAmount(tx).debit,
        ],
        [],
      ]),
    ];
    return data;
  };

  private static getEditedDescriptionName = (description: string): string =>
    `Rounding ADJ: ${description}`;

  private static getDeletedTransactionAmount = (
    transaction: FlattenTransaction
  ): { debit: string | null; credit: string | null } => {
    const updatedTransaction = { ...transaction, amount: 0 };
    return this.getEditedTransactionAmount(transaction, updatedTransaction);
  };

  private static getDebitCaseEditTransactionAmount = (
    originalTransaction: FlattenTransaction,
    updatedTransaction: FlattenTransaction
  ): { debit: number | null; credit: number | null } => {
    const absoluteDifference = getAbsoluteDifference(
      originalTransaction.amount,
      updatedTransaction.amount
    );
    if (isPositiveOrZero(originalTransaction.amount)) {
      if (valueIncreases(originalTransaction.amount, updatedTransaction.amount)) {
        return {
          debit: absoluteDifference,
          credit: null,
        };
      }
      return {
        debit: null,
        credit: absoluteDifference,
      };
    }
    if (valueIncreases(originalTransaction.amount, updatedTransaction.amount)) {
      return {
        debit: absoluteDifference,
        credit: null,
      };
    }
    return {
      debit: -absoluteDifference,
      credit: null,
    };
  };

  private static getEditedTransactionAmount = (
    originalTransaction: FlattenTransaction,
    updatedTransaction: FlattenTransaction
  ): { debit: string | null; credit: string | null } => {
    if (this.isDebitFlattenTransaction(originalTransaction)) {
      const { debit, credit } = this.getDebitCaseEditTransactionAmount(
        originalTransaction,
        updatedTransaction
      );
      return {
        debit: this.fixAmount(debit),
        credit: this.fixAmount(credit),
      };
    }
    const { debit, credit } = this.getCreditCaseEditTransactionAmount(
      originalTransaction,
      updatedTransaction
    );
    return {
      debit: this.fixAmount(debit),
      credit: this.fixAmount(credit),
    };
  };

  private static fixAmount = (amount: number | null): string | null =>
    amount !== null ? amount.toFixed(2) : amount;

  private static isDebitFlattenTransaction = (transaction: FlattenTransaction): boolean =>
    transaction.amountType === 'debit';

  private static isCreditFlattenTransaction = (transaction: FlattenTransaction): boolean =>
    transaction.amountType === 'credit';

  private static getCreditCaseEditTransactionAmount = (
    originalTransaction: FlattenTransaction,
    updatedTransaction: FlattenTransaction
  ): { debit: number | null; credit: number | null } => {
    const absoluteDifference = getAbsoluteDifference(
      originalTransaction.amount,
      updatedTransaction.amount
    );
    if (isPositiveOrZero(originalTransaction.amount)) {
      if (valueIncreases(originalTransaction.amount, updatedTransaction.amount)) {
        return {
          debit: null,
          credit: absoluteDifference,
        };
      }
      return {
        debit: absoluteDifference,
        credit: null,
      };
    }
    if (valueIncreases(originalTransaction.amount, updatedTransaction.amount)) {
      return {
        debit: null,
        credit: absoluteDifference,
      };
    }
    return {
      debit: null,
      credit: -absoluteDifference,
    };
  };
}
