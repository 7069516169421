import Tooltip from '@mui/material/Tooltip';
import { TransactionProps } from '../../../types';
import { BankTransaction, BankDebit, BankCredit } from './Styles';
import Description from '../DescriptionColumn';
import { Date, Reference } from '../Styles';

function BankTransactionBlock(props: TransactionProps): JSX.Element {
  const {
    date,
    description,
    reference,
    debit,
    credit,
    financialTransactionId
  } = props;

  const tooltipTitle = `TransactionID: ${financialTransactionId}`;

  return (
    <Tooltip title={tooltipTitle} placement='top'>
      <BankTransaction>
        <Date>{date}</Date>
        <Description content={description} />
        <Reference>{reference}</Reference>
        <BankDebit>{debit !== undefined && debit !== '' && debit !== '0.00' && debit}</BankDebit>
        <BankCredit>
          {credit !== undefined && credit !== '' && credit !== '0.00' && credit}
        </BankCredit>
      </BankTransaction>

    </Tooltip>

  );
}

export default BankTransactionBlock;
