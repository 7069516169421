/**
 * @fileoverview gRPC-Web generated client stub for reconcilio
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.3
// source: reconcilio.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as reconcilio_pb from './reconcilio_pb'; // proto import: "reconcilio.proto"


export class ReconcilioServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorUploadDocument = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/UploadDocument',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.UploadDocumentRequest,
    reconcilio_pb.UploadDocumentResponse,
    (request: reconcilio_pb.UploadDocumentRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.UploadDocumentResponse.deserializeBinary
  );

  uploadDocument(
    request: reconcilio_pb.UploadDocumentRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.UploadDocumentResponse>;

  uploadDocument(
    request: reconcilio_pb.UploadDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.UploadDocumentResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.UploadDocumentResponse>;

  uploadDocument(
    request: reconcilio_pb.UploadDocumentRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.UploadDocumentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/UploadDocument',
        request,
        metadata || {},
        this.methodDescriptorUploadDocument,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/UploadDocument',
    request,
    metadata || {},
    this.methodDescriptorUploadDocument);
  }

  methodDescriptorValidateDocument = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/ValidateDocument',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.ValidateDocumentRequest,
    reconcilio_pb.ValidateDocumentResponse,
    (request: reconcilio_pb.ValidateDocumentRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.ValidateDocumentResponse.deserializeBinary
  );

  validateDocument(
    request: reconcilio_pb.ValidateDocumentRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.ValidateDocumentResponse>;

  validateDocument(
    request: reconcilio_pb.ValidateDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.ValidateDocumentResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.ValidateDocumentResponse>;

  validateDocument(
    request: reconcilio_pb.ValidateDocumentRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.ValidateDocumentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/ValidateDocument',
        request,
        metadata || {},
        this.methodDescriptorValidateDocument,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/ValidateDocument',
    request,
    metadata || {},
    this.methodDescriptorValidateDocument);
  }

  methodDescriptorAcceptSuggestion = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/AcceptSuggestion',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.AcceptSuggestionRequest,
    reconcilio_pb.AcceptSuggestionResponse,
    (request: reconcilio_pb.AcceptSuggestionRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.AcceptSuggestionResponse.deserializeBinary
  );

  acceptSuggestion(
    request: reconcilio_pb.AcceptSuggestionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.AcceptSuggestionResponse>;

  acceptSuggestion(
    request: reconcilio_pb.AcceptSuggestionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AcceptSuggestionResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.AcceptSuggestionResponse>;

  acceptSuggestion(
    request: reconcilio_pb.AcceptSuggestionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AcceptSuggestionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/AcceptSuggestion',
        request,
        metadata || {},
        this.methodDescriptorAcceptSuggestion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/AcceptSuggestion',
    request,
    metadata || {},
    this.methodDescriptorAcceptSuggestion);
  }

  methodDescriptorAcceptSuggestions = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/AcceptSuggestions',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.AcceptSuggestionsRequest,
    reconcilio_pb.AcceptSuggestionsResponse,
    (request: reconcilio_pb.AcceptSuggestionsRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.AcceptSuggestionsResponse.deserializeBinary
  );

  acceptSuggestions(
    request: reconcilio_pb.AcceptSuggestionsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.AcceptSuggestionsResponse>;

  acceptSuggestions(
    request: reconcilio_pb.AcceptSuggestionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AcceptSuggestionsResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.AcceptSuggestionsResponse>;

  acceptSuggestions(
    request: reconcilio_pb.AcceptSuggestionsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AcceptSuggestionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/AcceptSuggestions',
        request,
        metadata || {},
        this.methodDescriptorAcceptSuggestions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/AcceptSuggestions',
    request,
    metadata || {},
    this.methodDescriptorAcceptSuggestions);
  }

  methodDescriptorRejectSuggestion = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/RejectSuggestion',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.RejectSuggestionRequest,
    reconcilio_pb.RejectSuggestionResponse,
    (request: reconcilio_pb.RejectSuggestionRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.RejectSuggestionResponse.deserializeBinary
  );

  rejectSuggestion(
    request: reconcilio_pb.RejectSuggestionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.RejectSuggestionResponse>;

  rejectSuggestion(
    request: reconcilio_pb.RejectSuggestionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.RejectSuggestionResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.RejectSuggestionResponse>;

  rejectSuggestion(
    request: reconcilio_pb.RejectSuggestionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.RejectSuggestionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/RejectSuggestion',
        request,
        metadata || {},
        this.methodDescriptorRejectSuggestion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/RejectSuggestion',
    request,
    metadata || {},
    this.methodDescriptorRejectSuggestion);
  }

  methodDescriptorRejectSuggestions = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/RejectSuggestions',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.RejectSuggestionsRequest,
    reconcilio_pb.RejectSuggestionsResponse,
    (request: reconcilio_pb.RejectSuggestionsRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.RejectSuggestionsResponse.deserializeBinary
  );

  rejectSuggestions(
    request: reconcilio_pb.RejectSuggestionsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.RejectSuggestionsResponse>;

  rejectSuggestions(
    request: reconcilio_pb.RejectSuggestionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.RejectSuggestionsResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.RejectSuggestionsResponse>;

  rejectSuggestions(
    request: reconcilio_pb.RejectSuggestionsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.RejectSuggestionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/RejectSuggestions',
        request,
        metadata || {},
        this.methodDescriptorRejectSuggestions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/RejectSuggestions',
    request,
    metadata || {},
    this.methodDescriptorRejectSuggestions);
  }

  methodDescriptorCreateReconciliationReport = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/CreateReconciliationReport',
    grpcWeb.MethodType.SERVER_STREAMING,
    reconcilio_pb.CreateReconciliationReportRequest,
    reconcilio_pb.OnCreateReconciliationReportEvent,
    (request: reconcilio_pb.CreateReconciliationReportRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.OnCreateReconciliationReportEvent.deserializeBinary
  );

  createReconciliationReport(
    request: reconcilio_pb.CreateReconciliationReportRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<reconcilio_pb.OnCreateReconciliationReportEvent> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/reconcilio.ReconcilioService/CreateReconciliationReport',
      request,
      metadata || {},
      this.methodDescriptorCreateReconciliationReport);
  }

  methodDescriptorGetSuggestions = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/GetSuggestions',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.GetSuggestionsRequest,
    reconcilio_pb.GetSuggestionsResponse,
    (request: reconcilio_pb.GetSuggestionsRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.GetSuggestionsResponse.deserializeBinary
  );

  getSuggestions(
    request: reconcilio_pb.GetSuggestionsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.GetSuggestionsResponse>;

  getSuggestions(
    request: reconcilio_pb.GetSuggestionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetSuggestionsResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.GetSuggestionsResponse>;

  getSuggestions(
    request: reconcilio_pb.GetSuggestionsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetSuggestionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/GetSuggestions',
        request,
        metadata || {},
        this.methodDescriptorGetSuggestions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/GetSuggestions',
    request,
    metadata || {},
    this.methodDescriptorGetSuggestions);
  }

  methodDescriptorGetReconciliation = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/GetReconciliation',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.GetReconciliationReportRequest,
    reconcilio_pb.GetReconciliationReportResponse,
    (request: reconcilio_pb.GetReconciliationReportRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.GetReconciliationReportResponse.deserializeBinary
  );

  getReconciliation(
    request: reconcilio_pb.GetReconciliationReportRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.GetReconciliationReportResponse>;

  getReconciliation(
    request: reconcilio_pb.GetReconciliationReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetReconciliationReportResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.GetReconciliationReportResponse>;

  getReconciliation(
    request: reconcilio_pb.GetReconciliationReportRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetReconciliationReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/GetReconciliation',
        request,
        metadata || {},
        this.methodDescriptorGetReconciliation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/GetReconciliation',
    request,
    metadata || {},
    this.methodDescriptorGetReconciliation);
  }

  methodDescriptorGetReconciliationAccountReports = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/GetReconciliationAccountReports',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.GetReconciliationAccountReportsRequest,
    reconcilio_pb.GetReconciliationAccountReportsResponse,
    (request: reconcilio_pb.GetReconciliationAccountReportsRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.GetReconciliationAccountReportsResponse.deserializeBinary
  );

  getReconciliationAccountReports(
    request: reconcilio_pb.GetReconciliationAccountReportsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.GetReconciliationAccountReportsResponse>;

  getReconciliationAccountReports(
    request: reconcilio_pb.GetReconciliationAccountReportsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetReconciliationAccountReportsResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.GetReconciliationAccountReportsResponse>;

  getReconciliationAccountReports(
    request: reconcilio_pb.GetReconciliationAccountReportsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetReconciliationAccountReportsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/GetReconciliationAccountReports',
        request,
        metadata || {},
        this.methodDescriptorGetReconciliationAccountReports,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/GetReconciliationAccountReports',
    request,
    metadata || {},
    this.methodDescriptorGetReconciliationAccountReports);
  }

  methodDescriptorManualReconciliation = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/ManualReconciliation',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.ManualReconciliationRequest,
    reconcilio_pb.ManualReconciliationResponse,
    (request: reconcilio_pb.ManualReconciliationRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.ManualReconciliationResponse.deserializeBinary
  );

  manualReconciliation(
    request: reconcilio_pb.ManualReconciliationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.ManualReconciliationResponse>;

  manualReconciliation(
    request: reconcilio_pb.ManualReconciliationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.ManualReconciliationResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.ManualReconciliationResponse>;

  manualReconciliation(
    request: reconcilio_pb.ManualReconciliationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.ManualReconciliationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/ManualReconciliation',
        request,
        metadata || {},
        this.methodDescriptorManualReconciliation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/ManualReconciliation',
    request,
    metadata || {},
    this.methodDescriptorManualReconciliation);
  }

  methodDescriptorLockReconciliationReport = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/LockReconciliationReport',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.LockReconciliationReportRequest,
    reconcilio_pb.LockReconciliationReportResponse,
    (request: reconcilio_pb.LockReconciliationReportRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.LockReconciliationReportResponse.deserializeBinary
  );

  lockReconciliationReport(
    request: reconcilio_pb.LockReconciliationReportRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.LockReconciliationReportResponse>;

  lockReconciliationReport(
    request: reconcilio_pb.LockReconciliationReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.LockReconciliationReportResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.LockReconciliationReportResponse>;

  lockReconciliationReport(
    request: reconcilio_pb.LockReconciliationReportRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.LockReconciliationReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/LockReconciliationReport',
        request,
        metadata || {},
        this.methodDescriptorLockReconciliationReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/LockReconciliationReport',
    request,
    metadata || {},
    this.methodDescriptorLockReconciliationReport);
  }

  methodDescriptorCreateWorkspace = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/CreateWorkspace',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.CreateWorkspaceRequest,
    reconcilio_pb.CreateWorkspaceResponse,
    (request: reconcilio_pb.CreateWorkspaceRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.CreateWorkspaceResponse.deserializeBinary
  );

  createWorkspace(
    request: reconcilio_pb.CreateWorkspaceRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.CreateWorkspaceResponse>;

  createWorkspace(
    request: reconcilio_pb.CreateWorkspaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.CreateWorkspaceResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.CreateWorkspaceResponse>;

  createWorkspace(
    request: reconcilio_pb.CreateWorkspaceRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.CreateWorkspaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/CreateWorkspace',
        request,
        metadata || {},
        this.methodDescriptorCreateWorkspace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/CreateWorkspace',
    request,
    metadata || {},
    this.methodDescriptorCreateWorkspace);
  }

  methodDescriptorCreateEntity = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/CreateEntity',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.CreateEntityRequest,
    reconcilio_pb.CreateEntityResponse,
    (request: reconcilio_pb.CreateEntityRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.CreateEntityResponse.deserializeBinary
  );

  createEntity(
    request: reconcilio_pb.CreateEntityRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.CreateEntityResponse>;

  createEntity(
    request: reconcilio_pb.CreateEntityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.CreateEntityResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.CreateEntityResponse>;

  createEntity(
    request: reconcilio_pb.CreateEntityRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.CreateEntityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/CreateEntity',
        request,
        metadata || {},
        this.methodDescriptorCreateEntity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/CreateEntity',
    request,
    metadata || {},
    this.methodDescriptorCreateEntity);
  }

  methodDescriptorCreateReconciliationAccount = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/CreateReconciliationAccount',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.CreateReconciliationAccountRequest,
    reconcilio_pb.CreateReconciliationAccountResponse,
    (request: reconcilio_pb.CreateReconciliationAccountRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.CreateReconciliationAccountResponse.deserializeBinary
  );

  createReconciliationAccount(
    request: reconcilio_pb.CreateReconciliationAccountRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.CreateReconciliationAccountResponse>;

  createReconciliationAccount(
    request: reconcilio_pb.CreateReconciliationAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.CreateReconciliationAccountResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.CreateReconciliationAccountResponse>;

  createReconciliationAccount(
    request: reconcilio_pb.CreateReconciliationAccountRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.CreateReconciliationAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/CreateReconciliationAccount',
        request,
        metadata || {},
        this.methodDescriptorCreateReconciliationAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/CreateReconciliationAccount',
    request,
    metadata || {},
    this.methodDescriptorCreateReconciliationAccount);
  }

  methodDescriptorGetWorkspaces = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/GetWorkspaces',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.GetWorkspacesRequest,
    reconcilio_pb.GetWorkspacesResponse,
    (request: reconcilio_pb.GetWorkspacesRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.GetWorkspacesResponse.deserializeBinary
  );

  getWorkspaces(
    request: reconcilio_pb.GetWorkspacesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.GetWorkspacesResponse>;

  getWorkspaces(
    request: reconcilio_pb.GetWorkspacesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetWorkspacesResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.GetWorkspacesResponse>;

  getWorkspaces(
    request: reconcilio_pb.GetWorkspacesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetWorkspacesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/GetWorkspaces',
        request,
        metadata || {},
        this.methodDescriptorGetWorkspaces,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/GetWorkspaces',
    request,
    metadata || {},
    this.methodDescriptorGetWorkspaces);
  }

  methodDescriptorGetWorkspaceById = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/GetWorkspaceById',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.GetWorkspaceByIdRequest,
    reconcilio_pb.GetWorkspaceByIdResponse,
    (request: reconcilio_pb.GetWorkspaceByIdRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.GetWorkspaceByIdResponse.deserializeBinary
  );

  getWorkspaceById(
    request: reconcilio_pb.GetWorkspaceByIdRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.GetWorkspaceByIdResponse>;

  getWorkspaceById(
    request: reconcilio_pb.GetWorkspaceByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetWorkspaceByIdResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.GetWorkspaceByIdResponse>;

  getWorkspaceById(
    request: reconcilio_pb.GetWorkspaceByIdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetWorkspaceByIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/GetWorkspaceById',
        request,
        metadata || {},
        this.methodDescriptorGetWorkspaceById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/GetWorkspaceById',
    request,
    metadata || {},
    this.methodDescriptorGetWorkspaceById);
  }

  methodDescriptorGetEntityById = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/GetEntityById',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.GetEntityByIdRequest,
    reconcilio_pb.GetEntityByIdResponse,
    (request: reconcilio_pb.GetEntityByIdRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.GetEntityByIdResponse.deserializeBinary
  );

  getEntityById(
    request: reconcilio_pb.GetEntityByIdRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.GetEntityByIdResponse>;

  getEntityById(
    request: reconcilio_pb.GetEntityByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetEntityByIdResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.GetEntityByIdResponse>;

  getEntityById(
    request: reconcilio_pb.GetEntityByIdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetEntityByIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/GetEntityById',
        request,
        metadata || {},
        this.methodDescriptorGetEntityById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/GetEntityById',
    request,
    metadata || {},
    this.methodDescriptorGetEntityById);
  }

  methodDescriptorGetReconciliationAccountById = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/GetReconciliationAccountById',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.GetReconciliationAccountByIdRequest,
    reconcilio_pb.GetReconciliationAccountByIdResponse,
    (request: reconcilio_pb.GetReconciliationAccountByIdRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.GetReconciliationAccountByIdResponse.deserializeBinary
  );

  getReconciliationAccountById(
    request: reconcilio_pb.GetReconciliationAccountByIdRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.GetReconciliationAccountByIdResponse>;

  getReconciliationAccountById(
    request: reconcilio_pb.GetReconciliationAccountByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetReconciliationAccountByIdResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.GetReconciliationAccountByIdResponse>;

  getReconciliationAccountById(
    request: reconcilio_pb.GetReconciliationAccountByIdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetReconciliationAccountByIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/GetReconciliationAccountById',
        request,
        metadata || {},
        this.methodDescriptorGetReconciliationAccountById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/GetReconciliationAccountById',
    request,
    metadata || {},
    this.methodDescriptorGetReconciliationAccountById);
  }

  methodDescriptorEditWorkspaceProfile = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/EditWorkspaceProfile',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.EditWorkspaceProfileRequest,
    reconcilio_pb.EditWorkspaceProfileResponse,
    (request: reconcilio_pb.EditWorkspaceProfileRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.EditWorkspaceProfileResponse.deserializeBinary
  );

  editWorkspaceProfile(
    request: reconcilio_pb.EditWorkspaceProfileRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.EditWorkspaceProfileResponse>;

  editWorkspaceProfile(
    request: reconcilio_pb.EditWorkspaceProfileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditWorkspaceProfileResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.EditWorkspaceProfileResponse>;

  editWorkspaceProfile(
    request: reconcilio_pb.EditWorkspaceProfileRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditWorkspaceProfileResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/EditWorkspaceProfile',
        request,
        metadata || {},
        this.methodDescriptorEditWorkspaceProfile,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/EditWorkspaceProfile',
    request,
    metadata || {},
    this.methodDescriptorEditWorkspaceProfile);
  }

  methodDescriptorEditEntityProfile = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/EditEntityProfile',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.EditEntityProfileRequest,
    reconcilio_pb.EditEntityProfileResponse,
    (request: reconcilio_pb.EditEntityProfileRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.EditEntityProfileResponse.deserializeBinary
  );

  editEntityProfile(
    request: reconcilio_pb.EditEntityProfileRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.EditEntityProfileResponse>;

  editEntityProfile(
    request: reconcilio_pb.EditEntityProfileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditEntityProfileResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.EditEntityProfileResponse>;

  editEntityProfile(
    request: reconcilio_pb.EditEntityProfileRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditEntityProfileResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/EditEntityProfile',
        request,
        metadata || {},
        this.methodDescriptorEditEntityProfile,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/EditEntityProfile',
    request,
    metadata || {},
    this.methodDescriptorEditEntityProfile);
  }

  methodDescriptorEditReconciliationAccountProfile = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/EditReconciliationAccountProfile',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.EditReconciliationAccountProfileRequest,
    reconcilio_pb.EditReconciliationAccountProfileResponse,
    (request: reconcilio_pb.EditReconciliationAccountProfileRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.EditReconciliationAccountProfileResponse.deserializeBinary
  );

  editReconciliationAccountProfile(
    request: reconcilio_pb.EditReconciliationAccountProfileRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.EditReconciliationAccountProfileResponse>;

  editReconciliationAccountProfile(
    request: reconcilio_pb.EditReconciliationAccountProfileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditReconciliationAccountProfileResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.EditReconciliationAccountProfileResponse>;

  editReconciliationAccountProfile(
    request: reconcilio_pb.EditReconciliationAccountProfileRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditReconciliationAccountProfileResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/EditReconciliationAccountProfile',
        request,
        metadata || {},
        this.methodDescriptorEditReconciliationAccountProfile,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/EditReconciliationAccountProfile',
    request,
    metadata || {},
    this.methodDescriptorEditReconciliationAccountProfile);
  }

  methodDescriptorDeleteWorkspace = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/DeleteWorkspace',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.DeleteWorkspaceRequest,
    reconcilio_pb.DeleteWorkspaceResponse,
    (request: reconcilio_pb.DeleteWorkspaceRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.DeleteWorkspaceResponse.deserializeBinary
  );

  deleteWorkspace(
    request: reconcilio_pb.DeleteWorkspaceRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.DeleteWorkspaceResponse>;

  deleteWorkspace(
    request: reconcilio_pb.DeleteWorkspaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteWorkspaceResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.DeleteWorkspaceResponse>;

  deleteWorkspace(
    request: reconcilio_pb.DeleteWorkspaceRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteWorkspaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/DeleteWorkspace',
        request,
        metadata || {},
        this.methodDescriptorDeleteWorkspace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/DeleteWorkspace',
    request,
    metadata || {},
    this.methodDescriptorDeleteWorkspace);
  }

  methodDescriptorDeleteEntity = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/DeleteEntity',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.DeleteEntityRequest,
    reconcilio_pb.DeleteEntityResponse,
    (request: reconcilio_pb.DeleteEntityRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.DeleteEntityResponse.deserializeBinary
  );

  deleteEntity(
    request: reconcilio_pb.DeleteEntityRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.DeleteEntityResponse>;

  deleteEntity(
    request: reconcilio_pb.DeleteEntityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteEntityResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.DeleteEntityResponse>;

  deleteEntity(
    request: reconcilio_pb.DeleteEntityRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteEntityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/DeleteEntity',
        request,
        metadata || {},
        this.methodDescriptorDeleteEntity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/DeleteEntity',
    request,
    metadata || {},
    this.methodDescriptorDeleteEntity);
  }

  methodDescriptorDeleteReconciliationAccount = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/DeleteReconciliationAccount',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.DeleteReconciliationAccountRequest,
    reconcilio_pb.DeleteReconciliationAccountResponse,
    (request: reconcilio_pb.DeleteReconciliationAccountRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.DeleteReconciliationAccountResponse.deserializeBinary
  );

  deleteReconciliationAccount(
    request: reconcilio_pb.DeleteReconciliationAccountRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.DeleteReconciliationAccountResponse>;

  deleteReconciliationAccount(
    request: reconcilio_pb.DeleteReconciliationAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteReconciliationAccountResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.DeleteReconciliationAccountResponse>;

  deleteReconciliationAccount(
    request: reconcilio_pb.DeleteReconciliationAccountRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteReconciliationAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/DeleteReconciliationAccount',
        request,
        metadata || {},
        this.methodDescriptorDeleteReconciliationAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/DeleteReconciliationAccount',
    request,
    metadata || {},
    this.methodDescriptorDeleteReconciliationAccount);
  }

  methodDescriptorAddWorkspaceStopListItem = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/AddWorkspaceStopListItem',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.AddWorkspaceStopListItemRequest,
    reconcilio_pb.AddWorkspaceStopListItemResponse,
    (request: reconcilio_pb.AddWorkspaceStopListItemRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.AddWorkspaceStopListItemResponse.deserializeBinary
  );

  addWorkspaceStopListItem(
    request: reconcilio_pb.AddWorkspaceStopListItemRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.AddWorkspaceStopListItemResponse>;

  addWorkspaceStopListItem(
    request: reconcilio_pb.AddWorkspaceStopListItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AddWorkspaceStopListItemResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.AddWorkspaceStopListItemResponse>;

  addWorkspaceStopListItem(
    request: reconcilio_pb.AddWorkspaceStopListItemRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AddWorkspaceStopListItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/AddWorkspaceStopListItem',
        request,
        metadata || {},
        this.methodDescriptorAddWorkspaceStopListItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/AddWorkspaceStopListItem',
    request,
    metadata || {},
    this.methodDescriptorAddWorkspaceStopListItem);
  }

  methodDescriptorDeleteWorkspaceStopListItem = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/DeleteWorkspaceStopListItem',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.DeleteWorkspaceStopListItemRequest,
    reconcilio_pb.DeleteWorkspaceStopListItemResponse,
    (request: reconcilio_pb.DeleteWorkspaceStopListItemRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.DeleteWorkspaceStopListItemResponse.deserializeBinary
  );

  deleteWorkspaceStopListItem(
    request: reconcilio_pb.DeleteWorkspaceStopListItemRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.DeleteWorkspaceStopListItemResponse>;

  deleteWorkspaceStopListItem(
    request: reconcilio_pb.DeleteWorkspaceStopListItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteWorkspaceStopListItemResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.DeleteWorkspaceStopListItemResponse>;

  deleteWorkspaceStopListItem(
    request: reconcilio_pb.DeleteWorkspaceStopListItemRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteWorkspaceStopListItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/DeleteWorkspaceStopListItem',
        request,
        metadata || {},
        this.methodDescriptorDeleteWorkspaceStopListItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/DeleteWorkspaceStopListItem',
    request,
    metadata || {},
    this.methodDescriptorDeleteWorkspaceStopListItem);
  }

  methodDescriptorEditWorkspaceStopListItem = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/EditWorkspaceStopListItem',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.EditWorkspaceStopListItemRequest,
    reconcilio_pb.EditWorkspaceStopListItemResponse,
    (request: reconcilio_pb.EditWorkspaceStopListItemRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.EditWorkspaceStopListItemResponse.deserializeBinary
  );

  editWorkspaceStopListItem(
    request: reconcilio_pb.EditWorkspaceStopListItemRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.EditWorkspaceStopListItemResponse>;

  editWorkspaceStopListItem(
    request: reconcilio_pb.EditWorkspaceStopListItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditWorkspaceStopListItemResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.EditWorkspaceStopListItemResponse>;

  editWorkspaceStopListItem(
    request: reconcilio_pb.EditWorkspaceStopListItemRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditWorkspaceStopListItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/EditWorkspaceStopListItem',
        request,
        metadata || {},
        this.methodDescriptorEditWorkspaceStopListItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/EditWorkspaceStopListItem',
    request,
    metadata || {},
    this.methodDescriptorEditWorkspaceStopListItem);
  }

  methodDescriptorAddWorkspaceCategory = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/AddWorkspaceCategory',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.AddWorkspaceCategoryRequest,
    reconcilio_pb.AddWorkspaceCategoryResponse,
    (request: reconcilio_pb.AddWorkspaceCategoryRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.AddWorkspaceCategoryResponse.deserializeBinary
  );

  addWorkspaceCategory(
    request: reconcilio_pb.AddWorkspaceCategoryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.AddWorkspaceCategoryResponse>;

  addWorkspaceCategory(
    request: reconcilio_pb.AddWorkspaceCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AddWorkspaceCategoryResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.AddWorkspaceCategoryResponse>;

  addWorkspaceCategory(
    request: reconcilio_pb.AddWorkspaceCategoryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AddWorkspaceCategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/AddWorkspaceCategory',
        request,
        metadata || {},
        this.methodDescriptorAddWorkspaceCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/AddWorkspaceCategory',
    request,
    metadata || {},
    this.methodDescriptorAddWorkspaceCategory);
  }

  methodDescriptorDeleteWorkspaceCategory = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/DeleteWorkspaceCategory',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.DeleteWorkspaceCategoryRequest,
    reconcilio_pb.DeleteWorkspaceCategoryResponse,
    (request: reconcilio_pb.DeleteWorkspaceCategoryRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.DeleteWorkspaceCategoryResponse.deserializeBinary
  );

  deleteWorkspaceCategory(
    request: reconcilio_pb.DeleteWorkspaceCategoryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.DeleteWorkspaceCategoryResponse>;

  deleteWorkspaceCategory(
    request: reconcilio_pb.DeleteWorkspaceCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteWorkspaceCategoryResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.DeleteWorkspaceCategoryResponse>;

  deleteWorkspaceCategory(
    request: reconcilio_pb.DeleteWorkspaceCategoryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteWorkspaceCategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/DeleteWorkspaceCategory',
        request,
        metadata || {},
        this.methodDescriptorDeleteWorkspaceCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/DeleteWorkspaceCategory',
    request,
    metadata || {},
    this.methodDescriptorDeleteWorkspaceCategory);
  }

  methodDescriptorAddWorkspaceCategoryItem = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/AddWorkspaceCategoryItem',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.AddWorkspaceCategoryItemRequest,
    reconcilio_pb.AddWorkspaceCategoryItemResponse,
    (request: reconcilio_pb.AddWorkspaceCategoryItemRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.AddWorkspaceCategoryItemResponse.deserializeBinary
  );

  addWorkspaceCategoryItem(
    request: reconcilio_pb.AddWorkspaceCategoryItemRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.AddWorkspaceCategoryItemResponse>;

  addWorkspaceCategoryItem(
    request: reconcilio_pb.AddWorkspaceCategoryItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AddWorkspaceCategoryItemResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.AddWorkspaceCategoryItemResponse>;

  addWorkspaceCategoryItem(
    request: reconcilio_pb.AddWorkspaceCategoryItemRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AddWorkspaceCategoryItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/AddWorkspaceCategoryItem',
        request,
        metadata || {},
        this.methodDescriptorAddWorkspaceCategoryItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/AddWorkspaceCategoryItem',
    request,
    metadata || {},
    this.methodDescriptorAddWorkspaceCategoryItem);
  }

  methodDescriptorDeleteWorkspaceCategoryItem = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/DeleteWorkspaceCategoryItem',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.DeleteWorkspaceCategoryItemRequest,
    reconcilio_pb.DeleteWorkspaceCategoryItemResponse,
    (request: reconcilio_pb.DeleteWorkspaceCategoryItemRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.DeleteWorkspaceCategoryItemResponse.deserializeBinary
  );

  deleteWorkspaceCategoryItem(
    request: reconcilio_pb.DeleteWorkspaceCategoryItemRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.DeleteWorkspaceCategoryItemResponse>;

  deleteWorkspaceCategoryItem(
    request: reconcilio_pb.DeleteWorkspaceCategoryItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteWorkspaceCategoryItemResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.DeleteWorkspaceCategoryItemResponse>;

  deleteWorkspaceCategoryItem(
    request: reconcilio_pb.DeleteWorkspaceCategoryItemRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteWorkspaceCategoryItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/DeleteWorkspaceCategoryItem',
        request,
        metadata || {},
        this.methodDescriptorDeleteWorkspaceCategoryItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/DeleteWorkspaceCategoryItem',
    request,
    metadata || {},
    this.methodDescriptorDeleteWorkspaceCategoryItem);
  }

  methodDescriptorEditWorkspaceCategoryName = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/EditWorkspaceCategoryName',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.EditWorkspaceCategoryNameRequest,
    reconcilio_pb.EditWorkspaceCategoryNameResponse,
    (request: reconcilio_pb.EditWorkspaceCategoryNameRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.EditWorkspaceCategoryNameResponse.deserializeBinary
  );

  editWorkspaceCategoryName(
    request: reconcilio_pb.EditWorkspaceCategoryNameRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.EditWorkspaceCategoryNameResponse>;

  editWorkspaceCategoryName(
    request: reconcilio_pb.EditWorkspaceCategoryNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditWorkspaceCategoryNameResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.EditWorkspaceCategoryNameResponse>;

  editWorkspaceCategoryName(
    request: reconcilio_pb.EditWorkspaceCategoryNameRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditWorkspaceCategoryNameResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/EditWorkspaceCategoryName',
        request,
        metadata || {},
        this.methodDescriptorEditWorkspaceCategoryName,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/EditWorkspaceCategoryName',
    request,
    metadata || {},
    this.methodDescriptorEditWorkspaceCategoryName);
  }

  methodDescriptorEditWorkspaceCategoryItem = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/EditWorkspaceCategoryItem',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.EditWorkspaceCategoryItemRequest,
    reconcilio_pb.EditWorkspaceCategoryItemResponse,
    (request: reconcilio_pb.EditWorkspaceCategoryItemRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.EditWorkspaceCategoryItemResponse.deserializeBinary
  );

  editWorkspaceCategoryItem(
    request: reconcilio_pb.EditWorkspaceCategoryItemRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.EditWorkspaceCategoryItemResponse>;

  editWorkspaceCategoryItem(
    request: reconcilio_pb.EditWorkspaceCategoryItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditWorkspaceCategoryItemResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.EditWorkspaceCategoryItemResponse>;

  editWorkspaceCategoryItem(
    request: reconcilio_pb.EditWorkspaceCategoryItemRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditWorkspaceCategoryItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/EditWorkspaceCategoryItem',
        request,
        metadata || {},
        this.methodDescriptorEditWorkspaceCategoryItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/EditWorkspaceCategoryItem',
    request,
    metadata || {},
    this.methodDescriptorEditWorkspaceCategoryItem);
  }

  methodDescriptorEnableWorkspaceStopListItemOfEntity = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/EnableWorkspaceStopListItemOfEntity',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.EnableWorkspaceStopListItemOfEntityRequest,
    reconcilio_pb.EnableWorkspaceStopListItemOfEntityResponse,
    (request: reconcilio_pb.EnableWorkspaceStopListItemOfEntityRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.EnableWorkspaceStopListItemOfEntityResponse.deserializeBinary
  );

  enableWorkspaceStopListItemOfEntity(
    request: reconcilio_pb.EnableWorkspaceStopListItemOfEntityRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.EnableWorkspaceStopListItemOfEntityResponse>;

  enableWorkspaceStopListItemOfEntity(
    request: reconcilio_pb.EnableWorkspaceStopListItemOfEntityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EnableWorkspaceStopListItemOfEntityResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.EnableWorkspaceStopListItemOfEntityResponse>;

  enableWorkspaceStopListItemOfEntity(
    request: reconcilio_pb.EnableWorkspaceStopListItemOfEntityRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EnableWorkspaceStopListItemOfEntityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/EnableWorkspaceStopListItemOfEntity',
        request,
        metadata || {},
        this.methodDescriptorEnableWorkspaceStopListItemOfEntity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/EnableWorkspaceStopListItemOfEntity',
    request,
    metadata || {},
    this.methodDescriptorEnableWorkspaceStopListItemOfEntity);
  }

  methodDescriptorDisableWorkspaceStopListItemOfEntity = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/DisableWorkspaceStopListItemOfEntity',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.DisableWorkspaceStopListItemOfEntityRequest,
    reconcilio_pb.DisableWorkspaceStopListItemOfEntityResponse,
    (request: reconcilio_pb.DisableWorkspaceStopListItemOfEntityRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.DisableWorkspaceStopListItemOfEntityResponse.deserializeBinary
  );

  disableWorkspaceStopListItemOfEntity(
    request: reconcilio_pb.DisableWorkspaceStopListItemOfEntityRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.DisableWorkspaceStopListItemOfEntityResponse>;

  disableWorkspaceStopListItemOfEntity(
    request: reconcilio_pb.DisableWorkspaceStopListItemOfEntityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DisableWorkspaceStopListItemOfEntityResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.DisableWorkspaceStopListItemOfEntityResponse>;

  disableWorkspaceStopListItemOfEntity(
    request: reconcilio_pb.DisableWorkspaceStopListItemOfEntityRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DisableWorkspaceStopListItemOfEntityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/DisableWorkspaceStopListItemOfEntity',
        request,
        metadata || {},
        this.methodDescriptorDisableWorkspaceStopListItemOfEntity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/DisableWorkspaceStopListItemOfEntity',
    request,
    metadata || {},
    this.methodDescriptorDisableWorkspaceStopListItemOfEntity);
  }

  methodDescriptorEnableWorkspaceCategoryOfEntity = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/EnableWorkspaceCategoryOfEntity',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.EnableWorkspaceCategoryOfEntityRequest,
    reconcilio_pb.EnableWorkspaceCategoryOfEntityResponse,
    (request: reconcilio_pb.EnableWorkspaceCategoryOfEntityRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.EnableWorkspaceCategoryOfEntityResponse.deserializeBinary
  );

  enableWorkspaceCategoryOfEntity(
    request: reconcilio_pb.EnableWorkspaceCategoryOfEntityRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.EnableWorkspaceCategoryOfEntityResponse>;

  enableWorkspaceCategoryOfEntity(
    request: reconcilio_pb.EnableWorkspaceCategoryOfEntityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EnableWorkspaceCategoryOfEntityResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.EnableWorkspaceCategoryOfEntityResponse>;

  enableWorkspaceCategoryOfEntity(
    request: reconcilio_pb.EnableWorkspaceCategoryOfEntityRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EnableWorkspaceCategoryOfEntityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/EnableWorkspaceCategoryOfEntity',
        request,
        metadata || {},
        this.methodDescriptorEnableWorkspaceCategoryOfEntity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/EnableWorkspaceCategoryOfEntity',
    request,
    metadata || {},
    this.methodDescriptorEnableWorkspaceCategoryOfEntity);
  }

  methodDescriptorDisableWorkspaceCategoryOfEntity = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/DisableWorkspaceCategoryOfEntity',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.DisableWorkspaceCategoryOfEntityRequest,
    reconcilio_pb.DisableWorkspaceCategoryOfEntityResponse,
    (request: reconcilio_pb.DisableWorkspaceCategoryOfEntityRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.DisableWorkspaceCategoryOfEntityResponse.deserializeBinary
  );

  disableWorkspaceCategoryOfEntity(
    request: reconcilio_pb.DisableWorkspaceCategoryOfEntityRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.DisableWorkspaceCategoryOfEntityResponse>;

  disableWorkspaceCategoryOfEntity(
    request: reconcilio_pb.DisableWorkspaceCategoryOfEntityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DisableWorkspaceCategoryOfEntityResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.DisableWorkspaceCategoryOfEntityResponse>;

  disableWorkspaceCategoryOfEntity(
    request: reconcilio_pb.DisableWorkspaceCategoryOfEntityRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DisableWorkspaceCategoryOfEntityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/DisableWorkspaceCategoryOfEntity',
        request,
        metadata || {},
        this.methodDescriptorDisableWorkspaceCategoryOfEntity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/DisableWorkspaceCategoryOfEntity',
    request,
    metadata || {},
    this.methodDescriptorDisableWorkspaceCategoryOfEntity);
  }

  methodDescriptorAddEntityStopListItem = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/AddEntityStopListItem',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.AddEntityStopListItemRequest,
    reconcilio_pb.AddEntityStopListItemResponse,
    (request: reconcilio_pb.AddEntityStopListItemRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.AddEntityStopListItemResponse.deserializeBinary
  );

  addEntityStopListItem(
    request: reconcilio_pb.AddEntityStopListItemRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.AddEntityStopListItemResponse>;

  addEntityStopListItem(
    request: reconcilio_pb.AddEntityStopListItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AddEntityStopListItemResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.AddEntityStopListItemResponse>;

  addEntityStopListItem(
    request: reconcilio_pb.AddEntityStopListItemRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AddEntityStopListItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/AddEntityStopListItem',
        request,
        metadata || {},
        this.methodDescriptorAddEntityStopListItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/AddEntityStopListItem',
    request,
    metadata || {},
    this.methodDescriptorAddEntityStopListItem);
  }

  methodDescriptorDeleteEntityStopListItem = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/DeleteEntityStopListItem',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.DeleteEntityStopListItemRequest,
    reconcilio_pb.DeleteEntityStopListItemResponse,
    (request: reconcilio_pb.DeleteEntityStopListItemRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.DeleteEntityStopListItemResponse.deserializeBinary
  );

  deleteEntityStopListItem(
    request: reconcilio_pb.DeleteEntityStopListItemRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.DeleteEntityStopListItemResponse>;

  deleteEntityStopListItem(
    request: reconcilio_pb.DeleteEntityStopListItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteEntityStopListItemResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.DeleteEntityStopListItemResponse>;

  deleteEntityStopListItem(
    request: reconcilio_pb.DeleteEntityStopListItemRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteEntityStopListItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/DeleteEntityStopListItem',
        request,
        metadata || {},
        this.methodDescriptorDeleteEntityStopListItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/DeleteEntityStopListItem',
    request,
    metadata || {},
    this.methodDescriptorDeleteEntityStopListItem);
  }

  methodDescriptorEditEntityStopListItem = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/EditEntityStopListItem',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.EditEntityStopListItemRequest,
    reconcilio_pb.EditEntityStopListItemResponse,
    (request: reconcilio_pb.EditEntityStopListItemRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.EditEntityStopListItemResponse.deserializeBinary
  );

  editEntityStopListItem(
    request: reconcilio_pb.EditEntityStopListItemRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.EditEntityStopListItemResponse>;

  editEntityStopListItem(
    request: reconcilio_pb.EditEntityStopListItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditEntityStopListItemResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.EditEntityStopListItemResponse>;

  editEntityStopListItem(
    request: reconcilio_pb.EditEntityStopListItemRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditEntityStopListItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/EditEntityStopListItem',
        request,
        metadata || {},
        this.methodDescriptorEditEntityStopListItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/EditEntityStopListItem',
    request,
    metadata || {},
    this.methodDescriptorEditEntityStopListItem);
  }

  methodDescriptorAddEntityCategory = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/AddEntityCategory',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.AddEntityCategoryRequest,
    reconcilio_pb.AddEntityCategoryResponse,
    (request: reconcilio_pb.AddEntityCategoryRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.AddEntityCategoryResponse.deserializeBinary
  );

  addEntityCategory(
    request: reconcilio_pb.AddEntityCategoryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.AddEntityCategoryResponse>;

  addEntityCategory(
    request: reconcilio_pb.AddEntityCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AddEntityCategoryResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.AddEntityCategoryResponse>;

  addEntityCategory(
    request: reconcilio_pb.AddEntityCategoryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AddEntityCategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/AddEntityCategory',
        request,
        metadata || {},
        this.methodDescriptorAddEntityCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/AddEntityCategory',
    request,
    metadata || {},
    this.methodDescriptorAddEntityCategory);
  }

  methodDescriptorDeleteEntityCategory = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/DeleteEntityCategory',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.DeleteEntityCategoryRequest,
    reconcilio_pb.DeleteEntityCategoryResponse,
    (request: reconcilio_pb.DeleteEntityCategoryRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.DeleteEntityCategoryResponse.deserializeBinary
  );

  deleteEntityCategory(
    request: reconcilio_pb.DeleteEntityCategoryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.DeleteEntityCategoryResponse>;

  deleteEntityCategory(
    request: reconcilio_pb.DeleteEntityCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteEntityCategoryResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.DeleteEntityCategoryResponse>;

  deleteEntityCategory(
    request: reconcilio_pb.DeleteEntityCategoryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteEntityCategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/DeleteEntityCategory',
        request,
        metadata || {},
        this.methodDescriptorDeleteEntityCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/DeleteEntityCategory',
    request,
    metadata || {},
    this.methodDescriptorDeleteEntityCategory);
  }

  methodDescriptorAddEntityCategoryItem = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/AddEntityCategoryItem',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.AddEntityCategoryItemRequest,
    reconcilio_pb.AddEntityCategoryItemResponse,
    (request: reconcilio_pb.AddEntityCategoryItemRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.AddEntityCategoryItemResponse.deserializeBinary
  );

  addEntityCategoryItem(
    request: reconcilio_pb.AddEntityCategoryItemRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.AddEntityCategoryItemResponse>;

  addEntityCategoryItem(
    request: reconcilio_pb.AddEntityCategoryItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AddEntityCategoryItemResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.AddEntityCategoryItemResponse>;

  addEntityCategoryItem(
    request: reconcilio_pb.AddEntityCategoryItemRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AddEntityCategoryItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/AddEntityCategoryItem',
        request,
        metadata || {},
        this.methodDescriptorAddEntityCategoryItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/AddEntityCategoryItem',
    request,
    metadata || {},
    this.methodDescriptorAddEntityCategoryItem);
  }

  methodDescriptorDeleteEntityCategoryItem = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/DeleteEntityCategoryItem',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.DeleteEntityCategoryItemRequest,
    reconcilio_pb.DeleteEntityCategoryItemResponse,
    (request: reconcilio_pb.DeleteEntityCategoryItemRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.DeleteEntityCategoryItemResponse.deserializeBinary
  );

  deleteEntityCategoryItem(
    request: reconcilio_pb.DeleteEntityCategoryItemRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.DeleteEntityCategoryItemResponse>;

  deleteEntityCategoryItem(
    request: reconcilio_pb.DeleteEntityCategoryItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteEntityCategoryItemResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.DeleteEntityCategoryItemResponse>;

  deleteEntityCategoryItem(
    request: reconcilio_pb.DeleteEntityCategoryItemRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteEntityCategoryItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/DeleteEntityCategoryItem',
        request,
        metadata || {},
        this.methodDescriptorDeleteEntityCategoryItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/DeleteEntityCategoryItem',
    request,
    metadata || {},
    this.methodDescriptorDeleteEntityCategoryItem);
  }

  methodDescriptorEditEntityCategoryName = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/EditEntityCategoryName',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.EditEntityCategoryNameRequest,
    reconcilio_pb.EditEntityCategoryNameResponse,
    (request: reconcilio_pb.EditEntityCategoryNameRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.EditEntityCategoryNameResponse.deserializeBinary
  );

  editEntityCategoryName(
    request: reconcilio_pb.EditEntityCategoryNameRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.EditEntityCategoryNameResponse>;

  editEntityCategoryName(
    request: reconcilio_pb.EditEntityCategoryNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditEntityCategoryNameResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.EditEntityCategoryNameResponse>;

  editEntityCategoryName(
    request: reconcilio_pb.EditEntityCategoryNameRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditEntityCategoryNameResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/EditEntityCategoryName',
        request,
        metadata || {},
        this.methodDescriptorEditEntityCategoryName,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/EditEntityCategoryName',
    request,
    metadata || {},
    this.methodDescriptorEditEntityCategoryName);
  }

  methodDescriptorEditEntityCategoryItem = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/EditEntityCategoryItem',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.EditEntityCategoryItemRequest,
    reconcilio_pb.EditEntityCategoryItemResponse,
    (request: reconcilio_pb.EditEntityCategoryItemRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.EditEntityCategoryItemResponse.deserializeBinary
  );

  editEntityCategoryItem(
    request: reconcilio_pb.EditEntityCategoryItemRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.EditEntityCategoryItemResponse>;

  editEntityCategoryItem(
    request: reconcilio_pb.EditEntityCategoryItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditEntityCategoryItemResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.EditEntityCategoryItemResponse>;

  editEntityCategoryItem(
    request: reconcilio_pb.EditEntityCategoryItemRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditEntityCategoryItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/EditEntityCategoryItem',
        request,
        metadata || {},
        this.methodDescriptorEditEntityCategoryItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/EditEntityCategoryItem',
    request,
    metadata || {},
    this.methodDescriptorEditEntityCategoryItem);
  }

  methodDescriptorGetWorkspaceSettingsById = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/GetWorkspaceSettingsById',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.GetWorkspaceSettingsByIdRequest,
    reconcilio_pb.GetWorkspaceSettingsByIdResponse,
    (request: reconcilio_pb.GetWorkspaceSettingsByIdRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.GetWorkspaceSettingsByIdResponse.deserializeBinary
  );

  getWorkspaceSettingsById(
    request: reconcilio_pb.GetWorkspaceSettingsByIdRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.GetWorkspaceSettingsByIdResponse>;

  getWorkspaceSettingsById(
    request: reconcilio_pb.GetWorkspaceSettingsByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetWorkspaceSettingsByIdResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.GetWorkspaceSettingsByIdResponse>;

  getWorkspaceSettingsById(
    request: reconcilio_pb.GetWorkspaceSettingsByIdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetWorkspaceSettingsByIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/GetWorkspaceSettingsById',
        request,
        metadata || {},
        this.methodDescriptorGetWorkspaceSettingsById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/GetWorkspaceSettingsById',
    request,
    metadata || {},
    this.methodDescriptorGetWorkspaceSettingsById);
  }

  methodDescriptorGetEntitySettingsById = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/GetEntitySettingsById',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.GetEntitySettingsByIdRequest,
    reconcilio_pb.GetEntitySettingsByIdResponse,
    (request: reconcilio_pb.GetEntitySettingsByIdRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.GetEntitySettingsByIdResponse.deserializeBinary
  );

  getEntitySettingsById(
    request: reconcilio_pb.GetEntitySettingsByIdRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.GetEntitySettingsByIdResponse>;

  getEntitySettingsById(
    request: reconcilio_pb.GetEntitySettingsByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetEntitySettingsByIdResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.GetEntitySettingsByIdResponse>;

  getEntitySettingsById(
    request: reconcilio_pb.GetEntitySettingsByIdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetEntitySettingsByIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/GetEntitySettingsById',
        request,
        metadata || {},
        this.methodDescriptorGetEntitySettingsById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/GetEntitySettingsById',
    request,
    metadata || {},
    this.methodDescriptorGetEntitySettingsById);
  }

  methodDescriptorCreateLedgerTransaction = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/CreateLedgerTransaction',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.CreateLedgerTransactionRequest,
    reconcilio_pb.CreateLedgerTransactionResponse,
    (request: reconcilio_pb.CreateLedgerTransactionRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.CreateLedgerTransactionResponse.deserializeBinary
  );

  createLedgerTransaction(
    request: reconcilio_pb.CreateLedgerTransactionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.CreateLedgerTransactionResponse>;

  createLedgerTransaction(
    request: reconcilio_pb.CreateLedgerTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.CreateLedgerTransactionResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.CreateLedgerTransactionResponse>;

  createLedgerTransaction(
    request: reconcilio_pb.CreateLedgerTransactionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.CreateLedgerTransactionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/CreateLedgerTransaction',
        request,
        metadata || {},
        this.methodDescriptorCreateLedgerTransaction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/CreateLedgerTransaction',
    request,
    metadata || {},
    this.methodDescriptorCreateLedgerTransaction);
  }

  methodDescriptorEditLedgerTransaction = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/EditLedgerTransaction',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.EditLedgerTransactionRequest,
    reconcilio_pb.EditLedgerTransactionResponse,
    (request: reconcilio_pb.EditLedgerTransactionRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.EditLedgerTransactionResponse.deserializeBinary
  );

  editLedgerTransaction(
    request: reconcilio_pb.EditLedgerTransactionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.EditLedgerTransactionResponse>;

  editLedgerTransaction(
    request: reconcilio_pb.EditLedgerTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditLedgerTransactionResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.EditLedgerTransactionResponse>;

  editLedgerTransaction(
    request: reconcilio_pb.EditLedgerTransactionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditLedgerTransactionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/EditLedgerTransaction',
        request,
        metadata || {},
        this.methodDescriptorEditLedgerTransaction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/EditLedgerTransaction',
    request,
    metadata || {},
    this.methodDescriptorEditLedgerTransaction);
  }

  methodDescriptorDeleteLedgerTransaction = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/DeleteLedgerTransaction',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.DeleteLedgerTransactionRequest,
    reconcilio_pb.DeleteLedgerTransactionResponse,
    (request: reconcilio_pb.DeleteLedgerTransactionRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.DeleteLedgerTransactionResponse.deserializeBinary
  );

  deleteLedgerTransaction(
    request: reconcilio_pb.DeleteLedgerTransactionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.DeleteLedgerTransactionResponse>;

  deleteLedgerTransaction(
    request: reconcilio_pb.DeleteLedgerTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteLedgerTransactionResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.DeleteLedgerTransactionResponse>;

  deleteLedgerTransaction(
    request: reconcilio_pb.DeleteLedgerTransactionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteLedgerTransactionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/DeleteLedgerTransaction',
        request,
        metadata || {},
        this.methodDescriptorDeleteLedgerTransaction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/DeleteLedgerTransaction',
    request,
    metadata || {},
    this.methodDescriptorDeleteLedgerTransaction);
  }

  methodDescriptorUnreconcileMatch = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/UnreconcileMatch',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.UnreconcileMatchRequest,
    reconcilio_pb.UnreconcileMatchResponse,
    (request: reconcilio_pb.UnreconcileMatchRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.UnreconcileMatchResponse.deserializeBinary
  );

  unreconcileMatch(
    request: reconcilio_pb.UnreconcileMatchRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.UnreconcileMatchResponse>;

  unreconcileMatch(
    request: reconcilio_pb.UnreconcileMatchRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.UnreconcileMatchResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.UnreconcileMatchResponse>;

  unreconcileMatch(
    request: reconcilio_pb.UnreconcileMatchRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.UnreconcileMatchResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/UnreconcileMatch',
        request,
        metadata || {},
        this.methodDescriptorUnreconcileMatch,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/UnreconcileMatch',
    request,
    metadata || {},
    this.methodDescriptorUnreconcileMatch);
  }

  methodDescriptorGetAdjustmentsReport = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/GetAdjustmentsReport',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.GetAdjustmentsReportRequest,
    reconcilio_pb.GetAdjustmentsReportResponse,
    (request: reconcilio_pb.GetAdjustmentsReportRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.GetAdjustmentsReportResponse.deserializeBinary
  );

  getAdjustmentsReport(
    request: reconcilio_pb.GetAdjustmentsReportRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.GetAdjustmentsReportResponse>;

  getAdjustmentsReport(
    request: reconcilio_pb.GetAdjustmentsReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetAdjustmentsReportResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.GetAdjustmentsReportResponse>;

  getAdjustmentsReport(
    request: reconcilio_pb.GetAdjustmentsReportRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.GetAdjustmentsReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/GetAdjustmentsReport',
        request,
        metadata || {},
        this.methodDescriptorGetAdjustmentsReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/GetAdjustmentsReport',
    request,
    metadata || {},
    this.methodDescriptorGetAdjustmentsReport);
  }

  methodDescriptorEditReconciliationReportDetails = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/EditReconciliationReportDetails',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.EditReconciliationReportDetailsRequest,
    reconcilio_pb.EditReconciliationReportDetailsResponse,
    (request: reconcilio_pb.EditReconciliationReportDetailsRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.EditReconciliationReportDetailsResponse.deserializeBinary
  );

  editReconciliationReportDetails(
    request: reconcilio_pb.EditReconciliationReportDetailsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.EditReconciliationReportDetailsResponse>;

  editReconciliationReportDetails(
    request: reconcilio_pb.EditReconciliationReportDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditReconciliationReportDetailsResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.EditReconciliationReportDetailsResponse>;

  editReconciliationReportDetails(
    request: reconcilio_pb.EditReconciliationReportDetailsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.EditReconciliationReportDetailsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/EditReconciliationReportDetails',
        request,
        metadata || {},
        this.methodDescriptorEditReconciliationReportDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/EditReconciliationReportDetails',
    request,
    metadata || {},
    this.methodDescriptorEditReconciliationReportDetails);
  }

  methodDescriptorAcceptAllSuggestions = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/AcceptAllSuggestions',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.AcceptAllSuggestionsRequest,
    reconcilio_pb.AcceptAllSuggestionsResponse,
    (request: reconcilio_pb.AcceptAllSuggestionsRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.AcceptAllSuggestionsResponse.deserializeBinary
  );

  acceptAllSuggestions(
    request: reconcilio_pb.AcceptAllSuggestionsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.AcceptAllSuggestionsResponse>;

  acceptAllSuggestions(
    request: reconcilio_pb.AcceptAllSuggestionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AcceptAllSuggestionsResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.AcceptAllSuggestionsResponse>;

  acceptAllSuggestions(
    request: reconcilio_pb.AcceptAllSuggestionsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.AcceptAllSuggestionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/AcceptAllSuggestions',
        request,
        metadata || {},
        this.methodDescriptorAcceptAllSuggestions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/AcceptAllSuggestions',
    request,
    metadata || {},
    this.methodDescriptorAcceptAllSuggestions);
  }

  methodDescriptorRefreshToken = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/RefreshToken',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.RefreshTokenRequest,
    reconcilio_pb.RefreshTokenResponse,
    (request: reconcilio_pb.RefreshTokenRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.RefreshTokenResponse.deserializeBinary
  );

  refreshToken(
    request: reconcilio_pb.RefreshTokenRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.RefreshTokenResponse>;

  refreshToken(
    request: reconcilio_pb.RefreshTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.RefreshTokenResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.RefreshTokenResponse>;

  refreshToken(
    request: reconcilio_pb.RefreshTokenRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.RefreshTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/RefreshToken',
        request,
        metadata || {},
        this.methodDescriptorRefreshToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/RefreshToken',
    request,
    metadata || {},
    this.methodDescriptorRefreshToken);
  }

  methodDescriptorCreateLedgerTransactions = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/CreateLedgerTransactions',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.CreateLedgerTransactionsRequest,
    reconcilio_pb.CreateLedgerTransactionsResponse,
    (request: reconcilio_pb.CreateLedgerTransactionsRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.CreateLedgerTransactionsResponse.deserializeBinary
  );

  createLedgerTransactions(
    request: reconcilio_pb.CreateLedgerTransactionsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.CreateLedgerTransactionsResponse>;

  createLedgerTransactions(
    request: reconcilio_pb.CreateLedgerTransactionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.CreateLedgerTransactionsResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.CreateLedgerTransactionsResponse>;

  createLedgerTransactions(
    request: reconcilio_pb.CreateLedgerTransactionsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.CreateLedgerTransactionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/CreateLedgerTransactions',
        request,
        metadata || {},
        this.methodDescriptorCreateLedgerTransactions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/CreateLedgerTransactions',
    request,
    metadata || {},
    this.methodDescriptorCreateLedgerTransactions);
  }

  methodDescriptorDeleteReconciliationReport = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/DeleteReconciliationReport',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.DeleteReconciliationReportRequest,
    reconcilio_pb.DeleteReconciliationReportResponse,
    (request: reconcilio_pb.DeleteReconciliationReportRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.DeleteReconciliationReportResponse.deserializeBinary
  );

  deleteReconciliationReport(
    request: reconcilio_pb.DeleteReconciliationReportRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.DeleteReconciliationReportResponse>;

  deleteReconciliationReport(
    request: reconcilio_pb.DeleteReconciliationReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteReconciliationReportResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.DeleteReconciliationReportResponse>;

  deleteReconciliationReport(
    request: reconcilio_pb.DeleteReconciliationReportRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.DeleteReconciliationReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/DeleteReconciliationReport',
        request,
        metadata || {},
        this.methodDescriptorDeleteReconciliationReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/DeleteReconciliationReport',
    request,
    metadata || {},
    this.methodDescriptorDeleteReconciliationReport);
  }

  methodDescriptorCreateConverterTracking = new grpcWeb.MethodDescriptor(
    '/reconcilio.ReconcilioService/CreateConverterTracking',
    grpcWeb.MethodType.UNARY,
    reconcilio_pb.CreateConverterTrackingRequest,
    reconcilio_pb.CreateConverterTrackingResponse,
    (request: reconcilio_pb.CreateConverterTrackingRequest) => {
      return request.serializeBinary();
    },
    reconcilio_pb.CreateConverterTrackingResponse.deserializeBinary
  );

  createConverterTracking(
    request: reconcilio_pb.CreateConverterTrackingRequest,
    metadata?: grpcWeb.Metadata | null): Promise<reconcilio_pb.CreateConverterTrackingResponse>;

  createConverterTracking(
    request: reconcilio_pb.CreateConverterTrackingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reconcilio_pb.CreateConverterTrackingResponse) => void): grpcWeb.ClientReadableStream<reconcilio_pb.CreateConverterTrackingResponse>;

  createConverterTracking(
    request: reconcilio_pb.CreateConverterTrackingRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reconcilio_pb.CreateConverterTrackingResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/reconcilio.ReconcilioService/CreateConverterTracking',
        request,
        metadata || {},
        this.methodDescriptorCreateConverterTracking,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/reconcilio.ReconcilioService/CreateConverterTracking',
    request,
    metadata || {},
    this.methodDescriptorCreateConverterTracking);
  }

}

