import { ChangeEvent } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Link } from './Styles';

interface Props {
    onTermsChange: (accepted: boolean) => void;
    termsAccepted: boolean;
};

const privacyPageUrl = 'https://www.reconcilio.com/privacy-policy';
const termsAndConditionsUrl = 'https://www.reconcilio.com/terms-conditions';

export default function TermsCheckbox(
    {
        onTermsChange,
        termsAccepted
    }: Props
) {

    const onCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
        onTermsChange(event.target.checked)
    }

    return (
        <div className="flex items-center gap-2">
            <Checkbox
                id="terms"
                onChange={onCheckChange}
                checked={termsAccepted}
            />
            <label
                htmlFor="terms"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
                I have read and accept to the{' '}
                <Link
                    href={termsAndConditionsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary hover:underline"
                >
                    Terms and conditions
                </Link>
                {' '}and{' '}
                <Link
                    href={privacyPageUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary hover:underline"
                >
                    Privacy Policy
                </Link>
            </label>
        </div>
    )
};
