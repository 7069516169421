import {
  CreateReconciliationReportResult,
  IReconciliationReportService,
  MatchModel,
  ReconciliationReportModel,
  ReconciliationAccountReportModel,
  ReconciliationTransaction,
  ReportContext,
  SuggestionModel,
  Transaction,
  UploadDocumentRequestType,
  CreateReconciliationReportInputParams,
  CreateLedgerTransactionParams,
  UpdateLedgerTransactionParams,
  DeleteLedgerTransactionParams,
  UnreconcileMatchParams,
  LockReconciliationReportParams,
  GetAdjustmentsReportParams,
  GetAdjustmentsReportResp,
  TransactionType,
  UpdatedTransactionsType,
  ReconciliationReportDetailsParams,
  CreateConverterTrackingParams,
  ValidateDocumentResult,
} from '../interfaces/IReconciliationReportService';
import { ReconcilioServiceClient } from '../../bitloops/proto/ReconcilioServiceClientPb';
import { PROXY_URL } from '../../config';
import {
  AcceptAllSuggestionsRequest,
  AcceptSuggestionRequest,
  CreateReconciliationReportRequest,
  FinancialDocument,
  FinancialTransaction,
  GetReconciliationAccountReportsRequest,
  GetReconciliationReportOKResponse,
  GetReconciliationReportRequest,
  GetSuggestionsRequest,
  GetSuggestionsResponse,
  ManualReconciliationRequest,
  Match,
  RejectSuggestionRequest,
  Suggestion,
  UploadDocumentRequest,
  UploadDocumentResponse,
  ValidateDocumentRequest,
  ValidateDocumentResponse,
  GetReconciliationReportResponse,
  CreateLedgerTransactionRequest,
  CreateLedgerTransactionResponse,
  CreateLedgerTransactionErrorResponse,
  UploadDocumentErrorResponse,
  ValidateDocumentErrorResponse,
  RejectSuggestionErrorResponse,
  AcceptSuggestionErrorResponse,
  GetReconciliationReportErrorResponse,
  GetSuggestionsErrorResponse,
  GetReconciliationAccountReportsErrorResponse,
  ManualReconciliationErrorResponse,
  EditLedgerTransactionRequest,
  EditLedgerTransactionResponse,
  EditLedgerTransactionErrorResponse,
  DeleteLedgerTransactionRequest,
  DeleteLedgerTransactionResponse,
  DeleteLedgerTransactionErrorResponse,
  UnreconcileMatchRequest,
  UnreconcileMatchResponse,
  UnreconcileMatchErrorResponse,
  LockReconciliationReportRequest,
  LockReconciliationReportResponse,
  LockReconciliationReportErrorResponse,
  GetAdjustmentsReportRequest,
  GetAdjustmentsReportResponse,
  GetAdjustmentsReportErrorResponse,
  // GetAdjustmentsReportOKResponse,
  // CreateReconciliationReportResponse,
  // CreateReconciliationReportErrorResponse,
  EditReconciliationReportDetailsRequest,
  EditReconciliationReportDetailsErrorResponse,
  AcceptAllSuggestionsErrorResponse,
  CreateLedgerTransactionsRequest,
  CreateLedgerTransaction,
  CreateLedgerTransactionsResponse,
  DeleteReconciliationReportRequest,
  DeleteReconciliationReportResponse,
  DeleteReconciliationReportErrorResponse,
  RejectSuggestionsRequest,
  RejectSuggestionsErrorResponse,
  AcceptSuggestionsRequest,
  AcceptSuggestionsErrorResponse,
  CreateConverterTrackingRequest,
  CreateConverterTrackingErrorResponse,
} from '../../bitloops/proto/reconcilio_pb';
import { ReportServiceErrors } from '../../Errors';
import { CreateLedgerTransactionsParams } from '../../models/ManualReconciliation';

enum AMOUNT_TYPE {
  DEBIT = 'debit',
  CREDIT = 'credit',
}

export default class GRPCReconcilationReportService implements IReconciliationReportService {
  private reconciliationService: ReconcilioServiceClient;

  constructor() {
    this.reconciliationService = new ReconcilioServiceClient(PROXY_URL);
  }

  async rejectSuggestedMatches(
    reconciliationReportId: string,
    suggestionIds: string[],
    accessToken: string | null
  ): Promise<void> {
    const request = new RejectSuggestionsRequest();
    request.setReconciliationReportId(reconciliationReportId);
    request.setSuggestionIdsList(suggestionIds);
    const response = await this.reconciliationService.rejectSuggestions(request, {
      Authorization: `Bearer ${accessToken}`,
    });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: RejectSuggestionsErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case RejectSuggestionsErrorResponse.ErrorCase.RECONCILIATION_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_NOT_FOUND_ERROR();
        case RejectSuggestionsErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
  }

  async acceptSuggestedMatches(
    reconciliationReportId: string,
    suggestionIds: string[],
    accessToken: string | null
  ): Promise<void> {
    const request = new AcceptSuggestionsRequest();
    request.setReconciliationReportId(reconciliationReportId);
    request.setSuggestionIdsList(suggestionIds);
    const response = await this.reconciliationService.acceptSuggestions(request, {
      Authorization: `Bearer ${accessToken}`,
    });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: AcceptSuggestionsErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case AcceptSuggestionsErrorResponse.ErrorCase.RECONCILIATION_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_NOT_FOUND_ERROR();
        case AcceptSuggestionsErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
  }

  async acceptAllSuggestedMatches(
    reconciliationReportId: string,
    accessToken: string | null
  ): Promise<void> {
    const request = new AcceptAllSuggestionsRequest();
    request.setReconciliationReportId(reconciliationReportId);
    const response = await this.reconciliationService.acceptAllSuggestions(request, {
      Authorization: `Bearer ${accessToken}`,
    });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: AcceptAllSuggestionsErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case AcceptAllSuggestionsErrorResponse.ErrorCase.CREDIT_DEBIT_SUM_ERROR:
          throw ReportServiceErrors.TRANSACTIONS_NOT_SUM_TO_ZERO_ERROR();
        case AcceptAllSuggestionsErrorResponse.ErrorCase.RECONCILIATION_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_NOT_FOUND_ERROR();
        case AcceptAllSuggestionsErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
  }

  async editReconciliationReportDetails(
    reconciliationReportId: string,
    reconciliationReportDetails: ReconciliationReportDetailsParams,
    accessToken: string | null
  ): Promise<void> {
    const request = new EditReconciliationReportDetailsRequest();
    request.setReconciliationReportId(reconciliationReportId);
    const { name, label, comment } = reconciliationReportDetails;
    if (name !== undefined && name !== null) request.setName(name);
    if (label !== undefined && label !== null) request.setLabel(label);
    if (comment !== undefined && comment !== null) request.setComment(comment);
    const response = await this.reconciliationService.editReconciliationReportDetails(request, {
      Authorization: `Bearer ${accessToken}`,
    });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: EditReconciliationReportDetailsErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case EditReconciliationReportDetailsErrorResponse.ErrorCase
          .RECONCILIATION_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_NOT_FOUND_ERROR();
        case EditReconciliationReportDetailsErrorResponse.ErrorCase
          .CANNOT_CHANGE_LOCKED_RECONCILIATION_REPORT_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_STATUS_LOCKED_ERROR();
        case EditReconciliationReportDetailsErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
  }

  async getReconciliationReportsByAccountId(
    accountId: string,
    accessToken: string | null
  ): Promise<ReconciliationAccountReportModel[]> {
    /**
     *   rpc GetReconciliationAccountReports(GetReconciliationAccountReportsRequest) returns (
    GetReconciliationAccountReportsResponse
  );
     */
    const request = new GetReconciliationAccountReportsRequest();
    request.setReconciliationAccountId(accountId);

    const response = await this.reconciliationService.getReconciliationAccountReports(request, {
      Authorization: `Bearer ${accessToken}`,
    });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: GetReconciliationAccountReportsErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case GetReconciliationAccountReportsErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
    const reportsList = response.getOk()?.getReportsList();
    if (!reportsList) {
      return [];
    }
    return reportsList.map((report) => ({
      ...report.toObject(),
      ledgerOpeningBalance: report.getLedgerOpeningBalance(),
      ledgerClosingBalance: report.getLedgerClosingBalance(),
      bankOpeningBalance: report.getExternalOpeningBalance(),
      bankClosingBalance: report.getExternalClosingBalance(),
      reconciliationPeriod: report.getReconciliationPeriod()!.toObject(),
      createdAt: report.getCreatedat(),
    }));
  }

  public async createReconciliationReport(
    params: CreateReconciliationReportInputParams,
    reportContext: ReportContext,
    accessToken: string | null,
    onData: (result: CreateReconciliationReportResult) => void,
    onCompleted: () => void,
    onError: (error: any) => void
  ): Promise<void> {
    const {
      externalDocumentId,
      ledgerDocumentId,
      period,
      previousReconciliationReportId,
      shouldCreateCompensatingTransaction,
      ledgerDocumentType,
      externalDocumentType,
      ledgerOB,
      externalOB,
      ledgerDebitIncrease,
      externalDebitIncrease,
    } = params;

    const request = new CreateReconciliationReportRequest();
    request.setExternalDocumentId(externalDocumentId);
    request.setLedgerDocumentId(ledgerDocumentId);
    request.setStartDate(period.startDate);
    request.setEndDate(period.endDate);
    request.setWorkspaceId(reportContext.workspaceId);
    request.setEntityId(reportContext.entityId);
    request.setAccountId(reportContext.accountId);
    request.setShouldCreateCompensatingTransaction(shouldCreateCompensatingTransaction);
    request.setLedgerDocumentType(ledgerDocumentType);
    request.setExternalDocumentType(externalDocumentType);
    if (previousReconciliationReportId) {
      request.setPreviousReconciliationId(previousReconciliationReportId);
    }
    if (ledgerOB !== undefined) {
      request.setOverwriteLedgerOb(ledgerOB);
      request.setIsLedgerOverwritten(true);
    }
    if (externalOB !== undefined) {
      request.setOverwriteExternalOb(externalOB);
      request.setIsExternalOverwritten(true);
    }
    if (ledgerDebitIncrease !== undefined) {
      if (ledgerDebitIncrease) {
        request.setLedgerDebitIncreaseBalance(true);
      } else {
        request.setLedgerDebitDecreaseBalance(true);
      }
    }
    if (externalDebitIncrease !== undefined) {
      if (externalDebitIncrease) {
        request.setExternalDebitIncreaseBalance(true);
      } else {
        request.setExternalDebitDecreaseBalance(true);
      }
    }

    const stream = this.reconciliationService.createReconciliationReport(request, {
      Authorization: `Bearer ${accessToken}`,
    });

    stream.on('data', (response) => {
      if (response.hasError()) {
        stream.cancel();

        const error = response.getError();
        console.error(error);

        if (error?.hasUnexpectedError()) {
          console.log(
            'There is an unexpected error!!',
            (error?.getUnexpectedError() as any)?.array
          );
          const unexpectedError = (error?.getUnexpectedError() as any)?.array;
          const [errorId, errorMessage] = unexpectedError;

          switch (errorId) {
            case '2ez98ba2-3141-453a-b3c1-c955a72cbbze':
            case '3fz98ba2-3141-453a-b3c1-c955a72cbbzf':
              // eslint-disable-next-line no-case-declarations
              const error1 = ReportServiceErrors.MISMATCH_IN_LEDGER_BALANCE_ERROR(errorMessage);
              onError(error1);
              return;
            case '9ez98ba2-3141-453a-b3c1-c955a72cbbze':
              // eslint-disable-next-line no-case-declarations
              const error2 =
                ReportServiceErrors.TRANSACTION_DATE_NOT_IN_RECONCILIATION_REPORT_RANGE_ERROR();
              // If Transaction matching account
              if (ledgerDocumentType === 'Internal') {
                error2.message = `There are 1 or more transactions with a date that is after the End Date of the Reconciliation Report. 
                  Please change the Reconciliation Period or edit the incorrect statement.`;
              }
              onError(error2);
              return;
            case '5hb98vr2-4141-453a-b3c1-a958a72cbceb':
              onError(ReportServiceErrors.MIXED_DATES_ERROR());
              return;
            case '3ha98vr2-4141-453a-b3c1-a958a72cbceb':
              onError(ReportServiceErrors.INVALID_DATE_FORMAT_ERROR());
              return;
            case 'f7d579cf-ec12-4353-8339-fcae0bfa99b0':
              onError(ReportServiceErrors.MISSING_OPENING_BALANCE_ERROR());
              return;
            case '5ja98vr2-4141-453a-b3c1-a958a72cbceb':
              onError(ReportServiceErrors.DEBIT_CREDIT_MUST_BE_EXCLUSIVELY_FILLED_ERROR());
              return;
            case '4ia98vr2-4141-453a-b3c1-a958a72cbceb':
              onError(ReportServiceErrors.INVALID_BALANCES_ERROR());
              return;

            default: {
              const unexpectedDefaultError = ReportServiceErrors.UNEXPECTED_ERROR();
              onError(unexpectedDefaultError);
            }
          }
        }
      }
      const progressPercentage = response.getProgress()?.getProgress()?.getPercentage();
      const total = response.getProgress()?.getProgress()?.getTotal();
      const totalReconciled = response.getProgress()?.getProgress()?.getTotalReconciled();
      const message = response.getProgress()?.getMessage();
      const id = response.getOk()?.getId();
      onData({ progressPercentage, message, id, total, totalReconciled });
    });

    stream.on('end', () => {
      stream.cancel();
      onCompleted();
    });

    stream.on('error', (error: any) => {
      console.error(error);
      switch (error?.getErrorCase()) {
        case GetSuggestionsErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        default: {
          stream.cancel();
          const unknownError = ReportServiceErrors.UNKNOWN_ERROR();
          onError(unknownError);
        }
      }
    });
  }

  public async getSuggestions(
    reconciliationReportId: string,
    accessToken: string | null
  ): Promise<SuggestionModel[]> {
    const request = new GetSuggestionsRequest();
    request.setReconciliationReportId(reconciliationReportId);
    const response: GetSuggestionsResponse = await this.reconciliationService.getSuggestions(
      request,
      {
        Authorization: `Bearer ${accessToken}`,
      }
    );
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: GetSuggestionsErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case GetSuggestionsErrorResponse.ErrorCase.RECONCILIATION_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_NOT_FOUND_ERROR();
        case GetSuggestionsErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
    const suggestions = response.getOk()?.getSuggestionsList();
    if (!suggestions) {
      return [];
    }

    return this.mapSuggestionListToSuggestionArray(suggestions);
  }

  public async getReconciliationReport(
    reconciliationReportId: string,
    accessToken: string | null
  ): Promise<ReconciliationReportModel> {
    const request = new GetReconciliationReportRequest();
    request.setReconciliationReportId(reconciliationReportId);

    const response: GetReconciliationReportResponse =
      await this.reconciliationService.getReconciliation(request, {
        Authorization: `Bearer ${accessToken}`,
      });

    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: GetReconciliationReportErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case GetReconciliationReportErrorResponse.ErrorCase.RECONCILIATION_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_NOT_FOUND_ERROR();
        case GetReconciliationReportErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }

    return this.mapReconciliationReportToReconciliationReportModel(response.getOk()!);
  }

  public async acceptSuggestedMatch(
    reconciliationReportId: string,
    suggestedMatchId: string,
    accessToken: string | null
  ): Promise<void> {
    const request = new AcceptSuggestionRequest();
    request.setSuggestionId(suggestedMatchId);
    request.setReconciliationReportId(reconciliationReportId);
    const response = await this.reconciliationService.acceptSuggestion(request, {
      Authorization: `Bearer ${accessToken}`,
    });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: AcceptSuggestionErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case AcceptSuggestionErrorResponse.ErrorCase.RECONCILIATION_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_NOT_FOUND_ERROR();
        case AcceptSuggestionErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
  }

  public async rejectSuggestedMatch(
    reconciliationReportId: string,
    suggestedMatchId: string,
    accessToken: string | null
  ): Promise<void> {
    const request = new RejectSuggestionRequest();
    request.setSuggestionId(suggestedMatchId);
    request.setReconciliationReportId(reconciliationReportId);
    const response = await this.reconciliationService.rejectSuggestion(request, {
      Authorization: `Bearer ${accessToken}`,
    });
    // TODO fix to return standard error
    // console.log('getError case', response.getError()?.toObject());
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: RejectSuggestionErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case RejectSuggestionErrorResponse.ErrorCase.RECONCILIATION_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_NOT_FOUND_ERROR();
        case RejectSuggestionErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
  }

  public async createConverterTracking(
    params: CreateConverterTrackingParams,
    accessToken: string | null
  ): Promise<void> {
    const { workspaceId, format, type } = params;
    const request = new CreateConverterTrackingRequest();
    request.setWorkspaceId(workspaceId);
    request.setFormat(format);
    request.setType(type);
    const response = await this.reconciliationService.createConverterTracking(request, {
      Authorization: `Bearer ${accessToken}`,
    });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: CreateConverterTrackingErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case CreateConverterTrackingErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
  }

  public async validateDocument(
    id: string,
    concreteDocumentType: string,
    accessToken: string | null
  ): Promise<ValidateDocumentResult> {
    const request = new ValidateDocumentRequest();
    request.setId(id);
    request.setConcreteDocumentType(concreteDocumentType);
    const response: ValidateDocumentResponse = await this.reconciliationService.validateDocument(
      request,
      {
        Authorization: `Bearer ${accessToken}`,
      }
    );

    const errorResponse = response.getError();
    console.log('errorResponse', errorResponse);
    if (response.hasError() && errorResponse) {
      const error: ValidateDocumentErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case ValidateDocumentErrorResponse.ErrorCase.DOCUMENT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.DOCUMENT_NOT_FOUND_ERROR();
        case ValidateDocumentErrorResponse.ErrorCase.INVALID_DOCUMENT_STATUS_ERROR:
          throw ReportServiceErrors.INVALID_DOCUMENT_STATUS_ERROR();
        case ValidateDocumentErrorResponse.ErrorCase.INVALID_DATE_FORMAT_ERROR:
          throw ReportServiceErrors.INVALID_DATE_FORMAT_ERROR();
        case ValidateDocumentErrorResponse.ErrorCase.MISSING_OPENING_BALANCE_ERROR:
          throw ReportServiceErrors.MISSING_OPENING_BALANCE_ERROR();
        case ValidateDocumentErrorResponse.ErrorCase.DEBIT_CREDIT_MUST_BE_EXCLUSIVELY_FILLED_ERROR:
          throw ReportServiceErrors.DEBIT_CREDIT_MUST_BE_EXCLUSIVELY_FILLED_ERROR();
        case ValidateDocumentErrorResponse.ErrorCase.INVALID_BALANCES_ERROR:
          throw ReportServiceErrors.INVALID_BALANCES_ERROR();
        case ValidateDocumentErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ReportServiceErrors.UNAUTHORIZED_ERROR();
        case ValidateDocumentErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        case ValidateDocumentErrorResponse.ErrorCase.MIXED_DATES_FORMAT_ERROR:
          throw ReportServiceErrors.MIXED_DATES_ERROR();
        case ValidateDocumentErrorResponse.ErrorCase.DOCUMENT_FORMAT_TYPE_MISSING_ERROR:
          throw ReportServiceErrors.MISSING_DOCUMENT_FORMAT_TYPE_ERROR();
        case ValidateDocumentErrorResponse.ErrorCase.DEBIT_CREDIT_SWAPPED_ERROR:
          throw ReportServiceErrors.DEBIT_CREDIT_SWAPPED_ERROR();
        default:
          console.log('Unknown');
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
    const okResponse = response.getOk();

    return {
      openingBalance: okResponse ? okResponse.getOpeningBalance() : null,
      isOpeningBalanceSet: okResponse ? okResponse.getIsOpeningBalanceSet() : false,
    };
  }

  public async uploadDocument(data: UploadDocumentRequestType): Promise<string> {
    const request = new UploadDocumentRequest();
    request.setName(data.name);
    request.setDocumentType(data.documentType);
    request.setStorageIdentifier(data.storagePath);
    request.setFileType(data.fileType);
    request.setConcreteDocumentType(data.concreteType);
    request.setWorkspaceId(data.workspaceId);
    request.setEntityId(data.entityId);
    request.setAccountId(data.accountId);
    if (data.isNotInStandardTemplateFormat)
      request.setIsNotInStandardTemplateFormat(data.isNotInStandardTemplateFormat);

    const response: UploadDocumentResponse = await this.reconciliationService.uploadDocument(
      request,
      {
        Authorization: `Bearer ${data.accessToken}`,
      }
    );
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: UploadDocumentErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case UploadDocumentErrorResponse.ErrorCase.INVALID_FILE_TYPE_ERROR:
          throw ReportServiceErrors.INVALID_FILE_TYPE_ERROR();
        case UploadDocumentErrorResponse.ErrorCase.INVALID_DOCUMENT_LOCATION_ERROR:
          throw ReportServiceErrors.INVALID_DOCUMENT_LOCATION_ERROR();
        case UploadDocumentErrorResponse.ErrorCase.INVALID_DOCUMENT_TYPE_ERROR:
          throw ReportServiceErrors.INVALID_DOCUMENT_TYPE_ERROR();
        case UploadDocumentErrorResponse.ErrorCase.INVALID_DOCUMENT_STATUS_ERROR:
          throw ReportServiceErrors.INVALID_DOCUMENT_STATUS_ERROR();
        case UploadDocumentErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ReportServiceErrors.UNAUTHORIZED_ERROR();
        case UploadDocumentErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
    const id = response.getOk()?.getId();
    if (!id) {
      throw new Error('No id returned');
    }
    console.log('GRPCReconcilationReportService -> uploadDocument -> id', id);
    return id;
  }

  public async createLedgerTransaction(
    data: CreateLedgerTransactionParams,
    accessToken: string | null
  ): Promise<number> {
    const request = new CreateLedgerTransactionRequest();
    request.setReconciliationReportId(data.reconciliationReportId);
    if (data.financialTransactionId) request.setFinancialTransactionId(data.financialTransactionId);
    request.setDescription(data.description);
    request.setAmount(data.amount);
    if (data.reference) request.setReference(data.reference);
    request.setTransactionType(data.transactionType);
    request.setDate(data.date);

    const response: CreateLedgerTransactionResponse =
      await this.reconciliationService.createLedgerTransaction(request, {
        Authorization: `Bearer ${accessToken}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: CreateLedgerTransactionErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case CreateLedgerTransactionErrorResponse.ErrorCase.RECONCILIATION_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_NOT_FOUND_ERROR();
        case CreateLedgerTransactionErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        case CreateLedgerTransactionErrorResponse.ErrorCase.INVALID_BALANCES_ERROR:
          throw ReportServiceErrors.INVALID_BALANCES_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
    const id = response.getOk()?.getRowId();
    if (!id) {
      throw new Error('No id returned');
    }
    return id;
  }

  public async editLedgerTransaction(
    data: UpdateLedgerTransactionParams,
    accessToken: string | null
  ): Promise<number> {
    const request = new EditLedgerTransactionRequest();
    request.setReconciliationReportId(data.reconciliationReportId);
    request.setRowId(data.rowId);
    if (data.financialTransactionId) request.setFinancialTransactionId(data.financialTransactionId);
    if (data.description) request.setDescription(data.description);

    if (data.amount !== null && data.amount !== undefined) request.setAmount(+data.amount);
    if (data.reference !== undefined && data.reference !== null)
      request.setReference(data.reference);
    if (data.transactionType) request.setTransactionType(data.transactionType);
    if (data.date) request.setDate(data.date);

    const response: EditLedgerTransactionResponse =
      await this.reconciliationService.editLedgerTransaction(request, {
        Authorization: `Bearer ${accessToken}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: EditLedgerTransactionErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case EditLedgerTransactionErrorResponse.ErrorCase.RECONCILIATION_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_NOT_FOUND_ERROR();
        case EditLedgerTransactionErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        case EditLedgerTransactionErrorResponse.ErrorCase.INVALID_BALANCES_ERROR:
          throw ReportServiceErrors.INVALID_BALANCES_ERROR();
        case EditLedgerTransactionErrorResponse.ErrorCase
          .CANNOT_CHANGE_LOCKED_RECONCILIATION_REPORT_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_STATUS_LOCKED_ERROR();
        case EditLedgerTransactionErrorResponse.ErrorCase.TRANSACTION_NOT_FOUND_ERROR:
          throw ReportServiceErrors.TRANSACTION_WAS_NOT_FOUND();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
    const id = response.getOk()?.getRowId();
    if (!id) {
      throw new Error('No id returned');
    }
    return id;
  }

  public async deleteLedgerTransaction(
    data: DeleteLedgerTransactionParams,
    accessToken: string | null
  ): Promise<number> {
    const request = new DeleteLedgerTransactionRequest();
    request.setReconciliationReportId(data.reconciliationReportId);
    request.setRowId(data.rowId);

    const response: DeleteLedgerTransactionResponse =
      await this.reconciliationService.deleteLedgerTransaction(request, {
        Authorization: `Bearer ${accessToken}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: DeleteLedgerTransactionErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case DeleteLedgerTransactionErrorResponse.ErrorCase.RECONCILIATION_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_NOT_FOUND_ERROR();
        case DeleteLedgerTransactionErrorResponse.ErrorCase.TRANSACTION_NOT_FOUND_ERROR:
          throw ReportServiceErrors.TRANSACTION_WAS_NOT_FOUND();
        case DeleteLedgerTransactionErrorResponse.ErrorCase
          .CANNOT_CHANGE_LOCKED_RECONCILIATION_REPORT_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_STATUS_LOCKED_ERROR();
        case DeleteLedgerTransactionErrorResponse.ErrorCase
          .CANNOT_MODIFY_RECONCILED_TRANSACTION_ERROR:
          throw ReportServiceErrors.CANNOT_MODIFY_RECONCILED_TRANSACTION_ERROR();

        case DeleteLedgerTransactionErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
    const id = response.getOk()?.getRowId();
    if (!id) {
      throw new Error('No id returned');
    }
    return id;
  }

  public async deleteReconciliationReport(
    reportId: string,
    accessToken: string | null
  ): Promise<void> {
    const request = new DeleteReconciliationReportRequest();
    request.setReconciliationReportId(reportId);
    const response: DeleteReconciliationReportResponse =
      await this.reconciliationService.deleteReconciliationReport(request, {
        Authorization: `Bearer ${accessToken}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: DeleteReconciliationReportErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case DeleteReconciliationReportErrorResponse.ErrorCase
          .RECONCILIATION_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_NOT_FOUND_ERROR();
        case DeleteReconciliationReportErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        case DeleteReconciliationReportErrorResponse.ErrorCase
          .CANNOT_DELETE_LOCKED_RECONCILIATION_REPORT_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_STATUS_LOCKED_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
    const okResponse = response.getOk();
    if (!okResponse) {
      throw new Error('No ok response returned');
    }
  }

  public async unreconcileMatch(
    data: UnreconcileMatchParams,
    accessToken: string | null
  ): Promise<void> {
    const request = new UnreconcileMatchRequest();
    request.setReconciliationReportId(data.reconciliationReportId);
    request.setMatchId(data.matchId);

    const response: UnreconcileMatchResponse = await this.reconciliationService.unreconcileMatch(
      request,
      {
        Authorization: `Bearer ${accessToken}`,
      }
    );
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: UnreconcileMatchErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case UnreconcileMatchErrorResponse.ErrorCase.RECONCILIATION_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_NOT_FOUND_ERROR();
        case UnreconcileMatchErrorResponse.ErrorCase.RECONCILIATION_REPORT_STATUS_LOCKED_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_STATUS_LOCKED_ERROR();
        case UnreconcileMatchErrorResponse.ErrorCase.MATCH_DOES_NOT_EXIST_IN_RECONCILIATION_ERROR:
          throw ReportServiceErrors.MATCH_DOES_NOT_EXIST_IN_RECONCILIATION_REPORT();
        case UnreconcileMatchErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
  }

  public async lockReconciliationReport(
    data: LockReconciliationReportParams,
    accessToken: string | null
  ): Promise<void> {
    const request = new LockReconciliationReportRequest();
    request.setReconciliationReportId(data.reconciliationReportId);
    request.setWorkspaceId(data.workspaceId);
    request.setEntityId(data.entityId);
    request.setAccountId(data.accountId);

    const response: LockReconciliationReportResponse =
      await this.reconciliationService.lockReconciliationReport(request, {
        Authorization: `Bearer ${accessToken}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: LockReconciliationReportErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case LockReconciliationReportErrorResponse.ErrorCase.RECONCILIATION_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_NOT_FOUND_ERROR();
        case LockReconciliationReportErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        case LockReconciliationReportErrorResponse.ErrorCase
          .RECONCILIATION_REPORT_ALREADY_LOCKED_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_ALREADY_LOCKED();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
  }

  public async getAdjustmentsReport(
    data: GetAdjustmentsReportParams,
    accessToken: string | null
  ): Promise<GetAdjustmentsReportResp> {
    const request = new GetAdjustmentsReportRequest();
    request.setReconciliationReportId(data.reconciliationReportId);

    const response: GetAdjustmentsReportResponse =
      await this.reconciliationService.getAdjustmentsReport(request, {
        Authorization: `Bearer ${accessToken}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: GetAdjustmentsReportErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case GetAdjustmentsReportErrorResponse.ErrorCase.ADJUSTEMENTS_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.ADJUSTMENTS_REPORT_NOT_FOUND();
        case GetAdjustmentsReportErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
    const reconciliationAdjustmentsReportId = response.getOk()?.getId();
    const reconciliationReportId = response.getOk()?.getReconciliationReportId();
    const updatedAt = response.getOk()?.getUpdatedAt();
    const createdTransactions = response
      .getOk()
      ?.getCreatedTransactionsList()
      .map((createdTransaction) => createdTransaction.toObject());

    const deletedTransactions = response
      .getOk()
      ?.getDeletedTransactionsList()
      .map((deletedTransaction) => deletedTransaction.toObject());
    const updatedTransactions = response
      .getOk()
      ?.getUpdatedTransactionsList()
      .map((updatedTransaction) => updatedTransaction.toObject());

    return {
      reconciliationAdjustmentsReportId: reconciliationAdjustmentsReportId!,
      reconciliationReportId: reconciliationReportId!,
      updatedAt: updatedAt!,
      createdTransactions: createdTransactions! as unknown as TransactionType[],
      deletedTransactions: deletedTransactions! as unknown as TransactionType[],
      updatedTransactions: updatedTransactions! as unknown as UpdatedTransactionsType[],
    };
  }

  public async manuallyReconcile(
    reconciliationReportId: string,
    ledgerTransactionRowIds: number[],
    externalTransactionRowIds: number[],
    accessToken: string | null
  ): Promise<void> {
    const request = new ManualReconciliationRequest();
    request.setLedgerTransactionRowIdsList(ledgerTransactionRowIds);
    request.setExternalTransactionRowIdsList(externalTransactionRowIds);
    request.setReconciliationReportId(reconciliationReportId);
    const response = await this.reconciliationService.manualReconciliation(request, {
      Authorization: `Bearer ${accessToken}`,
    });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: ManualReconciliationErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case ManualReconciliationErrorResponse.ErrorCase.RECONCILIATION_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_NOT_FOUND_ERROR();
        case ManualReconciliationErrorResponse.ErrorCase.TRANSACTIONS_NOT_SUM_TO_ZERO_ERROR:
          throw ReportServiceErrors.TRANSACTIONS_NOT_SUM_TO_ZERO_ERROR();
        case ManualReconciliationErrorResponse.ErrorCase.RECONCILIATION_REPORT_STATUS_LOCKED_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_STATUS_LOCKED_ERROR();
        case ManualReconciliationErrorResponse.ErrorCase.TRANSACATION_IS_NOT_UNRECONCILED_ERROR:
          throw ReportServiceErrors.TRANSACATION_IS_NOT_UNRECONCILED_ERROR();
        case ManualReconciliationErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
  }

  public async createLedgerTransactions(
    data: CreateLedgerTransactionsParams,
    accessToken: string | null
  ): Promise<number[]> {
    const request = new CreateLedgerTransactionsRequest();
    request.setReconciliationReportId(data.reconciliationReportId);
    const ledgerTransactions = data.ledgerTransactions.map((t) => {
      const transaction = new CreateLedgerTransaction();
      if (t.financialTransactionId) transaction.setFinancialTransactionId(t.financialTransactionId);
      transaction.setDate(t.date);
      transaction.setDescription(t.description);
      if (t.reference) transaction.setReference(t.reference);
      transaction.setAmount(t.amount);
      transaction.setTransactionType(t.transactionType);
      return transaction;
    });
    request.setLedgerTransactionsList(ledgerTransactions);

    const response: CreateLedgerTransactionsResponse =
      await this.reconciliationService.createLedgerTransactions(request, {
        Authorization: `Bearer ${accessToken}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: CreateLedgerTransactionErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case CreateLedgerTransactionErrorResponse.ErrorCase.RECONCILIATION_REPORT_NOT_FOUND_ERROR:
          throw ReportServiceErrors.RECONCILIATION_REPORT_NOT_FOUND_ERROR();
        case CreateLedgerTransactionErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ReportServiceErrors.UNEXPECTED_ERROR();
        case CreateLedgerTransactionErrorResponse.ErrorCase.INVALID_BALANCES_ERROR:
          throw ReportServiceErrors.INVALID_BALANCES_ERROR();
        default:
          throw ReportServiceErrors.UNKNOWN_ERROR();
      }
    }
    const ids = response.getOk()?.getRowIdsList();
    if (!ids) {
      throw new Error('No ids returned');
    }
    return ids;
  }

  private mapSuggestionListToSuggestionArray(suggestions: Suggestion[]): SuggestionModel[] {
    return suggestions.map((s) => {
      const bankStatementTransactions = s
        .getExternalTransactionsList()
        .map((t) => this.mapFinancialTransactionToTransaction(t));
      const ledgerTransactions = s
        .getLedgerTransactionsList()
        .map((t) => this.mapFinancialTransactionToTransaction(t));
      return {
        id: s.getSuggestionId(),
        bankStatementTransactions,
        ledgerTransactions,
      };
    });
  }

  private mapMatchListToMatchArray(matches: Match[]): MatchModel[] {
    return matches.map((m) => {
      const bankStatementTransactions = m
        .getExternalTransactionsList()
        .map((t) => this.mapFinancialTransactionToTransaction(t));
      const ledgerTransactions = m
        .getLedgerTransactionsList()
        .map((t) => this.mapFinancialTransactionToTransaction(t));
      return {
        id: m.getMatchId(),
        bankStatementTransactions,
        ledgerTransactions,
        createdBy: m.getCreatedBy(),
        isCreatedFromSuggestion: m.getIsCreatedFromSuggestion(),
      };
    });
  }

  private mapFinancialTransactionToTransaction(transaction: FinancialTransaction): Transaction {
    const amount = transaction.getAmount();
    if (!amount) {
      throw new Error('Amount is undefined');
    }

    return {
      date: transaction.getDate(),
      description: transaction.getDescription(),
      reference: transaction.getReference(),
      status: transaction.getStatus(),
      rowId: transaction.getRowId(),
      debit: amount.getType() === AMOUNT_TYPE.DEBIT ? amount.getAmount() : 0,
      credit: amount.getType() === AMOUNT_TYPE.CREDIT ? amount.getAmount() : 0,
      financialTransactionId: transaction.getFinancialTransactionId(),
    };
  }

  private mapReconciliationReportToReconciliationReportModel(
    reconciliationOKResponse: GetReconciliationReportOKResponse
  ): ReconciliationReportModel {
    const reconciliationReport = reconciliationOKResponse?.toObject();
    const suggestions = reconciliationOKResponse?.getSuggestionsList()
      ? reconciliationOKResponse?.getSuggestionsList()
      : [];

    const mappedSuggestions = this.mapSuggestionListToSuggestionArray(suggestions!);

    const matches = reconciliationOKResponse?.getMatchesList();

    const mappedMatches = this.mapMatchListToMatchArray(matches!);

    const mappedExternalDocument = this.mapDocumentToReconciliationDocument(
      // TODO fix this
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      reconciliationReport?.externalDocument!
    );
    const mappedLedgerDocument = this.mapDocumentToReconciliationDocument(
      // TODO fix this
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      reconciliationReport?.ledgerDocument!
    );
    const res = {
      // TODO we must fix the proto file to not return optional values it they are not optional
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      id: reconciliationReport?.id!,
      externalDocument: mappedExternalDocument,
      ledgerDocument: mappedLedgerDocument,
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      status: reconciliationReport?.status!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      ledgerEndOfPeriodBalance: reconciliationReport?.ledgerClosingBalance!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      externalEndOfPeriodBalance: reconciliationReport?.externalClosingBalance!,
      suggestedMatches: mappedSuggestions,
      matches: mappedMatches,
      name: reconciliationReport.name,
      reconciliationPeriod: reconciliationReport.period!,
      debug: reconciliationReport.debug,
    };
    return res;
  }

  private mapDocumentToReconciliationDocument(financialDocument: FinancialDocument.AsObject) {
    const transactions = financialDocument.transactionsList;
    const mappedTransactions = transactions.map((t) =>
      this.mapFinancialReconiliationTransactionToReconciliationTransaction(t)
    );

    return {
      id: financialDocument.id,
      name: financialDocument.name,
      status: financialDocument.status,
      transactions: mappedTransactions,
    };
  }

  private mapFinancialReconiliationTransactionToReconciliationTransaction(
    transaction: FinancialTransaction.AsObject
  ): ReconciliationTransaction {
    const { amount } = transaction;
    if (amount === undefined || amount === null) {
      throw new Error('Amount is undefined');
    }

    return {
      date: transaction.date,
      description: transaction.description,
      reference: transaction.reference,
      balance: transaction.balance,
      rowId: transaction.rowId,
      status: transaction.status,
      debit: amount.type === AMOUNT_TYPE.DEBIT ? amount.amount : 0,
      credit: amount.type === AMOUNT_TYPE.CREDIT ? amount.amount : 0,
      financialTransactionId: transaction.financialTransactionId,
    };
  }
}
