import { useNavigate } from 'react-router-dom';
import { Header, Text, TextSpan1, TextSpan2 } from './Styles';
import { EmailPassForm } from '../../components/EmailPassForm';
import { useIamViewModel } from '../../view-models/IamViewModel';

export default function RegisterPage() {
  const navigate = useNavigate();
  const iamViewModel = useIamViewModel();

  const visitLoginPage = () => {
    navigate('/login');
  };

  const onSubmit = async (email: string, password: string): Promise<void> => {
    iamViewModel.registerWithEmailPassword(email, password);
  };

  return (
    <>
      <Header>Sign Up</Header>
      <EmailPassForm
        view="Sign Up"
        message={iamViewModel.authMessage}
        onSubmit={onSubmit}
      />
      <Text>
        <TextSpan1>Already a user?</TextSpan1>
        <TextSpan2 onClick={visitLoginPage}>LOGIN</TextSpan2>
      </Text>
    </>
  );
}