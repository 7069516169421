import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import ReconciliationReportPage from './page';
import { useReconciliationReportViewModel } from '../../view-models/ReconciliationReportViewModel';
import { useEntityViewModel } from '../../view-models/EntityViewModel';
import { useReconciliationAccountViewModel } from '../../view-models/ReconciliationAccountViewModel';

export const ReconciliationReportController: React.FC = () => {
  const reconciliationReportViewModel = useReconciliationReportViewModel();
  const entityViewModel = useEntityViewModel();
  const reconciliationAccountViewModel = useReconciliationAccountViewModel();

  const params = useParams();
  const navigate = useNavigate();
  const reconciliationReportId = params.id!;

  useEffect(() => {
    console.log(
      'reconciliationAccountViewModel.reconciliationAccount.data',
      reconciliationAccountViewModel.reconciliationAccount.data
    );
    // if (reconciliationAccountViewModel.reconciliationAccount.data) {
    //   console.log('setting reconciliation account');
    reconciliationReportViewModel?.setReconciliationAccount(
      reconciliationAccountViewModel.reconciliationAccount
    );
    //   console.log('account data', reconciliationAccountViewModel.reconciliationAccount.data);
    // }
    reconciliationReportViewModel?.getReconciliationReport(reconciliationReportId);
    reconciliationReportViewModel?.setReconciliationReportId(reconciliationReportId);
    reconciliationReportViewModel?.clearPaginationFilters();
  }, []);

  const navigateToManualReconciliation = () => {
    navigate(`/reconciliation-report/${reconciliationReportId}/manual-reconciliation`);
  };

  //  if (!entityViewModel.entity && !entityViewModel.clearedEntity && entityId) {
  //   console.log('[EntityController] [Entity] selectEntity', entityId);
  //   entityViewModel.selectEntity(entityId);
  // }

  // if (!entityViewModel.entity && !entityViewModel.clearedEntity && entityId) {
  //   console.log('[EntityController] [Entity] selectEntity', entityId);
  //   entityViewModel.selectEntity(entityId);
  // }

  // const navigateToDashboard = () => {
  //   navigate('/');
  // };

  // if (!entityViewModel.entity.data || !reconciliationAccountViewModel.reconciliationAccount.data) {
  //   navigateToDashboard();
  // }

  if (reconciliationReportViewModel) {
    return (
      <ReconciliationReportPage
        reconciledTransactionsPagination={reconciliationReportViewModel?.reconciledTransactionsPagination}
        unreconciledTransactionsCounts={reconciliationReportViewModel.unreconciledTransactions?.length ?? 0}
        reconciledTransactionsCounts={reconciliationReportViewModel.reconciledTransactions?.length ?? 0}
        unreconciledTransactionsBlocks={reconciliationReportViewModel.unreconciledTransactions}
        reconciledTransactionsBlocks={reconciliationReportViewModel.reconciledTransactions}
        unreconcileMatch={reconciliationReportViewModel.unReconcileMatch}
        status="success"
        currentEntity={entityViewModel.entity.data}
        currentReconciliationAccount={reconciliationAccountViewModel.reconciliationAccount.data}
        currentReconciliationReportName={reconciliationReportViewModel.reconciliationReportName}
        ledgerEndBalance={reconciliationReportViewModel.ledgerEndBalance}
        bankClosingBalance={reconciliationReportViewModel.bankClosingBalance}
        reconciliationDifference={reconciliationReportViewModel.reconciliationDifference}
        handleDownloadXLSXButtonClick={reconciliationReportViewModel.handleDownloadXLSXButtonClick}
        navigateToManualReconciliation={navigateToManualReconciliation}
        isReconciliationReportLocked={reconciliationReportViewModel.isReconciliationReportLocked}
        showLockReconciliationReportDialog={
          reconciliationReportViewModel.showLockReconciliationReportDialog
        }
        ledgerClosingBalance={reconciliationReportViewModel.ledgerClosingBalance}
        externalClosingBalanceName={reconciliationReportViewModel.externalClosingBalanceName}
        externalDocumentType={reconciliationAccountViewModel.externalDocumentType}
        ledgerDocumentType={reconciliationAccountViewModel.ledgerDocumentType}
        bankStatementTransactionsPercentage={
          reconciliationReportViewModel.bankStatementTransactionsPercentage
        }
      />
    );
  }
  return null;
};

const RPControllerPage = observer(ReconciliationReportController);

export { RPControllerPage as ReconciliationReportControllerPage };
