import { ChangeEvent, KeyboardEventHandler, useState } from 'react';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import FilledButton from '../Buttons/FilledButton';
import { Form } from './Styles';
import TermsCheckbox from './TermsCheckbox';

export interface EmailPassFormProps {
  requireTerms?: boolean;
  message: { type: 'error' | 'success'; message: string } | null;
  view: string;
  onSubmit: (email: string, password: string) => void;
}

function EmailPassForm(props: EmailPassFormProps): JSX.Element {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  const {
    message,
    view,
    requireTerms,
    onSubmit,
  } = props;

  const canSubmitLogin = email && password && (requireTerms ? termsAccepted : true);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const onTermsChange = (accepted: boolean) => {
    setTermsAccepted(accepted);
  };

  const submit = () => {
    onSubmit(email, password);
  };

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter' && canSubmitLogin) {
      submit();
    }
  }

  return (
    <Form>
      <TextField label="Email" type="email" value={email} onChange={handleEmailChange} />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={handlePasswordChange}
        onKeyDown={onKeyDown}
      />
      {requireTerms && (
        <TermsCheckbox
          onTermsChange={onTermsChange}
          termsAccepted={termsAccepted}
        />
      )}
      <FilledButton disabled={!canSubmitLogin} content={view} onClick={submit} />
      {message && <Alert severity={message.type}>{message.message}</Alert>}
    </Form>
  );
}
export { EmailPassForm };
