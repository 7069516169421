import AmountSubheader from '../AmountSubheader/AmountSubheader';
import { UnreconciledTransactionsBlocksProps } from '../../../types';
import { Empty, Layout, PercentageInfo } from './Styles';
import UnreconciledTransactionsSummary from './UnreconciledTransactionsSummary';

function UnreconciledTransactions(props: UnreconciledTransactionsBlocksProps): JSX.Element {
  const {
    unreconciledTransactionsBlocks,
    ledgerEndBalance,
    bankClosingBalance,
    reconciliationDifference,
    ledgerClosingBalance,
    externalClosingBalanceName,
    externalStatementTransactionsPercentage,
  } = props;

  return (
    <>
      {unreconciledTransactionsBlocks === null && (
        <Empty>Unreconciled transactions will be loaded soon.</Empty>
      )}
      {unreconciledTransactionsBlocks && unreconciledTransactionsBlocks.length === 0 && (
        <Empty>No unreconciled transactions found.</Empty>
      )}
      {unreconciledTransactionsBlocks && unreconciledTransactionsBlocks.length > 0 && (
        <Layout>
          <PercentageInfo>
            {externalStatementTransactionsPercentage !== null
              ? `(Automatic Reconciliation: ${externalStatementTransactionsPercentage.toFixed(2)}%)`
              : ''}
          </PercentageInfo>
          <AmountSubheader title={ledgerClosingBalance} amount={ledgerEndBalance} />
          {/* <Subheader title="Unreconciled Transactions" /> */}
          {/* <UnreconciledTransactionsAccordion
            unreconciledTransactionsBlocks={unreconciledTransactionsBlocks}
          /> */}
          <UnreconciledTransactionsSummary unreconciledTransactionsBlocks={unreconciledTransactionsBlocks} />
          <AmountSubheader title={externalClosingBalanceName} amount={bankClosingBalance} />
          <AmountSubheader title="Reconciliation Difference" amount={reconciliationDifference} />
          <div className="flex justify-end mt-4">
            <span>* Download report for full list of unreconciled transactions</span>
          </div>
        </Layout>
      )}
    </>
  );
}
export default UnreconciledTransactions;
