import {
    AmountFilter,
    DateFilter,
    FilterOptions,
} from '../models/ManualReconciliation';
import { ReconciledTransaction, ReconciledTransactionsBlock } from '../models/ReconciliationReport';
import DateUtils from '../utils/dates';
import { getAbsoluteValue } from '../utils/number';
import ReconciledTransactionHelper from './ReconciledTransactionBlock';

type TFilter = {
    filter: string;
    operator: string | null;
};

enum STANDARD_OPERATORS {
    EQUAL = '=',
    NOT_EQUAL = '<>',
    LIKE = '%',
    AND = '&',
}
const DEFAULT_OPERATOR = STANDARD_OPERATORS.LIKE;
const INVALID_DATE = 'Invalid Date';

export default class ReconciledTransactionFilter {
    public static runQuery(query: string, blocks: ReconciledTransactionsBlock[]): ReconciledTransactionsBlock[] {
        const filters = this.parseQuery(query);
    
        return blocks.map(block => ({
          ...block,
          bankStatementTransactions: block.bankStatementTransactions.filter(transaction => 
            this.applyOperators(transaction, filters)
          ),
          ledgerTransactions: block.ledgerTransactions
        })).filter(block => 
          block.bankStatementTransactions.length > 0
        );
    }

    public static applyFilterOptions(
        filterOptions: FilterOptions,
        transactions: ReconciledTransaction[]
    ): ReconciledTransaction[] {
        const transactionsDateFiltered = ReconciledTransactionFilter.applyDateFilter(
            filterOptions.date,
            transactions
        );
        const transactionsDateAmountFiltered = ReconciledTransactionFilter.applyAmountFilter(
            filterOptions.amount,
            transactionsDateFiltered
        );
        const transactionsFinalFiltered = ReconciledTransactionFilter.applySearchFilter(
            filterOptions.search,
            transactionsDateAmountFiltered
        );
        return transactionsFinalFiltered;
    }

    public static filterReconciledTransactionsBlocks(
        filterOptions: FilterOptions,
        blocks: ReconciledTransactionsBlock[]
    ): ReconciledTransactionsBlock[] {
        return blocks.map(block => {
            const filteredBankStatementTransactions = this.applyFilterOptions(
                filterOptions,
                block.bankStatementTransactions
            );

            const filteredLedgerTransactions = this.applyFilterOptions(
                filterOptions,
                block.ledgerTransactions
            );

            return {
                ...block,
                bankStatementTransactions: filteredBankStatementTransactions,
                ledgerTransactions: filteredLedgerTransactions
            };
        }).filter(block =>
            block.bankStatementTransactions.length > 0 ||
            block.ledgerTransactions.length > 0
        );
    }

    private static applySearchFilter(
        search: string,
        transactions: ReconciledTransaction[]
    ): ReconciledTransaction[] {
        return transactions.filter(transaction => 
            this.satisfiesSearchFilter(transaction, search)
        );
    }

    private static areBothDatesSet(date: DateFilter): boolean {
        return date.from !== INVALID_DATE && date.to !== INVALID_DATE;
    }

    private static getTransactionsWithDatesBetween(
        date: DateFilter,
        transactions: ReconciledTransaction[]
    ): ReconciledTransaction[] {
        const dateFrom = DateUtils.ParseCustomDate(date.from);
        const dateTo = DateUtils.ParseCustomDate(date.to);
        // get transactions with dates between from and to
        const filteredTransactions = transactions.filter((transaction) => {
            const formattedTransactionDate = DateUtils.FormatYearToYYYY(transaction.date);
            const transactionDate = DateUtils.ParseCustomDate(formattedTransactionDate);
            return (
                transactionDate.getTime() >= dateFrom.getTime() &&
                transactionDate.getTime() <= dateTo.getTime()
            );
        });
        return filteredTransactions;
    }

    private static isFromDateSet(date: DateFilter): boolean {
        return date.from !== INVALID_DATE;
    }

    private static getTransactionsWithDatesGreaterThanFrom(
        date: DateFilter,
        transactions: ReconciledTransaction[]
    ): ReconciledTransaction[] {
        const dateFrom = DateUtils.ParseCustomDate(date.from);
        // get transactions with dates greater than from
        const filteredTransactions = transactions.filter((transaction) => {
            const formattedTransactionDate = DateUtils.FormatYearToYYYY(transaction.date);
            const transactionDate = DateUtils.ParseCustomDate(formattedTransactionDate);
            return transactionDate.getTime() >= dateFrom.getTime();
        });
        return filteredTransactions;
    }

    private static isToDateSet(date: DateFilter): boolean {
        return date.to !== INVALID_DATE;
    }

    private static getTransactionsWithDatesLessThanTo(
        date: DateFilter,
        transactions: ReconciledTransaction[]
    ): ReconciledTransaction[] {
        const dateTo = DateUtils.ParseCustomDate(date.to);
        // get transactions with dates less than to
        const filteredTransactions = transactions.filter((transaction) => {
            const formattedTransactionDate = DateUtils.FormatYearToYYYY(transaction.date);
            const transactionDate = DateUtils.ParseCustomDate(formattedTransactionDate);
            return transactionDate.getTime() <= dateTo.getTime();
        });
        return filteredTransactions;
    }

    private static applyDateFilter(
        date: DateFilter,
        transactions: ReconciledTransaction[]
    ): ReconciledTransaction[] {
        if (this.areBothDatesSet(date)) {
            return this.getTransactionsWithDatesBetween(date, transactions);
        }
        if (this.isFromDateSet(date)) {
            return this.getTransactionsWithDatesGreaterThanFrom(date, transactions);
        }
        if (this.isToDateSet(date)) {
            return this.getTransactionsWithDatesLessThanTo(date, transactions);
        }
        return transactions;
    }

    private static areBothAmountsSet(amount: AmountFilter): boolean {
        return amount.from !== null && amount.to !== null;
    }

    private static getTransactionsWithAmountsBetween(
        amount: AmountFilter,
        transactions: ReconciledTransaction[]
    ): ReconciledTransaction[] {
        if (amount.from === amount.to) {
            // get transactions with amounts equal to absolute amount
            const absoluteAmount = getAbsoluteValue(amount.from!);
            const filteredTransactions = transactions.filter((transaction) => {
                if (ReconciledTransactionHelper.isCredit(transaction)) {
                    const absoluteCredit = getAbsoluteValue(ReconciledTransactionHelper.getCreditNumber(transaction));
                    return absoluteCredit === absoluteAmount;
                }
                const absoluteDebit = getAbsoluteValue(ReconciledTransactionHelper.getDebitNumber(transaction));
                return absoluteDebit === absoluteAmount;
            });
            return filteredTransactions;
        }
        // get transactions with amounts between from and to
        const filteredTransactions = transactions.filter((transaction) => {
            if (ReconciledTransactionHelper.isCredit(transaction)) {
                const creditNumber = ReconciledTransactionHelper.getCreditNumber(transaction);
                return creditNumber >= amount.from! && creditNumber <= amount.to!;
            }
            const debitNumber = ReconciledTransactionHelper.getDebitNumber(transaction);
            return debitNumber >= amount.from! && debitNumber <= amount.to!;
        });
        return filteredTransactions;
    }

    private static isFromAmountSet(amount: AmountFilter): boolean {
        return amount.from !== null;
    }

    private static getTransactionsWithAmountsGreaterThanFrom(
        amount: AmountFilter,
        transactions: ReconciledTransaction[]
    ): ReconciledTransaction[] {
        // get transactions with amounts greater than from
        const filteredTransactions = transactions.filter((transaction) => {
            if (ReconciledTransactionHelper.isCredit(transaction)) {
                return ReconciledTransactionHelper.getCreditNumber(transaction) >= amount.from!;
            }
            return ReconciledTransactionHelper.getDebitNumber(transaction) >= amount.from!;
        });
        return filteredTransactions;
    }

    private static isToAmountSet(amount: AmountFilter): boolean {
        return amount.to !== null;
    }

    private static getTransactionsWithAmountsLessThanTo(
        amount: AmountFilter,
        transactions: ReconciledTransaction[]
    ): ReconciledTransaction[] {
        // get transactions with dates less than to
        const filteredTransactions = transactions.filter((transaction) => {
            if (ReconciledTransactionHelper.isCredit(transaction)) {
                return ReconciledTransactionHelper.getCreditNumber(transaction) <= amount.to!;
            }
            return ReconciledTransactionHelper.getDebitNumber(transaction) <= amount.to!;
        });
        return filteredTransactions;
    }

    private static applyAmountFilter(
        amount: AmountFilter,
        transactions: ReconciledTransaction[]
    ): ReconciledTransaction[] {
        if (this.areBothAmountsSet(amount)) {
            return this.getTransactionsWithAmountsBetween(amount, transactions);
        }
        if (this.isFromAmountSet(amount)) {
            return this.getTransactionsWithAmountsGreaterThanFrom(amount, transactions);
        }
        if (this.isToAmountSet(amount)) {
            return this.getTransactionsWithAmountsLessThanTo(amount, transactions);
        }
        return transactions;
    }

    private static satisfiesSearchFilter(transaction: ReconciledTransaction, filter: string): boolean {
        const words = this.splitWordsOfStringFields(transaction);
        return words.some(word => word.includes(filter));
    }

    private static applyOperators(transaction: ReconciledTransaction, filters: TFilter[]): boolean {
        return filters.every(filter => this.applyOperator(transaction, filter));
    }

    private static applyOperator(transaction: ReconciledTransaction, filter: TFilter): boolean {
        switch (filter.operator) {
            case STANDARD_OPERATORS.EQUAL:
                return this.applyEqualOperator(transaction, filter.filter);
            case STANDARD_OPERATORS.NOT_EQUAL:
                return this.applyNotEqualOperator(transaction, filter.filter);
            case STANDARD_OPERATORS.LIKE:
                return this.applyLikeOperator(transaction, filter.filter);
            default:
                return this.applyLikeOperator(transaction, filter.filter);
        }
    }

    private static splitBySpace(str: string): string[] {
        return str.split(' ');
    }

    private static splitWordsIntoOneArray(transaction: ReconciledTransaction): string[] {
        const words: string[] = [];
        const trimmedDescription = this.trim(transaction.description);
        const trimmedReference = this.trim(transaction.reference);
        const trimmedFinancialTransactionId = this.trim(transaction.financialTransactionId);
        const trimmedCredit = this.trim(transaction.credit);
        const trimmedDebit = this.trim(transaction.debit);
        words.push(...this.splitBySpace(trimmedDescription));
        words.push(...this.splitBySpace(trimmedReference));
        words.push(trimmedFinancialTransactionId);
        words.push(trimmedCredit);
        words.push(trimmedDebit);
        return words;
    }

    private static splitWordsOfStringFields(transaction: ReconciledTransaction): string[] {
        const words: string[] = [];
        const trimmedDescription = this.trim(transaction.description);
        const trimmedReference = this.trim(transaction.reference);
        const trimmedFinancialTransactionId = this.trim(transaction.financialTransactionId);
        words.push(...this.splitBySpace(trimmedDescription));
        words.push(...this.splitBySpace(trimmedReference));
        words.push(trimmedFinancialTransactionId);
        return words;
    }

    private static indicatesDate(filter: string): boolean {
        return filter.includes('/');
    }

    private static applyEqualOperator(transaction: ReconciledTransaction, filter: string): boolean {
        if (this.indicatesDate(filter)) {
            return transaction.date === filter;
        }
        const words = this.splitWordsIntoOneArray(transaction);
        return words.includes(filter);
    }

    private static applyNotEqualOperator(transaction: ReconciledTransaction, filter: string): boolean {
        if (this.indicatesDate(filter)) {
            return transaction.date !== filter;
        }
        const words = this.splitWordsIntoOneArray(transaction);
        return !words.includes(filter);
    }

    private static applyLikeOperator(transaction: ReconciledTransaction, filter: string): boolean {
        if (this.indicatesDate(filter)) {
            return transaction.date.includes(filter);
        }
        const words = this.splitWordsIntoOneArray(transaction);
        return words.some(word => word.includes(filter));
    }

    private static getFilters(query: string): string[] {
        if (query.includes(STANDARD_OPERATORS.AND)) {
            const filters = query.split(STANDARD_OPERATORS.AND);
            const lastIndex = filters.length - 1;
            if (filters[lastIndex].length === 0) {
                filters.pop();
            }
            return filters;
        }

        return [query];
    }

    private static removeCommas(query: string): string {
        return query.replace(/,/g, '');
    }

    private static trim(query: string): string {
        const trimmed = query.trim();
        const trimmedAndCommaRemoved = this.removeCommas(trimmed);
        const final = trimmedAndCommaRemoved.toLowerCase();
        return final;
    }

    private static parseQuery(query: string): TFilter[] {
        const filters = this.getFilters(query);
        return filters.map(filter => {
            let operator = DEFAULT_OPERATOR;
            let parsedFilter = this.trim(filter);
            
            if (filter.includes(STANDARD_OPERATORS.LIKE)) {
                operator = STANDARD_OPERATORS.LIKE;
                parsedFilter = filter.replace(STANDARD_OPERATORS.LIKE, '');
            } else if (filter.includes(STANDARD_OPERATORS.NOT_EQUAL)) {
                operator = STANDARD_OPERATORS.NOT_EQUAL;
                parsedFilter = filter.replace(STANDARD_OPERATORS.NOT_EQUAL, '');
            } else if (filter.includes(STANDARD_OPERATORS.EQUAL)) {
                operator = STANDARD_OPERATORS.EQUAL;
                parsedFilter = filter.replace(STANDARD_OPERATORS.EQUAL, '');
            }
            
            return { filter: parsedFilter, operator };
        });
    }
}