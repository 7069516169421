import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import * as XLSX from 'xlsx';
import { CONCRETE_TYPES } from '../../../models/Document';
import { DOCUMENT_TYPE } from '../../../models/UploadFileData';
import { Choose, Header, Statement } from '../Styles';
import { CUSTOM_OPTIONS } from '../../../view-models/ConvertersAndTemplatesViewModel';
import { STATUS_STATES } from '../../../types';
import SimpleButton from '../../Buttons/SimpleButton';
import UploadFileBlockButton from '../../CreateReconciliationReport/UploadStatements/ChooseStatements/UploadFile/UploadFileBlockButton';

interface ConvertCustomFormatProps {
  uploadFileStatus: STATUS_STATES;
  customOptions: CUSTOM_OPTIONS[];
  selectedCustom: CUSTOM_OPTIONS;
  uploadedFile: File | null;
  setSelectedCustom: (value: CUSTOM_OPTIONS) => void;
  convertDocument: (ws: XLSX.WorkSheet, fileName: string, customType: CUSTOM_OPTIONS) => void;
  handleFileUpload: (file: File) => void;
  setUploadFileStatus: (value: STATUS_STATES) => void;
}

function ConvertCustomFormat(props: ConvertCustomFormatProps): JSX.Element {
  const {
    customOptions,
    selectedCustom,
    uploadFileStatus,
    uploadedFile,
    handleFileUpload,
    convertDocument,
    setSelectedCustom,
    setUploadFileStatus,
  } = props;

  const isFileEmpty = (data: string | ArrayBuffer | null | undefined): boolean =>
    !data || (data instanceof ArrayBuffer && data.byteLength === 0);

  const handleConvert = () => {
    if (!uploadedFile) return;

    const bankType = selectedCustom as CUSTOM_OPTIONS;
    const reader = new FileReader();

    // Read the contents of the file
    reader.onload = (e) => {
      const data = e.target?.result;

      // Check if the file is empty
      if (isFileEmpty(data)) {
        console.error('The file is empty.');
        return;
      }
      // Convert data to binary
      if (!(data instanceof ArrayBuffer)) throw new Error('Expected ArrayBuffer');
      const binaryData = new Uint8Array(data);

      // Parse the binary data as an Excel file
      const workbook = XLSX.read(binaryData, {
        type: 'array',
        raw: true,
      });

      // Check if the workbook has sheets
      if (workbook.SheetNames.length === 0) {
        console.error('The workbook does not contain any sheets.');
        return;
      }

      // Retrieve the name of a tab by its index.
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      convertDocument(worksheet, uploadedFile.name, bankType);
    };

    // Read the file as binary data
    reader.readAsArrayBuffer(uploadedFile);
  };

  return (
    <Statement>
      <Header>Custom Format</Header>

      <Autocomplete
        options={customOptions}
        sx={{ width: 200 }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} />}
        value={selectedCustom}
        onChange={(e, value) => {
          setSelectedCustom(value || CUSTOM_OPTIONS.NONE);
        }}
      />

      <Choose>
        <UploadFileBlockButton
          allowFileTypes={['.csv', '.xls', '.xlsx']}
          disableUpload={false}
          uploadFile={handleFileUpload}
          type={DOCUMENT_TYPE.LEDGER}
          concreteType={CONCRETE_TYPES.LEDGER}
          status={uploadFileStatus}
          setStatus={setUploadFileStatus}
        />
      </Choose>
      <SimpleButton content="Convert" onClick={handleConvert} />
    </Statement>
  );
}

export default ConvertCustomFormat;
