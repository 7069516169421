import CircularProgress from '@mui/material/CircularProgress';
import {
  Dialog,
  DialogTitle,
  LinearProgress,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { LoaderContainer } from './Styles';

interface ProgressDialogProps {
  open: boolean;
  progress: number;
  message: string;
  total: number;
  totalReconciled: number;
  // handleClose: () => void;
}
function ProgressDialog(props: ProgressDialogProps) {
  const { open, progress, total, totalReconciled, message } = props;

  return (
    <Dialog
      open={open}
    //   onClose={handleClose}
    >
      <DialogTitle>Processing...</DialogTitle>
      <DialogContent>
        <LoaderContainer>
          <CircularProgress size={150} />
        </LoaderContainer>
        <DialogContentText>{message}</DialogContentText>
        <DialogContentText>{`${totalReconciled} of ${total} transactions processed`}</DialogContentText>
      </DialogContent>
      <LinearProgress variant="determinate" value={progress} />
    </Dialog>
  );
}

export default ProgressDialog;
