import TableHeaders from './Styles';
import NameColumn from '../Columns/ΝameColumn';
import ReconciliationPeriodColumn from '../Columns/ReconciliationPeriodColumn';
import BalanceColumn from '../Columns/BalanceColumn';
import YearColumn from '../Columns/YearColumn';
import ActionsColumn from '../Columns/ActionsColumn';
import { AccountHeaders } from '../../../../view-models/ReconciliationAccountViewModel';

export type HeaderProps = {
  balanceHeaders: () => AccountHeaders;
};

function Headers(headerProps: HeaderProps): JSX.Element {
  const { balanceHeaders } = headerProps;

  const { ledgerOBColumn, externalOBColumn, ledgerCBColumn, externalCBColumn } = balanceHeaders();

  return (
    <TableHeaders
      sx={{
        fontSize: {
          xs: '14px',
          lg: '18px',
        },
      }}
    >
      <NameColumn content="Name" />
      {/* <LabelColumn content="Label" /> */}
      <ReconciliationPeriodColumn content="Reconciliation Period" />
      <BalanceColumn
        content={ledgerOBColumn}
        helperText="Ledger Opening Balance"
        type="header"
        ledger
      />
      <BalanceColumn
        content={externalOBColumn}
        helperText="Bank Opening Balance"
        type="header"
        bank
      />
      <BalanceColumn
        content={ledgerCBColumn}
        helperText="Ledger Closing Balance"
        type="header"
        ledger
      />
      <BalanceColumn
        content={externalCBColumn}
        helperText="Bank Closing Balance"
        type="header"
        bank
      />
      <YearColumn content="Year" />
      <ActionsColumn content="Report" />
    </TableHeaders>
  );
}

export default Headers;
