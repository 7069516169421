import { useEffect, useRef, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CSVBoxButton } from '@csvbox/react';
import { useDocumentsViewModel } from '../../view-models/DocumentsViewModel';

const CSVBoxController = observer(() => {
  const documentsViewModel = useDocumentsViewModel();
  const { open, apiKey, dataUrl } = documentsViewModel.csvBox;
  const ref = useRef<HTMLButtonElement>(null);
  const [shouldLaunch, setShouldLaunch] = useState(false);

  const csvBoxOptions = useMemo(
    () => ({
      upload_file_url: dataUrl,
      debug: false,
    }),
    [dataUrl]
  );

  const metadata = useMemo(
    () => ({
      user: documentsViewModel.userMetadata,
      environment: documentsViewModel.environmentMetadata,
    }),
    [documentsViewModel.userMetadata, documentsViewModel.environmentMetadata]
  );

  useEffect(() => {
    if (open && apiKey) {
      setShouldLaunch(true);
    }
  }, [open, apiKey]);

  if (!apiKey || !open) return null;

  const handleImport = (success: boolean, data: any) => {
    if (!success) {
      console.error('Import failed:', data);
      return;
    }
    documentsViewModel.handleCSVBoxData(data);
  };

  const handleClose = () => {
    documentsViewModel.closeCSVBox();
  }

  return (
    <CSVBoxButton
      key={`${apiKey}-${dataUrl}`}
      licenseKey={apiKey}
      user={metadata.user}
      environment={metadata.environment}
      options={csvBoxOptions}
      onImport={handleImport}
      onClose={handleClose}
      render={(launch: () => void, isLoading: boolean) => {
        if (shouldLaunch && !isLoading) {
          launch();
          setShouldLaunch(false);
        }
        return (
          <button
            disabled={isLoading}
            ref={ref}
            onClick={launch}
            type="button"
            style={{ display: 'none' }}
          >
            Upload file
          </button>
        );
      }}
    />
  );
});

export default CSVBoxController;
