import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import RegisterPage from './page';
import { useIamViewModel } from '../../view-models/IamViewModel';

const LoginController: React.FC = () => {
  const iamViewModel = useIamViewModel();
  const navigate = useNavigate();

  useEffect(() => {
    if (iamViewModel.isAuthenticated) {
      navigate('/');
    }
  }, [iamViewModel.isAuthenticated]);

  return (
    <RegisterPage />
  );
};

const Register = observer(LoginController);

export default Register;
